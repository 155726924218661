import {
  BTCheckbox,
  BTAlert,
  BTModal,
  BTForm,
  BTInput,
  BTButton,
  BTLayoutGrid,
  BTLayoutCol,
  BTLayoutRow,
} from '@btas/jasper';
import Spinner from '../../_shared/Spinner.jsx';
import { useState, useEffect, useRef } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getTemplateList } from './apis.js';
import { getDataFlowElevated } from '../DataFlowEditorPage/apis.js';
import { copyDataFlow } from './apis.js';
import TaxPeriodInput from '../../_shared/TaxPeriodInput.jsx';
import { getRenamedValue } from '../DataFlowEditorPage/DataFlowEditorHeaderBar/SaveAsModal.jsx';
import createTemplateGrid from './templateGrid.js';
import { useTrackInteractiveClick } from '../../_shared/EventTrackingContext.jsx';
import { itemTypes } from '../../_shared/EventTrackingContext/utils.js';
import CustomLogger from '../../_shared/Logger/CustomLogger.js';
const defaultSort = { binding: 'name', direction: 'ASC' };
const initialFilters = {
  name: '',
  taxPeriod: null,
  lastRunUser: null,
  lastRunDateFrom: null,
  lastRundDateTo: null,
  lastModifiedUser: null,
  lastModifiedDateFrom: null,
  lastModifiedDateTo: null,
};
export default function CreateDataFlowTemplateDialog({ onCancelClick, onCreateClick, error }) {
  const gridItemStyle = {
    textAlign: 'center',
    cursor: 'pointer',
  };

  const templateImgStyle = {
    border: '1px solid',
    padding: '10px',
    width: '100%',
    height: '100px',
    backgroundColor: '#f1f1f1',
    marginBottom: '7px',
  };

  const buttonStyle = {
    border: 'none',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '0',
  };

  const inputEl = useRef(null);

  const trackEvent = useTrackInteractiveClick();

  const [dataFlowData, setDataFlowData] = useState({ name: '', taxPeriod: '' });
  const [taxPeriod, setTaxPeriodForTemplate] = useState();
  const [dataFlowName, setNameForTemplate] = useState();
  const [includeSourceFiles, setIncludeSourceFiles] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingTemplates, setLoadingTemplates] = useState(false);
  const [copyTemplateError, setError] = useState(null);

  const history = useHistory();
  const onNameChange = useCallback(event => {
    setNameForTemplate(event.target.value);
  }, []);

  const setTaxPeriod = useCallback(taxPeriod => {
    setTaxPeriodForTemplate(taxPeriod);
  }, []);

  const onCopyDataFlowClick = async () => {
    await createDataFlowFromTemplate(dataFlowData);
  };

  const onTemplateClick = useCallback(
    async dataFlow => {
      setDataFlowData(dataFlow);
      setTaxPeriod(dataFlow.taxPeriod);
      setNameForTemplate(getRenamedValue(dataFlow.name));
      setShowModal(useTemplates);
    },
    [setTaxPeriod]
  );

  const createDataFlowFromTemplate = async dataFlow => {
    if (dataFlow) {
      setError(null);
      setLoading(true);

      let configToCopy = dataFlow.lastRun_configuration;

      if (!configToCopy) {
        const config = await getDataFlowElevated(dataFlow.id);
        configToCopy = config.configuration;
      }

      let response;
      try {
        const startTime = performance.now();
        response = await copyDataFlow(configToCopy, dataFlowName, taxPeriod, includeSourceFiles, dataFlow.id, true);
        const endTime = performance.now();
        if (response.error) {
          const validationDetails = response.error?.details.reduce(
            (acc, err) => ({ ...acc, [err.target]: err.message }),
            {}
          );
          CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
            error: validationDetails || response.error.message,
            from_template: 'true',
            template_name: dataFlow.name,
            copy_from_workflow_id: dataFlow.id,
            message: `Something happen while creating Data connection from template.`,
          });
          setError(validationDetails || response.error.message);
        } else {
          const duration = endTime - startTime;
          CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
            workflow_id: `${response.id}`,
            duration: `${duration} ms`,
            from_template: 'true',
            template_name: dataFlow.name,
            copy_from_workflow_id: `${dataFlow.id}`,
            message: `Data connection successfully created from template.`,
          });
          setTimeout(() => history.push(`/data-flows/${response.id}/editor`));
        }
      } catch (e) {
        CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
          error: e.message,
          from_template: 'true',
          template_name: dataFlow.name,
          copy_from_workflow_id: `${dataFlow.id}`,
          message: `Something happen while creating Data connection from template.`,
        });
        setError(e.message);
      } finally {
        setLoading(false);
      }

      return response;
    }
    CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
      error: `No template information provided after template selection`,
    });
  };

  const fetchTemplates = useCallback(
    async function (configs) {
      const sorting = configs?.sorting ?? defaultSort;
      const filters = configs?.filters ?? initialFilters;

      const templates = await getTemplateList({
        sorting,
        filters: {
          ...filters,
          name: filters.name ? filters.name : null,
          taxPeriod: filters.taxPeriod?.value,
          lastModifiedBy: filters.lastModifiedBy?.value,
          lastRunBy: filters.lastRunBy?.value,
        },
      });
      const templateGrid = await createTemplateGrid(templates, onTemplateClick, onCreateClick);
      setTemplateData(templateGrid);
      setLoadingTemplates(false);
    },
    [onTemplateClick, onCreateClick]
  );

  const templateModal = 'Templates';
  const useTemplates = 'Use Template';
  const [showModal, setShowModal] = useState('Templates');
  const [templateData, setTemplateData] = useState([{ key: 0, data: [] }]);

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
    setLoadingTemplates(true);
    fetchTemplates({ sorting: defaultSort, filters: initialFilters });
  }, [fetchTemplates]);

  if (showModal === templateModal) {
    return (
      <BTModal
        show={true}
        style={{
          height: '640px',
          width: '640px',
          paddingLeft: '0px',
        }}
        title={'New Data Connect Workflow'}
        onCloseClick={onCancelClick}
      >
        <BTModal.Body
          key="template-modal-body"
          className="wkp-modal-body"
          id="create-df-modal"
          style={{
            height: '640px',
            width: '640px',
            display: 'inline-flex',
            paddingLeft: '0px',
          }}
        >
          {isLoadingTemplates ? (
            <div
              className="template-modal-spinner"
              hidden={isLoadingTemplates ? false : 'hidden'}
              style={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                overflowX: 'hidden',
                overflowY: 'hidden',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <BTLayoutGrid
              hidden={isLoadingTemplates ? true : false}
              style={{
                width: '100%',
                paddingLeft: '0px',
              }}
            >
              {templateData.map(templateRow => (
                <BTLayoutRow key={'templateRow-' + templateRow.key} style={{ marginBottom: '10px' }}>
                  {templateRow.data.map(templateItem => (
                    <BTLayoutCol key={templateItem.text} lg={4} md={4} sm={4} xs={4}>
                      <div
                        className={`df-template-tile ${templateItem.text.replaceAll(' ', '_')}`}
                        style={gridItemStyle}
                      >
                        <button
                          style={buttonStyle}
                          onClick={() => {
                            trackEvent(
                              `new-data-transformation-template-selection: ${templateItem.text}`,
                              itemTypes.BUTTON
                            );
                            templateItem.handler(templateItem);
                          }}
                        >
                          <img alt="Bloomberg Tax Workpapers" src={templateItem.image} style={templateImgStyle} />
                          <span style={{ fontWeight: 500 }}>{templateItem.text}</span>
                        </button>
                      </div>
                    </BTLayoutCol>
                  ))}
                </BTLayoutRow>
              ))}
            </BTLayoutGrid>
          )}
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton onClick={onCancelClick}>Cancel</BTButton>
        </BTModal.Footer>
      </BTModal>
    );
  }
  if (showModal === useTemplates) {
    return (
      <BTModal show={true} title={'Create from Template'} onCloseClick={onCancelClick}>
        <BTModal.Body className="wkp-modal-body" style={{ overflow: 'unset' }}>
          <BTForm>
            <BTForm.FormGroup
              required
              aria-label="nameForm"
              errorText={copyTemplateError?.name}
              hasError={copyTemplateError?.name !== undefined}
              label="Name"
            >
              <BTInput maxLength="100" value={dataFlowName} onChange={onNameChange} />
            </BTForm.FormGroup>
            <TaxPeriodInput
              required
              errorText={copyTemplateError?.taxPeriod}
              hasError={!!copyTemplateError?.taxPeriod}
              style={{ overflow: 'auto' }}
              value={taxPeriod}
              onChange={tp => setTaxPeriod(tp)}
            />
            <BTCheckbox
              checked={includeSourceFiles}
              id="includeSourceFiles"
              label="Include sample data"
              onChange={ev => setIncludeSourceFiles(ev.target.checked)}
            />
          </BTForm>
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton onClick={onCancelClick}>Cancel</BTButton>
          <BTButton btStyle="primary" disabled={isLoading} hasSpinner={isLoading} onClick={() => onCopyDataFlowClick()}>
            Create
          </BTButton>
        </BTModal.Footer>
        <BTAlert appear dismissible fixed btStyle="danger" visible={!!error}>
          {error || ''}
        </BTAlert>
      </BTModal>
    );
  }
}

import GC from '../../../../../SpreadSheets';
export const momentDateFormats = [
  'MM-DD-YYYY',
  'M-D-YYYY',
  'MM-D-YYYY',
  'M-DD-YYYY',
  'MM/DD/YYYY',
  'M/D/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-M-D',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY/MM/DD',
  'YYYY/MM/D',
  'YYYY/M/DD',
  'YYYY/M/D',
  'ddd MMM DD YYYY HH:mm:ss',
];

export const operatorMap = {};
for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.preOperaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.operaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

import React, { useEffect, useState, useContext } from 'react';
import { BTModal, BTButton, BTForm, BTComboBox, BTAlert } from '@btas/jasper';
import EditorContext from '../EditorContext';
import { getWorkpapers, getWorkbookSheets, copySheet } from './WorkpaperCopySheetModal/apis';
import ValidationErrors from '../../../_shared/ValidationError';
import { generalErrorMessage } from '../../../_shared/messages';
import './WorkpaperCopySheetModal/styles.scss';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../_shared/storage';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { SJS_API } from '../../../../constants/featureFlags';
import { startJob } from '../../../_shared/jobs/apis';
import { WORKPAPER_COPY_WORKSHEET_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import { WORKPAPER_NOT_FOUND_ERROR, getLock, getWorkbookMetadata } from '../apis';
import { getUser } from '../../../_shared/auth';
import { getWorkflowStatus } from './SideBar/WorkflowPanel/Status/api';
import { isEmptyObject } from '../DataReference/dataReferenceHelper';

export default function WorkpaperCopySheetModal({ show, sourceWorkpaperId, onClose, handleCopySheetSjs }) {
  const [workpapers, setWorkpapers] = useState([]);
  const [taxPeriods, setTaxPeriods] = useState([]);
  const [selectedWorkpaper, setSelectedWorkpaper] = useState();
  const [selectedTaxPeriod, setSelectedTaxPeriod] = useState();
  const [selectedWorkpaperId, setSelectedWorkpaperId] = useState();
  const [selectedSheets, setSelectedSheets] = useState();
  const [targetWorkpaperSheets, setTargetWorkpaperSheets] = useState([]);
  const [beforeSheet, setBeforeSheet] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [error, setError] = useState();

  const { spreadRef, setSuccessAlertMessage, workbookName } = useContext(EditorContext);
  const spread = spreadRef?.current;
  const lockedWorkpaperError = (userName, targetWorkpaperName) =>
    `The workpaper '${targetWorkpaperName}' is locked for editing by '${userName}'. Please try again once the workpaper lock has been released.`;
  const lockedWorkpaperAsFinalError = targetWorkpaperName =>
    `The workpaper '${targetWorkpaperName}' has been locked as 'Final'. No edits can be made to this workpaper while it is in 'Final' status.`;
  useEffect(() => {
    if (!show) {
      return;
    }
    getWorkpapers().then(workpapers => {
      const workpapersOptions = workpapers.map(wkp => ({ value: wkp, label: wkp.workpaperName }));
      setWorkpapers(workpapersOptions);
      if (workpapersOptions.length === 1) {
        setSelectedWorkpaper(workpapersOptions[0]);
      } else {
        const optionToSelect = workpapersOptions.find(option =>
          option.value.taxPeriods?.some(taxPeriod => taxPeriod.id === sourceWorkpaperId)
        );
        setSelectedWorkpaper(optionToSelect);
      }
    });
  }, [show, sourceWorkpaperId]);

  useEffect(() => {
    setTaxPeriods([]);
    if (!selectedWorkpaper) return;

    const getSourceWorkpaperTaxPeriod = async () => {
      const { metadata } = await getWorkbookMetadata({ id: sourceWorkpaperId });
      return !metadata.taxPeriod ? '[Unassigned]' : metadata.taxPeriod;
    };

    (async () => {
      // Clean tax period array and set [Unassigned] for workpapers without tax period
      const taxPeriodOptions = selectedWorkpaper.value?.taxPeriods.map(({ id, period }) => ({
        value: id,
        label: period ?? '[Unassigned]',
      }));
      if (taxPeriodOptions.length === 1) {
        const [taxPeriodOption] = taxPeriodOptions;
        setSelectedWorkpaperId(taxPeriodOption.value);

        taxPeriodOption.label === null ? setSelectedTaxPeriod(null) : setSelectedTaxPeriod(taxPeriodOption);
      } else {
        const metadataTaxPeriod = await getSourceWorkpaperTaxPeriod();
        let optionToSelect = null;
        if (selectedWorkpaper.label === workbookName) {
          optionToSelect = taxPeriodOptions.find(
            ({ value, label }) => value === sourceWorkpaperId && metadataTaxPeriod === label
          );
        }
        handleTaxPeriodChange(optionToSelect);
        setTaxPeriods(taxPeriodOptions);
      }
    })();
  }, [selectedWorkpaper, sourceWorkpaperId, workbookName]);

  useEffect(() => {
    setBeforeSheet(null);
    setTargetWorkpaperSheets([]);
    setError();
    if (!show || !selectedWorkpaperId) {
      return;
    }

    if (sourceWorkpaperId === selectedWorkpaperId) {
      const ss = spreadRef.current;
      const allWorksheets = ss.getSheetCount();
      const worksheetsArray = [...Array(allWorksheets)].map((_, i) => ({
        worksheetName: ss.getSheet(i).name(),
      }));
      setTargetWorkpaperSheets(worksheetsArray);
    } else {
      getWorkbookSheets(selectedWorkpaperId)
        .then(sheets => setTargetWorkpaperSheets(sheets))
        .catch(error => {
          error.message = error.message ?? generalErrorMessage;
          setError(error);
        });
    }
  }, [show, selectedWorkpaperId, spreadRef, sourceWorkpaperId]);

  useEffect(() => {
    setSelectedSheets(spread.sheets?.filter(sheet => sheet.isSelected()).map(sheet => sheet.name()));
  }, [spread.sheets]);

  const handleWorkpaperChange = wkp => setSelectedWorkpaper(wkp);

  const handleTaxPeriodChange = taxPeriod => {
    setSelectedTaxPeriod(taxPeriod);
    setSelectedWorkpaperId(taxPeriod?.value);
  };

  const handleBeforeSheetChange = ({ target }) => {
    setBeforeSheet(target.value);
  };

  /**
   * Slices a string before the first match of a regular expression.
   * @param {RegExp} regex - The regular expression to match against the string.
   * @param {string} str - The string to slice.
   * @returns {string} The portion of the string before the first match of the regular expression, or the entire string if there is no match.
   */
  const sliceBeforeRegexMatch = (regex, str) => {
    const regexMatch = str.match(regex);
    return regexMatch ? str.slice(0, regexMatch.index).trim() : str;
  };

  /**
   * Generates a new unique name for the copied sheet.
   * @param {string} sheetToCopy - The name of the sheet to be copied.
   * @param {array} existingSheets - An array of objects representing the existing sheets in the target workbook.
   * @returns {string} A new unique name for the copied sheet.
   */
  const getAvailableSheetName = (existingSheetsNames, sheetToCopy) => {
    const MAX_NAME_LENGTH = 31;
    const copyRegex = /\(\d+\)$/;
    let baseName = sliceBeforeRegexMatch(copyRegex, sheetToCopy);
    let availableName;

    for (let sequenceNumber = 1; ; sequenceNumber++) {
      let suffix = ` (${sequenceNumber})`;
      availableName = `${baseName}${suffix}`;

      if (availableName.length > MAX_NAME_LENGTH) {
        const truncatedBaseName = baseName.slice(0, MAX_NAME_LENGTH - suffix.length);
        availableName = `${truncatedBaseName}${suffix}`;
      }

      if (!existingSheetsNames.includes(availableName)) {
        break;
      }
    }

    return availableName;
  };

  const copySheetLocally = async (sheetToCopy, workpaperId) => {
    const sjsEnabled = isFeatureFlagEnabled(SJS_API);
    const newName = getAvailableSheetName(
      spread.sheets.map(sheet => sheet.name()),
      sheetToCopy
    );

    let targetIndex = targetWorkpaperSheets.length;

    if (beforeSheet !== -1) {
      const beforeSheetArrayPointer = targetWorkpaperSheets.find(({ worksheetName }) => worksheetName === beforeSheet);
      const beforeSheetIndex = targetWorkpaperSheets.indexOf(beforeSheetArrayPointer);

      targetIndex = beforeSheetArrayPointer ? beforeSheetIndex : targetWorkpaperSheets.length;
    }

    const commandManager = spread.commandManager();
    sjsEnabled && setDataToLocalStorage(`ignore-insert-sheet-${workpaperId}`, true);

    const command = {
      cmd: 'copySheet',
      sheetName: sheetToCopy,
      newName,
      targetIndex,
      includeBindingSource: true,
    };

    commandManager.execute(command);
  };

  const handleCopy = async () => {
    let error;
    const operationStartTime = Date.now();
    const targetWorkpaperId = selectedWorkpaperId;
    const worksheetNames = selectedSheets;
    const selectedBeforeSheet = targetWorkpaperSheets.find(({ worksheetName }) => worksheetName === beforeSheet);
    const workpaperData = JSON.parse(getDataFromLocalStorage(sourceWorkpaperId) || '{}');
    const sjsEnabled = isFeatureFlagEnabled(SJS_API);
    const isDifferentWorkpaper = sourceWorkpaperId !== targetWorkpaperId;
    let jobId;
    workpaperData['copyWorksheet'] = true;
    workpaperData['currentSheets'] = spread.sheets.map(x => x.name());
    setDataToLocalStorage(sourceWorkpaperId, JSON.stringify(workpaperData));
    setIsCopying(true);
    setError();

    try {
      if (isDifferentWorkpaper) {
        if (sjsEnabled) {
          // Handle lock && deleted target
          let isLocked;

          try {
            isLocked = await getLock(targetWorkpaperId);
          } catch (err) {
            if (err.name === WORKPAPER_NOT_FOUND_ERROR) {
              error = {
                message: `Cannot copy sheet. The workpaper '${selectedWorkpaper.value.workpaperName}' no longer exists.`,
              };
              setError(error);
              return;
            } else {
              error = generalErrorMessage;
              setError(generalErrorMessage);
              return;
            }
          }

          if (isLocked) {
            const workpaperStatus = await getWorkflowStatus(targetWorkpaperId);
            const status = workpaperStatus?.status;

            if (status === 'Final') {
              error = {
                message: lockedWorkpaperAsFinalError(selectedWorkpaper.value.workpaperName),
              };
              setError(error);
              return;
            } else {
              const userInfo = getUser();

              if (userInfo.userId !== isLocked.userId) {
                error = {
                  message: lockedWorkpaperError(isLocked.userFullName, selectedWorkpaper.value.workpaperName),
                };

                setError(error);

                return;
              }
            }
          }

          // Start job
          ({ jobId } = await startJob({
            entityId: targetWorkpaperId,
            jobType: WORKPAPER_COPY_WORKSHEET_JOB_TYPE,
            payload: {
              fileName: workbookName,
            },
          }));
          // End SJS
        }

        // Send copy worksheet request
        const result = await copySheet({
          sourceWorkpaperId,
          worksheetNames,
          targetWorkpaperId,
          beforeSheet: selectedBeforeSheet?.worksheetName,
          jobId,
        });

        const logPayload = {
          sourceWorkpaperId,
          sourceWorkpaperName: workbookName,
          targetWorkpaperId,
          targetWorkpaperName: selectedWorkpaper.label,
          selectedSheets: JSON.stringify(selectedSheets),
        };

        const modalPayload = {
          type: 'COPY_SHEET',
          workpaperName: selectedWorkpaper.label,
          workpaperId: selectedTaxPeriod?.value,
          workpaperTaxPeriod: selectedTaxPeriod?.label,
        };

        if (!result.success) {
          const { errorMessage, systemErrorMessage } = result;
          error = errorMessage ?? systemErrorMessage;
          const copySheetException = { message: errorMessage ?? generalErrorMessage };
          setError(copySheetException);
          return;
        }

        if (sjsEnabled) {
          // Handles SJS Copy Sheet
          handleCopySheetSjs(jobId, operationStartTime, modalPayload, logPayload);
        } else {
          // Triggers static generation on target until job finishes
          setSuccessAlertMessage(modalPayload);
        }
      } else {
        worksheetNames.reverse().forEach(async sheetName => copySheetLocally(sheetName, sourceWorkpaperId));
      }
      onClose();
    } finally {
      setIsCopying(false);
      CustomLogger.pushLog(CustomLogger.operations.COPY.SHEET, {
        duration: (Date.now() - operationStartTime).toString(),
        sourceWorkpaperId,
        sourceWorkpaperName: workbookName,
        targetWorkpaperId,
        targetWorkpaperName: selectedWorkpaper.label,
        selectedSheets: JSON.stringify(selectedSheets),
        ...(error && !isEmptyObject(error) && { error: JSON.stringify(error) }),
      });
    }
  };

  const sheets = () => {
    let sheetsOptions = [];
    sheetsOptions = targetWorkpaperSheets.map(({ worksheetName }) => ({
      key: worksheetName,
      value: worksheetName,
    }));
    if (targetWorkpaperSheets.length !== 0) {
      sheetsOptions.push({ key: -1, value: 'move to end' });
    }
    return sheetsOptions;
  };

  return (
    <BTModal
      className="workpaper-copy-sheet-modal"
      id="workpaper-copy-sheet-modal"
      show={show}
      size="modal-lg"
      title="Copy Sheet"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <BTForm className="wkp-source">
          <BTForm.FormGroup
            required
            data-testid="copy-worksheet-workpaper-select"
            htmlFor="workpaper"
            label="To workpaper"
          >
            <BTComboBox
              popOutMenu
              disabled={!workpapers.length}
              id="workpaper-select"
              isSearchable={true}
              maxMenuHeight={100}
              options={workpapers}
              value={selectedWorkpaper}
              onChange={handleWorkpaperChange}
            />
          </BTForm.FormGroup>

          {taxPeriods && (
            <BTForm.FormGroup
              required
              data-testid="copy-worksheet-taxPeriod-select"
              htmlFor="taxPeriod"
              label="Tax Period"
            >
              <BTComboBox
                popOutMenu
                disabled={!taxPeriods.length}
                id="taxPeriod"
                isClearable={true}
                isSearchable={true}
                maxMenuHeight={100}
                options={taxPeriods}
                placeholder={'Select tax period'}
                value={selectedTaxPeriod}
                onChange={handleTaxPeriodChange}
              />
            </BTForm.FormGroup>
          )}

          <BTForm.FormGroup required htmlFor="workpaper-sheets" label="Before sheet">
            <select
              multiple
              disabled={!targetWorkpaperSheets.length}
              id="workpaper-sheets"
              onChange={handleBeforeSheetChange}
            >
              {sheets()?.map(({ key, value }) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </BTForm.FormGroup>
        </BTForm>

        {!!error && (
          <BTAlert btStyle="danger" visible={!!error}>
            <ValidationErrors error={error} />
          </BTAlert>
        )}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton
          btStyle="primary"
          disabled={!beforeSheet || isCopying || error}
          hasSpinner={isCopying}
          id="copy"
          onClick={handleCopy}
        >
          Copy
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}

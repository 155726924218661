import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import useSyncCommands from '../useWorkpaper/useSyncCommands';
import { eventTrackTagNames } from '../../../_shared/EventTrackingContext/constants';

export default function WorkpaperSyncCommandsModal({ show, workpaperId }) {
  const { handleWorkpaperSyncCommandsModalClose, handleSyncCommands } = useSyncCommands(workpaperId);

  return (
    <>
      <style>
        {`
          .bbui-dialog-title-close-icon {
            display: none;
          }
        `}
      </style>
      <BTModal
        btStyle="danger"
        id="sync-commands-modal"
        show={show}
        title="Failed to Synchronize Changes"
        onCloseClick={handleWorkpaperSyncCommandsModalClose}
      >
        <BTModal.Body>
          <p>One or more changes have not been synchronized. To avoid losing your recent changes, synchronize now.</p>
        </BTModal.Body>
        <BTModal.Footer>
          <BTButton btStyle="primary" data-track-tag={eventTrackTagNames.interactive} onClick={handleSyncCommands}>
            Synchronize Changes
          </BTButton>
        </BTModal.Footer>
      </BTModal>
    </>
  );
}

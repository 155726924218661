import ReorderElementInspector from '../../ReorderElementInspector';
import { REORDER_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/Reorder_icon.svg';
import hintImage from '../icons/Reorder_hint_img.svg';
import { REORDER } from './shared/typesConstants';

export class ReorderElementType extends TransformationElementType {
  static TYPE = REORDER;

  static HELP_TEXT = `The reorder block allows you to order fields based on your preference.<img src=${hintImage} alt="Reorder hint"/>`;
  constructor() {
    super(ReorderElementType.TYPE, 'Reorder', REORDER_COLOR, iconImage, ReorderElementType.HELP_TEXT);
  }
  get initialData() {
    return {
      name: this.label,
      type: this.type,
      fields: [],
      newFieldOrder: [],
    };
  }
  get maxCount() {
    return -1;
  }
  get inspectorComponent() {
    return ReorderElementInspector;
  }

  updateFieldOrder(reorderFields, sourceElementFields) {
    const updateReorderFields = [];
    let listOfNewFields = [...sourceElementFields];
    reorderFields.forEach(field => {
      const fieldName = field?.original_name ? field?.original_name : field?.name;
      const fieldExist = sourceElementFields.find(sourceField =>
        sourceField?.original_name ? sourceField?.original_name === fieldName : sourceField?.name === fieldName
      );
      if (fieldExist) {
        updateReorderFields.push(fieldExist);
        listOfNewFields = listOfNewFields.filter(sf =>
          sf?.original_name ? sf?.original_name !== fieldExist?.original_name : sf?.name !== fieldExist?.name
        );
      }
    });
    return listOfNewFields.length > 0 ? updateReorderFields.concat(listOfNewFields) : updateReorderFields;
  }

  applySourceElements(elementData, sourceElements) {
    let sourceDataFields = [];
    if (sourceElements['in']?.elementData?.fields) {
      sourceDataFields = sourceElements['in']?.elementData?.fields;
    } else if (elementData?.fields) {
      sourceDataFields = elementData?.fields;
    }
    const newFieldOrder =
      elementData?.newFieldOrder.length > 0 ? this.updateFieldOrder(elementData?.newFieldOrder, sourceDataFields) : [];
    const fields = newFieldOrder?.length > 0 ? newFieldOrder : sourceDataFields;

    return {
      ...elementData,
      fields: fields,
      newFieldOrder: newFieldOrder,
      sourceDataFields: sourceDataFields,
    };
  }
  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      newFieldOrder: elementData?.newFieldOrder,
      sourceDataFields: elementData?.sourceDataFields,
    };
  }
}

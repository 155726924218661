import React from 'react';
import { BTEmptyState } from '@btas/jasper';
import newDataFlowIcon from '../../_shared/icons/new_data_flow.svg';
import './EmptyState/styles.scss';

export default function EmptyState({ onCreateDataFlowClick }) {
  return (
    <BTEmptyState
      buttonText="New Data Connect Workflow"
      className="wkp-data-flows-empty-container"
      emptyStateDescription="Data connect workflows allow you to automate your repetitive cleansing and computation steps."
      icon={<img alt="New data transformation workflow icon" src={newDataFlowIcon} />}
      message="No Data Connect workflows have been created."
      onButtonClick={onCreateDataFlowClick}
    />
  );
}

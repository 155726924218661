import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BTModal, BTForm, BTComboBox, BTButton } from '@btas/jasper';
import './styles.scss';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import updateElementDataConfig from './UpdateElementDataConfig';

const UpdateFieldNameReferenceDialog = ({
  state = {
    show: false,
  },
  setState,
  setElementData,
  onClose,
  setFilePropertiesDialog,
  filePropertiesDialog,
}) => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const leftLabel = 'Previous field';
  const rightLabel = 'New field';
  const stepNameLabel = dataFlowState?.workingElement ? `for ${dataFlowState?.workingElement?.elementData?.name}` : '';
  const [mapping, setMapping] = useState([]);
  useEffect(() => {
    if (state?.oldFields) {
      const map = state?.oldFields.map(oldField => {
        let hasMatch = state?.newFields.find(newField => oldField.name === newField.name);
        const newfieldMap = hasMatch ? { label: hasMatch.name, value: hasMatch.name, hasError: false } : undefined;
        return { oldFields: { label: oldField.name, value: oldField.name, hasError: false }, newFields: newfieldMap };
      });
      setMapping(map);
    }
  }, [state?.oldFields, state?.newFields]);

  const handleClose = useCallback(() => {
    setState({ show: false });
    setFilePropertiesDialog({ ...filePropertiesDialog, saving: false });
  }, [setState, filePropertiesDialog, setFilePropertiesDialog]);

  const onChange = (i, option) => {
    let newMapping = [...mapping];
    newMapping[i]['newFields'] = option;

    const updatedMapping = newMapping.reduce((acc, obj) => {
      let updatedNewFields = {};
      const oldFields = { ...obj.oldFields };
      let currentFields = obj.newFields?.value;
      if (currentFields) {
        const listOfRepeatedValue = mapping.filter(field => field.newFields?.value === currentFields);
        const hasDuplicates = listOfRepeatedValue.length > 1;
        updatedNewFields = { ...obj.newFields, hasError: hasDuplicates };
      } else {
        updatedNewFields = { ...obj.newFields };
      }

      return [...acc, { oldFields: oldFields, newFields: updatedNewFields }];
    }, []);

    setMapping(updatedMapping);
  };

  const onSubmit = () => {
    if (mapping.some(field => field.newFields?.hasError)) {
      return;
    }
    setElementData(state.inputId, {
      ...state.elementData,
      fields: state?.newFields,
      pendingSourceFileVersionId: state.fileVersionId,
    });
    const dataFlowStateLinks = dataFlowState.links;
    const dataFlowStateElements = dataFlowState.elements;
    const updatedDataFlowStateConfig = updateElementDataConfig(
      dataFlowStateLinks,
      dataFlowStateElements,
      dataFlowState.workingElement,
      mapping
    );

    setState({
      show: false,
      mapping: updatedDataFlowStateConfig,
      triggerReRun: true,
    });

    onClose();
  };

  const options = useMemo(() => {
    return state.newFields?.map(field => ({ label: field.name, value: field.name, hasError: false })) || [];
  }, [state?.newFields]);

  return (
    <BTModal
      className="df-find-and-replace-dialog-container"
      show={state.show}
      title="Update Field Name References"
      onCloseClick={handleClose}
    >
      <BTModal.Body className="wkp-modal-body">
        <p>
          {`The new data ${stepNameLabel} has different field names than previously input. 
          Map previous fields to new fields to update downstream references automatically in your Data Connect workflow.`}
        </p>
        <div className="wkp-find-and-replace-fields-selectors">
          {mapping?.map((field, index) => {
            return (
              <div key={`${index}_${field.left?.name}${field.right?.name}`} className="wkp-find-and-replace-field-row">
                <BTForm.FormGroup
                  errorText={''}
                  hasError={field.newFields?.hasError}
                  label={index === 0 ? leftLabel : null}
                >
                  <BTComboBox popOutMenu disabled={true} maxMenuHeight={100} value={field?.oldFields || undefined} />
                </BTForm.FormGroup>
                <BTForm.FormGroup
                  errorText={''}
                  hasError={field.newFields?.hasError}
                  label={index === 0 ? rightLabel : ''}
                >
                  <BTComboBox
                    popOutMenu
                    isClearable={field.newFields?.value ? true : false}
                    maxMenuHeight={100}
                    options={options}
                    placeholder={`Equivalent new field, if applicable`}
                    value={options.find(options => options?.value === field.newFields?.value) || undefined}
                    onChange={value => onChange(index, value)}
                  />
                </BTForm.FormGroup>
              </div>
            );
          })}
          {mapping.some(field => field.newFields?.hasError) && (
            <div className="wkp-find-and-replace-error">Column name must be unqiue.</div>
          )}
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={handleClose}>Cancel</BTButton>
        <BTButton btStyle="primary" id="df-update-field-name-reference-button" onClick={onSubmit}>
          Update
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default UpdateFieldNameReferenceDialog;

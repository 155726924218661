import React from 'react';
import { BTSpinner } from '@btas/jasper';

import './Spinner/styles.scss';

export default function Spinner({ description, extraStyle }) {
  return (
    <div className="wkp-spinner-wrapper" style={extraStyle ? extraStyle : {}}>
      <BTSpinner />
      {description && <p>{description}</p>}
    </div>
  );
}

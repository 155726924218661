import React, { useContext, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { DataFlowEditorContext } from './DataFlowEditorContext';

const CONFIRMATION_MESSAGE =
  'The Data Connect workflow has unsaved changes. If you leave before saving, your changes will be lost. What would you like to do?';

const DirtyStateHandler = () => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { isDirty } = dataFlowState;
  useEffect(() => {
    if (!isDirty) {
      return;
    }
    const listener = event => {
      event.preventDefault();
      if (event) {
        event.returnValue = CONFIRMATION_MESSAGE;
      }
      return CONFIRMATION_MESSAGE;
    };
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, [isDirty]);

  return (
    <Prompt
      message={(location, action) => {
        if (!location.pathname.includes('editor')) {
          return CONFIRMATION_MESSAGE;
        } else {
          return null;
        }
      }}
      when={isDirty}
    />
  );
};

export default DirtyStateHandler;

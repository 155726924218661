import { redirectToLogin, refreshExpiredToken } from './jwtAuth';
import { isTokenExpired, refreshToken } from '../auth/_shared/authUtils';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { WKP_REFRESH_TOKEN_REWRITE_UI } from '../../constants/featureFlags';
import { getUserInfoV2 } from './jwtAuth';
import CustomLogger from './Logger/CustomLogger';

async function handleAuth(res) {
  const missingTokenErrorCode = 'GN0002';
  const unauthorizedAccessErrorCode = 'GN0003';
  if (res.status === 400 || res.status === 401) {
    const { error } = await res.clone().json();
    if (error && (error.code === missingTokenErrorCode || error.code === unauthorizedAccessErrorCode)) {
      redirectToLogin();
    }
  }
  return res;
}

function performFetch(url, fetchOptions) {
  const tokenExpired = isTokenExpired();

  /* 
    If the btas-wkp-refresh-token-rewrite-ui feature flag is on, then this logic will do the following:
    -Check if the existing token expired. If the token expired, then a new one should be retrieved
    -Make an API call.
  */
  if (isFeatureFlagEnabled(WKP_REFRESH_TOKEN_REWRITE_UI) && tokenExpired) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: `Retrieving refresh token before making API call to ${url}`,
    });

    return refreshToken(() => {}, getUserInfoV2, refreshExpiredToken)
      .then(() => {
        return fetch(url, {
          credentials: 'include',
          ...fetchOptions,
          headers: { ...fetchOptions.headers, Authorization: `Bearer ${'token'}` },
        });
      })
      .then(res => handleAuth(res));
  } else {
    return fetch(url, {
      credentials: 'include',
      ...fetchOptions,
      headers: { ...fetchOptions.headers, Authorization: `Bearer ${'token'}` },
    }).then(res => handleAuth(res));
  }
}

export function get(url, fetchOptions = {}) {
  return performFetch(url, { ...fetchOptions, method: 'GET' });
}

export function getToggle(url) {
  // Temp solution for FF until ECDS is integrated
  return fetch(url, { headers: { Authorization: `Bearer ${'token'}` }, method: 'GET' }).then(handleAuth);
}

export function post(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'POST',
  });
}

export function put(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'PUT',
  });
}

export function patch(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'PATCH',
  });
}

export function remove(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'DELETE',
  });
}

import React from 'react';

export default function DataFlowConfigurationPanel({ onClose }) {
  return (
    <>
      <div className="wkp-df-right-panel">
        <div className="wkp-df-inspector-title">
          <div className="wkp-df-panel-header">
            <span className="wkp-df-right-panel-name">Configure</span>
            <button className="wkp-df-right-panel-close" onClick={onClose}>
              <i aria-hidden="true" className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div className="wkp-df-panel-content">Select a block to view or edit its configuration.</div>
      </div>
    </>
  );
}

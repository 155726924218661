import { useContext, useEffect } from 'react';
import EditorContext from './EditorContext';
import { useMetadata } from './Spreadsheet/SideBar/SettingsPanel/useMetadata';
import GC from '../../../SpreadSheets';
import { useEditorToggle } from './useEditorToggle';
import { createWorkpaperDataReferences } from './DataReference/apis';
import { DATA_LINK } from '../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { DATA_LINK_ADDED } from './HistoryTracker/useHistoryTracker';
import { applyTimestamp } from '../../../sjs-cmd-process';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DATA_LINKS } from '../../../constants/featureFlags';
import { setValueOnServer } from './useWorkpaper/processCommand';

export function toA1Notation(row, column, isRelative = false) {
  const charCodeA = 65;
  const alphabetLength = 26;
  let columnLabel = '';

  for (let c = column; c >= 0; c = Math.floor(c / 26) - 1) {
    const transposition = c % alphabetLength;
    columnLabel = String.fromCharCode(charCodeA + transposition) + columnLabel;
  }

  const rowLabel = row + 1;
  return isRelative ? `${columnLabel}${rowLabel}` : `$${columnLabel}$${rowLabel}`;
}

export function useDataLink({ workpaperId }) {
  const targetWorkbookStorage = 'linking.target';
  const sourceWorkbookStorage = 'linking.source';
  const endDataLinkCommandName = 'endDataLink';
  const sourceSelectionColor = '#AB63DB';
  const defaultSelectionColor = '#0073FF';
  const isDataLinksEnabled = isFeatureFlagEnabled(DATA_LINKS);

  const { spreadRef, setDataLinkStatus, addToHistory, enqueueCommands, dataReferences, dataLinksChannelsRef } =
    useContext(EditorContext);
  const { getMetadata } = useMetadata({ workpaperId });
  const { disableEditor, enableEditor } = useEditorToggle();

  async function handleFinishDataLink() {
    const sheet = spreadRef.current.getActiveSheet();
    const [{ row, col: column }] = sheet.getSelections();
    const sheetName = sheet.name();
    const value = sheet.getValue(row, column);
    const { taxPeriod, name } = await getMetadata();

    localStorage.setItem(
      sourceWorkbookStorage,
      JSON.stringify({
        workpaperId,
        taxPeriod,
        name,
        sheetName,
        row,
        column,
        value,
        isFinal: true,
      })
    );
  }

  function sourceWorkbookHandler(linkStorageState) {
    if (linkStorageState && linkStorageState.workpaperId !== workpaperId) {
      setDataLinkStatus({ active: true, targetWorkpaperName: linkStorageState.workpaperName });
      disableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', false);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, GC.Spread.Commands.Key.enter);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = sourceSelectionColor;
    } else {
      setDataLinkStatus({ active: false, targetWorkpaperName: null });
      enableEditor();
      spreadRef.current.commandManager().setShortcutKey('commitInputNavigationDown', GC.Spread.Commands.Key.enter);
      spreadRef.current.commandManager().setShortcutKey(endDataLinkCommandName, false);
      spreadRef.current.getActiveSheet().options.selectionBorderColor = defaultSelectionColor;
    }
  }

  function targetWorkbookHandler(linkStorageState, event) {
    if (linkStorageState.workpaperId === workpaperId) {
      const {
        workpaperId: sourceId,
        taxPeriod,
        name,
        sheetName,
        row: sourceRow,
        column: sourceColumn,
        value,
        isFinal,
      } = JSON.parse(event.newValue);
      const { row: targetRow, column: targetColumn, previousValue } = linkStorageState;

      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';

      const a1Coordinate = toA1Notation(sourceRow, sourceColumn);
      const formula = `'${effectiveTaxPeriod}[${name}]${sheetName}'!${a1Coordinate}`;

      const ss = spreadRef.current;
      const sheet = ss.getActiveSheet();

      sheet.setFormula(targetRow, targetColumn, formula);
      if (!isFinal) return;
      sheet.setFormula(targetRow, targetColumn, null);

      const partialWorkbook = {
        [sheetName]: {
          [sourceRow]: {
            [sourceColumn]: value,
          },
        },
      };
      const command = {
        cmd: 'editCell',
        sheetName: sheet.name(),
        row: targetRow,
        col: targetColumn,
        applyResult: 0,
        newValue: `=${formula}`,
        activeRowIndex: targetRow,
        activeColIndex: targetColumn,
        actionType: 0,
        partialWorkbook,
        workpaperName: name,
        taxPeriod: effectiveTaxPeriod,
      };

      ss.commandManager().execute(command);
      addToHistory(
        {
          cmd: DATA_LINK_ADDED,
          undo: () => {
            console.log('UNDOING', DATA_LINK_ADDED);
            ss.undoManager().undo();
            sheet.setValue(targetRow, targetColumn, previousValue);
          },
        },
        ss
      );
      ss.updateExternalReference(name, partialWorkbook, effectiveTaxPeriod, true);
      const referenceId = uuidv4();
      const dataLinkReference = {
        workpaperId,
        row: targetRow,
        column: targetColumn,
        referenceId,
        id: referenceId,
        sheetName: sheet.name(),
        referenceType: DATA_LINK,
        type: DATA_LINK,
        oldValue: value,
        newValue: value,
        value,
        parameters: JSON.stringify({
          workpaperId: sourceId,
          taxPeriod,
          workpaperName: name,
          sheetName,
          a1Coordinate,
          row: sourceRow,
          column: sourceColumn,
        }),
      };
      createWorkpaperDataReferences(workpaperId, [dataLinkReference]);
      dataReferences.current.push(dataLinkReference);
      dataLinksChannelsRef.current?.targetCreated?.postMessage(dataLinkReference);
      sheet.endEdit(true);
    }
  }

  function setupSourcesUpdateChannelListener() {
    function listener(event) {
      const updatedReferences = event.data;
      if (updatedReferences?.length) {
        dataReferences.current = dataReferences.current.map(ref => updatedReferences.find(r => r.id === ref.id) ?? ref);
      }
    }
    dataLinksChannelsRef.current?.sourcesUpdate?.addEventListener('message', listener);
    return listener;
  }

  function setupTargetCreatedChannelListener() {
    function listener(event) {
      const createdReference = event.data;
      const params = JSON.parse(createdReference.parameters);
      if (params.workpaperId === workpaperId) {
        dataReferences.current.push(createdReference);
      }
    }
    dataLinksChannelsRef.current?.targetCreated?.addEventListener('message', listener);
    return listener;
  }

  function setupStorageListener() {
    if (!window) return;
    function listener(event) {
      if (event.storageArea === localStorage) {
        const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
        switch (event.key) {
          case targetWorkbookStorage: {
            sourceWorkbookHandler(linking);
            break;
          }
          case sourceWorkbookStorage: {
            targetWorkbookHandler(linking, event);
            break;
          }
          default:
            break;
        }
      }
    }
    window.addEventListener('storage', listener);
    return listener;
  }

  function sourceWorkbookSpreadListeners() {
    async function setDataLinkSource(row, column, sheetName, value) {
      const { taxPeriod, name } = await getMetadata();
      localStorage.setItem(
        sourceWorkbookStorage,
        JSON.stringify({
          workpaperId,
          taxPeriod,
          name,
          sheetName,
          row,
          column,
          value,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss) return;

    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, function (e, { sheet, newSelections, sheetName }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        const [{ row, col: column }] = newSelections;
        const value = sheet.getValue(row, column);
        setDataLinkSource(row, column, sheetName, value);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, function (e, { newSheet, oldSheet }) {
      const linking = JSON.parse(localStorage.getItem(targetWorkbookStorage));
      if (linking && workpaperId !== linking.workpaperId) {
        oldSheet.options.selectionBorderColor = defaultSelectionColor;
        newSheet.options.selectionBorderColor = sourceSelectionColor;
        const [{ row, col }] = newSheet.getSelections();
        const value = newSheet.getValue(row, col);
        const cellData = JSON.parse(localStorage.getItem(sourceWorkbookStorage));
        cellData.sheetName = newSheet.name();
        cellData.row = row;
        cellData.column = col;
        cellData.value = value;
        localStorage.setItem(sourceWorkbookStorage, JSON.stringify(cellData));
      }
    });
  }

  function targetWorkbookSpreadListener() {
    async function setDataLinkTarget(row, column, previousValue) {
      const { name } = await getMetadata();
      localStorage.setItem(
        targetWorkbookStorage,
        JSON.stringify({
          workpaperId,
          workpaperName: name,
          row,
          column,
          previousValue,
        })
      );
    }
    const ss = spreadRef.current;

    if (!ss) return;

    ss.bind(GC.Spread.Sheets.Events.EditChange, function (e, { sheet, row, col, editingText }) {
      if (editingText === '=') {
        setDataLinkTarget(row, col, sheet.getValue(row, col));
      } else {
        localStorage.removeItem(targetWorkbookStorage);
        localStorage.removeItem(sourceWorkbookStorage);
      }
    });

    ss.bind(GC.Spread.Sheets.Events.EditEnded, function (e, a) {
      localStorage.removeItem(targetWorkbookStorage);
      localStorage.removeItem(sourceWorkbookStorage);
    });

    function updateFormulaEventHandler(e, args) {
      if (!dataReferences.current?.length) return;

      const { sheet } = args;
      let reference;
      if (e.type === 'CellClick') {
        const { row, col } = args;
        reference = dataReferences.current.find(
          ref =>
            ref.type === DATA_LINK &&
            ref.sheetName === sheet.name() &&
            ref.workpaperId === workpaperId &&
            ref.row === row &&
            ref.column === col
        );
      } else if (e.type === 'SelectionChanged') {
        const { newSelections } = args;
        reference = dataReferences.current.find(
          ref =>
            ref.type === DATA_LINK &&
            ref.sheetName === sheet.name() &&
            ref.workpaperId === workpaperId &&
            newSelections[0].contains(ref.row, ref.column)
        );
      }

      if (!reference) return;

      const { taxPeriod, workpaperName, a1Coordinate, sheetName, row, column } =
        typeof reference.parameters === 'string' ? JSON.parse(reference.parameters) : reference.parameters;
      const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
      const formula = `='${effectiveTaxPeriod}[${workpaperName}]${sheetName}'!${a1Coordinate}`;
      sheet.setFormula(reference.row, reference.column, formula);

      const partialWorkbook = {
        [sheetName]: {
          [row]: {
            [column]: reference.value,
          },
        },
      };

      const previousValue = sheet.getValue(reference.row, reference.column);
      ss.updateExternalReference(workpaperName, partialWorkbook, effectiveTaxPeriod, true);
      const command = applyTimestamp(setValueOnServer(sheet, reference.row, reference.column, previousValue, formula));
      command.partialWorkbook = partialWorkbook;
      command.workpaperName = workpaperName;
      command.taxPeriod = effectiveTaxPeriod;
      command.isUndoable = false;
      enqueueCommands([{ commandText: JSON.stringify(command) }]);
    }

    ss.bind(GC.Spread.Sheets.Events.CellClick, updateFormulaEventHandler);
    ss.bind(GC.Spread.Sheets.Events.SelectionChanged, updateFormulaEventHandler);
  }

  function setupSpreadListeners() {
    targetWorkbookSpreadListener();
    sourceWorkbookSpreadListeners();

    spreadRef.current.commandManager().register(endDataLinkCommandName, { execute: handleFinishDataLink });
  }

  useEffect(() => {
    let storageListener;
    let sourcesUpdatedListener;
    let targetCreatedListener;
    let channels;
    if (isDataLinksEnabled) {
      storageListener = setupStorageListener();
      sourcesUpdatedListener = setupSourcesUpdateChannelListener();
      targetCreatedListener = setupTargetCreatedChannelListener();
      channels = dataLinksChannelsRef.current;
    }

    return () => {
      try {
        if (isDataLinksEnabled) {
          window.removeEventListener('storage', storageListener);
          channels?.sourcesUpdated?.removeEventListener('message', sourcesUpdatedListener);
          channels?.targetCreated?.removeEventListener('message', targetCreatedListener);
        }
      } catch (error) {}
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { setupSpreadListeners };
}

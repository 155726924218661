import CommandProcessor from './../../../../cmd-process';

export function setValueOnServer(sheet, row, column, oldValue, value, dirtyCellsToUpdate = null) {
  return CommandProcessor.setValueOnServer(sheet, row, column, oldValue, value, dirtyCellsToUpdate);
}

export function setValueAndTagOnServer(sheet, row, column, tag) {
  return CommandProcessor.setValueAndTagOnServer(sheet, row, column, tag);
}

export function prepareCommand(GC, spreadSheet, command, actionType) {
  return CommandProcessor.prepareCommand(GC, spreadSheet, command, actionType);
}

export function setCountCommand(ss, rowCount, colCount, workbookId, callback, lastCommandAppliedTime) {
  return CommandProcessor.setCountCommand(ss, rowCount, colCount, workbookId, callback, lastCommandAppliedTime);
}

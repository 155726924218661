import { useState, useEffect, useRef } from 'react';
import { cleanUpJobs, getJobsStatus } from '../../../_shared/jobs/apis';
import { getActiveBatchId, mapJobToProcess } from '../../../_shared/jobs/jobHelpers';
import { WORKPAPER_JOB_STATUS_COMPLETED, WORKPAPER_JOB_STATUS_FAILED } from '../../../_shared/jobs/jobStatus';
import { WORBOOK_JSON_SYNC, WORKPAPER_COPY_WORKSHEET_JOB_TYPE } from '../../../_shared/jobs/jobTypes';

export default function useJobsOverlay() {
  const [minimizeOverlay, setMinimizeOverlay] = useState(false);
  const [closeOverlay, setCloseOverlay] = useState(false);

  const jobsHeartbeatCheckTimeout = useRef(null);
  const jobsHeartbeatCheckInterval = 1000;
  const [processes, setProcesses] = useState([]);
  const [hasActiveProcess, setHasActiveProcess] = useState(false);
  const [activeProcesses, setActiveProcesses] = useState(0);
  const [failedProcesses, setFailedProcesses] = useState(0);
  const [onJobResolvedCallbacks, setOnJobResolvedCallbacks] = useState({});

  function jobsHeartbeatCheck() {
    if (jobsHeartbeatCheckTimeout.current) {
      clearTimeout(jobsHeartbeatCheckTimeout.current);
      jobsHeartbeatCheckTimeout.current = null;
    }

    jobsHeartbeatCheckTimeout.current = setTimeout(loadJobs, jobsHeartbeatCheckInterval);
  }

  const loadJobs = async (preloadedJobs = []) => {
    const batchId = getActiveBatchId();
    const batchIds = batchId ? [batchId] : null;

    let jobs = await getJobsStatus({
      batchIds,
    });
    jobs = [...jobs, ...preloadedJobs.filter(j => !jobs.find(({ id }) => j.id === id))];

    const filterJobs = [WORKPAPER_COPY_WORKSHEET_JOB_TYPE, WORBOOK_JSON_SYNC]; // filter out jobs not to show in the overlay
    const processList = jobs.filter(({ jobType }) => !filterJobs.includes(jobType)).map(mapJobToProcess);

    const jobsInProgress = [];
    const jobsFailed = [];
    const jobsCompleted = [];

    for (let i = 0; i < processList.length; i++) {
      const job = processList[i];
      if (onJobResolvedCallbacks[job.jobId]) {
        const onJobResolved = onJobResolvedCallbacks[job.jobId][job.status];
        if (onJobResolved && !onJobResolved.hasBeenCalled) {
          onJobResolved.callback();
          onJobResolved.hasBeenCalled = true;
          setOnJobResolvedCallbacks({
            ...onJobResolvedCallbacks,
            [job.jobId]: { ...onJobResolvedCallbacks[job.jobId], [job.status]: onJobResolved },
          });
        }
      }

      if (job.status === WORKPAPER_JOB_STATUS_COMPLETED) {
        jobsCompleted.push(job);
      } else if (job.status === WORKPAPER_JOB_STATUS_FAILED) {
        jobsFailed.push(job);
      } else {
        jobsInProgress.push(job);
      }
    }

    if (batchId) {
      setProcesses(processList);
      setActiveProcesses(jobsInProgress.length);
      setFailedProcesses(jobsFailed.length);
    }

    // CHECK IF THE HEARTBEAT IS NOT NEEDED ANYMORE
    if ((jobsInProgress && !jobsInProgress.length) || jobs.length === jobsCompleted.length + jobsFailed.length) {
      setHasActiveProcess(false);
      // FINISH THE HEARTBEAT CHECKER
      clearTimeout(jobsHeartbeatCheckTimeout.current);
      jobsHeartbeatCheckTimeout.current = null;
    }
  };

  function bindOnJobResolved(jobStatus = WORKPAPER_JOB_STATUS_COMPLETED) {
    return (jobId = '', callback = function () {}, ...args) => {
      const callbackWrapper = () => callback(...args);
      const onJobResolved = { callback: callbackWrapper, hasBeenCalled: false };
      setOnJobResolvedCallbacks(resolvedCallbacksState => ({
        ...resolvedCallbacksState,
        [jobId]: { ...resolvedCallbacksState[jobId], [jobStatus]: onJobResolved },
      }));
    };
  }

  useEffect(() => {
    jobsHeartbeatCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes, onJobResolvedCallbacks, hasActiveProcess]);

  useEffect(() => {
    if (closeOverlay) {
      processes.splice(0, processes.length);
      setMinimizeOverlay(true);
      cleanUpJobs();
      setCloseOverlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOverlay]);

  useEffect(() => {
    jobsHeartbeatCheck();
    if (activeProcesses > 0) {
      setMinimizeOverlay(false);
      setHasActiveProcess(true);
    }
    if (processes.length <= 0) {
      setMinimizeOverlay(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProcesses, onJobResolvedCallbacks, hasActiveProcess]);
  return {
    minimizeOverlay,
    hasProcess: processes.length > 0,
    hasFailedProcess: failedProcesses > 0,
    processes,
    activeProcesses,
    hasActiveProcess,
    loadJobs,
    setMinimizeOverlay,
    setCloseOverlay,
    bindOnJobCompleted: bindOnJobResolved(WORKPAPER_JOB_STATUS_COMPLETED),
    bindOnJobFailed: bindOnJobResolved(WORKPAPER_JOB_STATUS_FAILED),
  };
}

import { MERGE_COLOR } from '../../shared/colors';
import { MultiMergeElementInspector } from '../../MultiMergeElementInspector';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/multi_merge_block_icon.svg';
import hintImage from '../icons/multi_merge_hint_img.svg';
import { MULTIMERGE } from './shared/typesConstants';

export class MultiMergeElementType extends TransformationElementType {
  static TYPE = MULTIMERGE;

  static HELP_TEXT = `The multi merge block combines rows from multiple inputs. Field names and data types must match among all inputs.<img src=${hintImage} alt="Multi Merge hint" />`;

  constructor() {
    super(MultiMergeElementType.TYPE, `Multi\nMerge`, MERGE_COLOR, iconImage, MultiMergeElementType.HELP_TEXT);
    this.linkedSources = [];
  }

  get initialData() {
    return {
      ...super.initialData,
      inputNames: [],
      upstreamElementsConnected: [],
      inputCount: 0,
    };
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return MultiMergeElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    const sources = Object.keys(sourceElements);
    const upstreamElementsConnected = [];
    const sourceElementsNames =
      sourceElements === undefined
        ? []
        : sources
            ?.filter(key => sourceElements[key] !== undefined)
            ?.map(key => {
              // On first renders some connected elements can be undefined until the dataflow is updated
              upstreamElementsConnected.push({
                blockId: sourceElements[key].id,
                fields: sourceElements[key].elementData?.fields,
              });
              return sourceElements[key]?.elementData?.name;
            });

    let fields = [];
    if (sources.length) {
      fields = sourceElements[sources[0]]?.elementData?.fields;
    }

    return {
      ...elementData,
      fields: fields,
      inputNames: sourceElementsNames,
      upstreamElementsConnected: upstreamElementsConnected,
      inputCount: Object.keys(sourceElements).length,
    };
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      inputNames: elementData.inputNames,
      upstreamElementsConnected: elementData.upstreamElementsConnected,
      inputCount: elementData.inputCount,
    };
  }
}

import { BTGrid, BTIcon, BTPopover, BTPagination } from '@btas/jasper';
import React, { useContext, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { wijmoKey } from '../../../configs/params';
import Spinner from '../../_shared/Spinner';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import PreviewError from './DataFlowOutputPreview/PreviewError';
import {
  getOutputRecords,
  getColumns,
  formatOutputRecords,
  objToHeadersWithoutRecords,
} from './DataFlowOutputPreview/utils';

import { getOriginalName } from '../shared/utils/FieldHashUtils';

import { TEXT } from './shared/fieldTypes';
import './DataFlowOutputPreview/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import {
  IMPROVE_DATAFLOW_PREVIEW,
  WKP_DF_PREVIEW_PAGINATION,
  WKP_INPUT_FILE_IMPORT,
} from '../../../constants/featureFlags';
import { FILE_IMPORT_RUNNING_STATUS } from '../DataFlowEditorPage/FileSettings/FilePropertiesStatusTypes';
import SpreadView from './DataFlowOutputPreview/SpreadView';
import { INPUT } from '../DataFlowEditorPage/elementType/types/shared/typesConstants';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import Restore from './shared/icons/Restore.svg';
import Minimize from './shared/icons/Minimize.svg';
import Maximize from './shared/icons/Maximize.svg';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import OutputToggle from './DataFlowOutputPreview/PreviewNav/OutputToggle';
import { OutputToggleUtils } from './DataFlowOutputPreview/OutputToggleUtils';

export default function DataFlowOutputPreview({ callback }) {
  const { dataFlowState, previewState, previewActions, dataFlowActions, fileImport } =
    useContext(DataFlowEditorContext);
  const { id: dataFlowId, activeElement, workingElement, preview, previewOutputNode } = dataFlowState;
  const { runPreview, setToggleInput } = previewActions;
  const { previewRun, isWaiting, error, validationErrors, previewRecords, totalRecordsCount } = previewState;
  const { setPreview } = dataFlowActions;
  const { importFileState } = fileImport;

  const [previewRecordsCount, setPreviewRecordsCount] = useState({
    count: 0,
    isFiltered: false,
    isSorted: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 25;
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = currentPage * PAGE_SIZE;
  const previewPaginationEnabled = isFeatureFlagEnabled(WKP_DF_PREVIEW_PAGINATION);
  const wkpInputImportEnabled = isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT);
  const [expandType, setExpandType] = useState('restore');
  const hasErrors = error || validationErrors;

  const outputPreviewRecords = getOutputRecords(dataFlowState, previewRecords);

  const previewFields = activeElement?.elementType?.getPreviewColumns(activeElement?.elementData, previewOutputNode);

  const formattedOutputPreviewRecords = useMemo(
    () => formatOutputRecords(previewFields, outputPreviewRecords),
    [outputPreviewRecords, previewFields]
  );

  const activeElementId = useMemo(() => activeElement?.id, [activeElement?.id]);

  const canEditWorkflow = useCanEditWorkflow();
  const canRunPreview = activeElement && activeElement.elementType.canPreview(activeElement.elementData);
  const previewInspector = useMemo(() => activeElement && canRunPreview, [activeElement, canRunPreview]);
  const totalColumnCount = useMemo(
    () =>
      formattedOutputPreviewRecords?.recordTypes ? Object.keys(formattedOutputPreviewRecords?.recordTypes).length : 0,
    [formattedOutputPreviewRecords?.recordTypes]
  );
  const resetView = () => {
    setPreviewRecordsCount({
      count: 0,
      isFiltered: false,
      isSorted: false,
    });

    setCurrentPage(1);
  };

  useEffect(() => {
    callback(previewInspector);
  }, [callback, previewInspector]);

  useEffect(() => {
    if (activeElementId) {
      resetView();
    }
  }, [activeElementId]);

  useEffect(() => {
    if (activeElementId && canRunPreview) {
      runPreview(activeElementId);
    }
    setToggleInput(null);
  }, [setToggleInput, activeElementId, canRunPreview, runPreview, activeElement?.type]);

  const columns = useMemo(
    () =>
      getColumns(
        activeElement,
        previewRun?.configuration?.elements[activeElementId],
        startIndex,
        endIndex,
        previewOutputNode
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeElement, previewRun, activeElementId, currentPage, previewOutputNode]
  );

  useEffect(() => {
    setExpandType(preview);
  }, [preview]);

  const dataFiltered = useMemo(() => previewRecordsCount?.isFiltered, [previewRecordsCount?.isFiltered]);

  const sortedFormattedOutputPreviewRecords = useMemo(() => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW) && columns) {
      // sort the elements so it matches with the ones displayed in the Block Configuration Panel
      const columnNames = columns
        .filter(column => column.hidden === false || column.hidden === undefined)
        .map(column => getOriginalName(column));
      const headers = objToHeadersWithoutRecords(columns);

      const records =
        formattedOutputPreviewRecords.records?.length > 0
          ? formattedOutputPreviewRecords.records?.map(record => {
              const row = {};
              columnNames.forEach(name => (row[name] = record[name]));

              return row;
            })
          : headers;

      return { records, recordTypes: formattedOutputPreviewRecords.recordTypes };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, formattedOutputPreviewRecords, dataFiltered]);

  const columnLengh = useRef(totalColumnCount);
  const rowsLength = useRef(totalRecordsCount);

  const getGrid = useCallback(() => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
      return (
        <SpreadView
          endIndex={endIndex}
          expand={expandType}
          previewOutputNode={previewOutputNode}
          previewRecordsCount={previewRecordsCount}
          records={sortedFormattedOutputPreviewRecords}
          setPreviewRecordsCount={setPreviewRecordsCount}
          startIndex={startIndex}
        ></SpreadView>
      );
    } else {
      return (
        <BTGrid
          stickyHeaders
          allowResizing={1}
          className="wkp-preview-grid"
          data={formattedOutputPreviewRecords}
          gridHeight={200}
          wijmoKey={wijmoKey}
        >
          {columns.map(({ name, type, isSelected, hidden }) => (
            <BTGrid.Header
              key={name}
              align="left"
              binding={name}
              columnClass={isSelected === false ? 'wkp-shaded' : null}
              hidden={hidden}
              minWidth={240}
              width={type === TEXT ? '4*' : '3*'}
            >
              {name}
            </BTGrid.Header>
          ))}
        </BTGrid>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewRecordsCount, sortedFormattedOutputPreviewRecords, formattedOutputPreviewRecords, columns, expandType]);

  const widthStyle = {
    width: `calc(100vw - ${canEditWorkflow ? '669' : '575'}px)`,
  };

  const returnGrid = () => {
    return hasErrors ? (
      <PreviewError error={error} extraStyle={widthStyle} validationErrors={validationErrors} />
    ) : (
      getGrid()
    );
  };

  const getPreviewTitle = useCallback(() => {
    const previewText = 'Preview Output ';
    const PREVIEW_RECORDS_LIMIT = 100;

    if (
      isWaiting ||
      (wkpInputImportEnabled &&
        //check element import is not running
        workingElement?.elementData.import?.status === FILE_IMPORT_RUNNING_STATUS &&
        importFileState &&
        //check from importFileState if the import is not complete
        importFileState[workingElement.id]?.currentStep !== 'STEP_COMPLETE')
    ) {
      return previewText.concat(`(loading records...)`);
    }

    if (hasErrors) {
      //ignoring the block not connected errors
      if ((validationErrors && !validationErrors[0]?.message.includes('not connected')) || error) {
        CustomLogger.pushLog(CustomLogger.operations.LOAD_PREVIEW, {
          workflow_id: dataFlowId,
          message: 'Error loading preview',
          block: activeElement.type,
          validationErrors: JSON.stringify(validationErrors),
          error: JSON.stringify(error),
        });
      }

      return previewText.concat(`(showing 0 records)`);
    }

    const containsNewSourceFiles = workingElement?.elementData?.containsNewSourceFiles;
    //if the input block has not any new files uploaded, then input title should show 0 records
    if (containsNewSourceFiles !== undefined && !containsNewSourceFiles && activeElement?.type === INPUT) {
      const defaultRecordsCount = 0;
      return previewText.concat(`(showing ${defaultRecordsCount} of ${defaultRecordsCount} records)`);
    }
    const filteredText = previewRecordsCount?.isFiltered && !isWaiting ? ' – Filtered' : '';
    if (totalRecordsCount && totalRecordsCount > 100) {
      const count = previewRecordsCount.isFiltered ? previewRecordsCount.count : PREVIEW_RECORDS_LIMIT;
      return previewText.concat(`(showing ${count} of ${totalRecordsCount} records)${filteredText}`);
    } else {
      const count = previewRecordsCount.isFiltered ? previewRecordsCount.count : previewRecords?.length;
      return previewText.concat(`(showing ${count} of ${previewRecords?.length} records)${filteredText}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeElement?.type,
    hasErrors,
    isWaiting,
    previewRecords?.length,
    previewRecordsCount,
    totalRecordsCount,
    workingElement?.id,
    importFileState,
    workingElement?.elementData?.import,
    wkpInputImportEnabled,
    workingElement?.elementData?.containsNewSourceFiles,
    error,
    validationErrors,
  ]);

  const handlePreviewSize = e => {
    const value = e.currentTarget.getAttribute('value');
    setPreview(value);
  };

  let previewView;
  if (expandType === 'restore') {
    previewView = canEditWorkflow ? 'wkp-preview' : 'wkp-preview-no-palette';
  } else if (expandType === 'maximize') {
    previewView = canEditWorkflow ? 'wkp-preview-expand' : 'wkp-preview-expand-no-palette';
  } else if (expandType === 'minimize') {
    previewView = canEditWorkflow ? 'wkp-preview-minimize' : 'wkp-preview-minimize-no-palette';
  }

  const returnImprovePreview = () => {
    if (isFeatureFlagEnabled(IMPROVE_DATAFLOW_PREVIEW)) {
      const totalColumns = totalColumnCount;
      const pageRange =
        startIndex + 1 === totalColumns
          ? totalColumns
          : `${startIndex + 1}-${endIndex > totalColumnCount ? totalColumnCount : endIndex}`;
      return (
        <div className={previewView}>
          <h4 className="wkp-preview-title">
            <span>{expandType !== 'minimize' ? getPreviewTitle() : 'Preview Output'} </span>
            {(previewRecordsCount?.isFiltered || previewRecordsCount?.isSorted) && (
              <span className="title-reset-view" onClick={() => resetView()}>
                Reset View
              </span>
            )}
            {previewPaginationEnabled && totalColumnCount > PAGE_SIZE && (
              <BTPopover
                content={
                  <div className="wkp-preview-pagination-popover">
                    <BTPagination
                      currentPage={currentPage}
                      pageSize={PAGE_SIZE}
                      totalCount={totalColumns}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                }
                placement="bottom"
                triggerEvent="click"
              >
                <span className="vertical-line"></span>
                <button
                  aria-label="previewPagination"
                  className="wkp-preview-pagination-button"
                  title="previewPagination"
                  value="previewPagination"
                >
                  {`${pageRange} of ${totalColumns} columns`}{' '}
                  <span style={{ fontSize: '10px' }}>
                    <BTIcon icon="chevron-down" />
                  </span>
                </button>
              </BTPopover>
            )}

            <div className="wkp-preview-pane-toolTip">
              <button
                aria-label="minimize"
                className="wkp-preview-button"
                title="Minimize"
                value="minimize"
                onClick={e => handlePreviewSize(e)}
              >
                <img alt="minimize" src={Minimize} />
              </button>
              <button
                aria-label="restore"
                className="wkp-preview-button"
                title="Restore"
                value="restore"
                onClick={e => handlePreviewSize(e)}
              >
                <img alt="restore" src={Restore} />
              </button>
              <button
                aria-label="maximize"
                className="wkp-preview-button"
                title="Maximize"
                value="maximize"
                onClick={e => handlePreviewSize(e)}
              >
                <img alt="maximize" src={Maximize} />
              </button>
            </div>
          </h4>
          {expandType !== 'minimize' && (
            <div className="wkp-preview-ui">
              <div className="wkp-preview-output-toggle">
                <OutputToggle
                  activeElement={activeElement}
                  dataFlowActions={dataFlowActions}
                  outputToggleUtils={new OutputToggleUtils()}
                  previewOutputNode={previewOutputNode}
                ></OutputToggle>
              </div>
              <div className="wkp-preview-body">
                {isWaiting ||
                (wkpInputImportEnabled &&
                  //check element import is not running
                  workingElement?.elementData.import?.status === FILE_IMPORT_RUNNING_STATUS &&
                  importFileState &&
                  //check from importFileState if the import is not already complete
                  importFileState[workingElement.id]?.currentStep !== 'STEP_COMPLETE') ? (
                  <Spinner extraStyle={widthStyle} />
                ) : (
                  returnGrid()
                )}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="wkp-preview">
          <h4 className="wkp-preview-title">Preview (first 100 records)</h4>
          <div className="wkp-preview-body">{isWaiting ? <Spinner extraStyle={widthStyle} /> : returnGrid()}</div>
        </div>
      );
    }
  };
  const isColumnCountChanged = columnLengh.current !== totalColumnCount && totalColumnCount !== 0;
  const isRowCountChanged =
    rowsLength.current !== totalRecordsCount && rowsLength.current !== 0 && totalRecordsCount !== undefined;

  //Avoiding multiple logs when the component renders multiple times.
  if (isColumnCountChanged || isRowCountChanged) {
    CustomLogger.pushLog(CustomLogger.operations.LOAD_PREVIEW, {
      workflow_id: dataFlowId,
      number_cols: JSON.stringify(totalColumnCount),
      number_rows: JSON.stringify(totalRecordsCount),
      message: 'Preview loaded successfully',
    });
    columnLengh.current = totalColumnCount;
    rowsLength.current = totalRecordsCount;
  }

  return previewInspector ? returnImprovePreview() : null;
}

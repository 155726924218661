import qs from 'qs';
import * as fetch from '../../_shared/fetch';
import { getFormattedDate } from '../../_shared/dateFormatting';
import { dataFlowServiceHostName } from '../../../configs/params';
import CustomLogger from '../../_shared/Logger/CustomLogger';

export async function createDataFlow(dataFlowData) {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/`, {
    body: JSON.stringify(dataFlowData),
  });
  const data = await response.json();

  return data;
}

export async function getLatestValidDataFlowRun(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/runs`;
  const res = await fetch.get(reqUri);

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getDataFlowRuns({ limit } = {}) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/runs?limit=${limit ?? 50}`;
  const res = await fetch.get(reqUri);

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getRunningDataFlowInfo(id) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/runs/${id}/`;
  const res = await fetch.get(reqUri);

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function deleteDataFlows(ids) {
  const res = await fetch.remove(`${dataFlowServiceHostName}/api/v1/data-flows/`, {
    body: JSON.stringify({ ids }),
  });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function getTemplateList({ sorting, filters }) {
  return await getListHelper({ sorting, filters }, 'templates');
}

export async function getList({ sorting, filters }) {
  try {
    const startTime = performance.now();
    const response = await getListHelper({ sorting, filters }, 'list');
    const endTime = performance.now();
    const duration = endTime - startTime;

    CustomLogger.pushLog(CustomLogger.operations.GENERAL, {
      duration: `${duration} ms`,
      message: `Data flows listed successfully`,
    });

    return response;
  } catch (err) {
    CustomLogger.pushLog(CustomLogger.operations.GENERAL, {
      error: JSON.stringify(err),
      message: `Something happen while listing data flows`,
    });
    throw err;
  }
}

export async function copyDataFlow(configuration, name, taxPeriod, includeSourceFiles, copyFromDataFlowId, isTemplate) {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/`, {
    body: JSON.stringify({ configuration, name, taxPeriod, includeSourceFiles, copyFromDataFlowId, isTemplate }),
  });

  return response.json();
}

export async function getListHelper({ sorting, filters }, name) {
  const defaultSort = { binding: 'name', direction: 'ASC' };
  const defaultFilters = {};
  if (!sorting) {
    sorting = defaultSort;
  }
  if (!filters) {
    filters = defaultFilters;
  }

  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${name}?sortByColumn=${sorting.binding}&sortDirection=${
      sorting.direction
    }&${qs.stringify(filters, { skipNulls: true })}`
  );
  const data = await response.json();
  if (!response.ok) {
    throw data.error;
  }
  if (data && data.items) {
    return {
      ...data,
      items: data.items.map(dataFlow => {
        const {
          lastModifiedDate,
          lastRunTimestamp,
          lastRunUserFirstName,
          lastRunUserLastName,
          lastModifiedUserFirstName,
          lastModifiedUserLastName,
          linkedWorkpapers,
          sourceFiles,
        } = dataFlow;

        return {
          ...dataFlow,
          lastModifiedDate: lastModifiedDate ? getFormattedDate(lastModifiedDate) : null,
          lastRunTimestamp: lastRunTimestamp ? getFormattedDate(lastRunTimestamp) : null,
          publishedWithNoNewRuns: new Date(lastModifiedDate) < new Date(lastRunTimestamp),
          lastModifiedUser: lastModifiedUserFirstName
            ? `${lastModifiedUserFirstName} ${lastModifiedUserLastName}`
            : null,
          lastRunUser: lastRunUserFirstName ? `${lastRunUserFirstName} ${lastRunUserLastName}` : null,
          linkedWorkpapers: linkedWorkpapers > 0 ? linkedWorkpapers : null,
          sourceFiles: sourceFiles > 0 ? sourceFiles : null,
        };
      }),
    };
  }
}

export async function getFilterOptions() {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/filters`);
  const data = await response.json();
  if (!response.ok) {
    throw data.error;
  }
  return data;
}

export async function updateDataFlowGlobalTemplate(dataFlowId, isGlobalTemplate) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/templates/${dataFlowId}`;
  const res = await fetch.put(reqUri, {
    body: JSON.stringify({ isGlobalTemplate }),
  });

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

import { IN_OUT_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import InputElementInspector from '../../InputElementInspector';
import iconImage from '../icons/input_block_icon.svg';
import hintImage from '../icons/input_hint_img.svg';
import { INPUT } from '../types/shared/typesConstants';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { WKP_INPUT_FILE_IMPORT } from '../../../../../constants/featureFlags';

export class InputElementType extends TransformationElementType {
  static TYPE = INPUT;

  static HELP_TEXT = `The input block is the first step of a Data Connect workflow. Its purpose is to establish the initial format of the untransformed source data.<img src=${hintImage} alt="input hint" />`;

  constructor() {
    super(InputElementType.TYPE, 'Input', IN_OUT_COLOR, iconImage, InputElementType.HELP_TEXT);
  }

  get maxCount() {
    return -1;
  }

  get inPorts() {
    return null;
  }

  get inspectorComponent() {
    return InputElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    if (!elementData.pendingSourceFileVersionId) {
      delete elementData.pendingSourceFileVersionId;
    }

    const sourceFields =
      elementData.sourceFields && elementData.sourceFields.length > 0 ? elementData.sourceFields : elementData.fields;
    const fields = elementData.fields ? elementData.fields : elementData.sourceFields;

    return { ...elementData, sourceFields: sourceFields, fields: fields };
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      pendingSourceFileVersionId: elementData.pendingSourceFileVersionId,
      configFields: elementData?.configFields,
      integrationType: elementData?.integrationType,
      hasSourceFileUploaded: elementData?.hasSourceFileUploaded,
      uploadedFile: isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) ? elementData?.uploadedFile : undefined,
      fileMetadata: elementData?.fileMetadata || undefined,
      import: isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) ? elementData?.import : undefined,
      isEdit: elementData?.isEdit,
      appendDataSource: elementData?.appendDataSource,
    };
  }

  canPreview(elementData) {
    if (elementData?.integrationType === 'FA' && (elementData.fields === undefined || elementData.fields === null)) {
      // disable preview for FA
      return false;
    }

    return !!elementData.fields;
  }
}

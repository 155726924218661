import { useRef, useState, useCallback, useContext } from 'react';
import { useInterval } from '../../../global-components/Hooks/useInterval';
import { RENEW_SYNC_COMMANDS_INTERVAL_MS } from '../Spreadsheet/shared/constants';
import { getCommandsStatus, getSourcePresignedUrl, worbookJsonSync } from '../apis';
import { logoutSubscribe } from '../../../_shared/auth';
import EditorContext from '../EditorContext';
import { getJobsStatus, startJob } from '../../../_shared/jobs/apis';
import { WORBOOK_JSON_SYNC } from '../../../_shared/jobs/jobTypes';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { uploadFileToBucket } from '../../../data-flows/shared/apis';
import { recoverWorkpaper } from '../../../home/HomePage/apis';
import { getWorkpaperVersions } from '../Spreadsheet/SideBar/HistoryPanel/apis';

export default function useSyncCommands(id) {
  const { spreadRef, setShowWorkpaperSyncCommandsModal, setIsLoading } = useContext(EditorContext);
  const [delayInterval] = useState(RENEW_SYNC_COMMANDS_INTERVAL_MS);
  const [isRunningInterval, setIsRunningInterval] = useState(false);
  const renewSyncInterval = useRef();
  const logoutUnsubscribe = useRef();
  const workpaperId = useRef(id);

  const handleWorkpaperSyncCommandsModalClose = () => {};

  const handleSyncCommands = async () => {
    setShowWorkpaperSyncCommandsModal(false);
    setIsLoading(true);
    const { jobId } = await startJob({
      entityId: workpaperId.current,
      jobType: WORBOOK_JSON_SYNC,
      payload: {
        workpaperId: workpaperId.current,
      },
    });

    const startTime = Date.now();
    const ss = spreadRef.current;
    const newSJS = await generateSJS(ss, workpaperId.current, jobId);
    if (newSJS) {
      const { url, key } = await getSourcePresignedUrl(workpaperId.current);
      await uploadFileToBucket(url, JSON.stringify(newSJS));
      await worbookJsonSync(workpaperId.current, jobId, key);
    }
    await retryFetchJobStatus(jobId);
    CustomLogger.pushLog(CustomLogger.operations.JSON_SYNC, {
      workpaperId,
      duration: (Date.now() - startTime).toString(),
    });
    window.location.reload();
  };

  const syncCleanup = useCallback(async () => {
    setIsRunningInterval(false);
    logoutUnsubscribe.current?.unsubscribe();
    renewSyncInterval.current = null;
    workpaperId.current = null;
  }, []);

  const initSyncRenew = id => {
    workpaperId.current = id;
    renewSync();
    logoutUnsubscribe.current = logoutSubscribe(syncCleanup);
    setIsRunningInterval(true);
  };

  const renewSync = async () => {
    try {
      const commandsStatus = await getCommandsStatus(workpaperId.current);
      if (commandsStatus.count > 0) {
        setShowWorkpaperSyncCommandsModal(true);
        syncCleanup();
      }
    } catch (error) {
      console.error(error);
    }
  };

  renewSyncInterval.current = useInterval(renewSync, isRunningInterval ? delayInterval : null);
  const isSyncInitialized = () => renewSyncInterval.current !== null;

  const retryFetchJobStatus = async jobIds => {
    let status = 1;
    let retries = 300;

    while (status === 1 && --retries >= 0) {
      await new Promise(r => setTimeout(r, 1000));
      const res = await getJobsStatus({ jobIds: [jobIds] });
      if (res) {
        ({ status } = res[0]);
      }
      if (retries === 0) {
        throw new Error('Failed to sync workpaper, maximum retries limit reached');
      }

      if (status === 3) {
        CustomLogger.pushLog(CustomLogger.operations.JSON_SYNC, {
          workpaperId,
          error: 'sync workpaper job failed',
        });
        throw new Error('sync workpaper job failed');
      }
    }
  };

  const generateSJS = async (workbook, workpaperId, jobId) => {
    try {
      const workpaperSJS = await new Promise((resolve, reject) => {
        workbook.save(
          model => {
            resolve(model);
          },
          reject,
          { jsonStream: true }
        );
      });
      return workpaperSJS;
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.JSON_SYNC, {
        workpaperId,
        error: error,
      });
      const versions = await getWorkpaperVersions(workpaperId, false);
      if (versions.length > 0) {
        await recoverWorkpaper({ workpaperId, jobId });
      } else {
        throw new Error('error recovering the last version');
      }
      return null;
    }
  };

  return {
    initSyncRenew,
    syncCleanup,
    isSyncInitialized,
    handleWorkpaperSyncCommandsModalClose,
    handleSyncCommands,
  };
}

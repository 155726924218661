export function OutputToggle({ activeElement, dataFlowActions, previewOutputNode, outputToggleUtils }) {
  const setToggleOutput = function (option) {
    if (outputToggleUtils.hasMultipleOutputs(activeElement?.elementType?.type)) {
      dataFlowActions.setPreviewOutputNode(option);
    }
  };

  const getLiClass = function (output, options) {
    if (output === options.activeOutput) {
      return 'nav-item-active';
    } else {
      return 'nav-item';
    }
  };

  const outputOptions = outputToggleUtils.getOutputToggleData(activeElement, previewOutputNode);

  return (
    <div>
      <ul className="nav">
        {outputOptions?.outputs?.map((option, i) => (
          <li
            key={option}
            className={getLiClass(option, outputOptions)}
            title={outputToggleUtils.getTooltipText(option, outputOptions.elementType)}
            onClick={() => setToggleOutput(option)}
          >
            <i>
              <img
                alt={outputToggleUtils.getAlternativeOutputText(option)}
                src={outputToggleUtils.getIconPath(option)}
                title={outputToggleUtils.getTooltipText(outputOptions.elementType, option)}
              ></img>
            </i>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OutputToggle;

import { BTAlert } from '@btas/jasper';
import React from 'react';
import './EditDataConnection/styles.scss';

const DataConnectionError = ({ dataConnectionStatus }) => {
  if (dataConnectionStatus?.nullOutputId) {
    return (
      <BTAlert appear btStyle="danger">
        {`No Data Connect workflow output block matches the ID provided.`}
      </BTAlert>
    );
  } else if (!dataConnectionStatus?.published) {
    return (
      <BTAlert appear btStyle="warning">
        {`Publish the Data Connect workflow '${dataConnectionStatus.dataFlowName}' for tax period '${dataConnectionStatus.taxPeriod}' to see the data connection properties.`}
      </BTAlert>
    );
  }
};

export default DataConnectionError;

import { BTModal, BTButton, BTForm, BTInput } from '@btas/jasper';
import { useState, useEffect, useRef } from 'react';
import TaxPeriodInput from '../../_shared/TaxPeriodInput.jsx';
import Data_Flow_OnboardingGif from './CreateDataFlowDialog/Data_Flow_Onboarding.gif';

export default function CreateDataFlowDialog({ onCancelClick, onCreateClick, error }) {
  const inputEl = useRef(null);
  const [dataFlowData, setDataFlowData] = useState({ name: '', taxPeriod: '' });

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl]);

  return (
    <BTModal show={true} title={'New Data Connect Workflow'} onCloseClick={onCancelClick}>
      <BTModal.Body className="wkp-modal-body">
        <BTForm
          onSubmit={ev => {
            ev.preventDefault();
            onCreateClick(dataFlowData);
          }}
        >
          <BTForm.FormGroup required errorText={error.name} hasError={!!error.name} label="Name">
            <BTInput
              ref={inputEl}
              placeholder='e.g. "Trial balance" or "Stock compensation".'
              value={dataFlowData.name}
              onChange={ev => setDataFlowData({ ...dataFlowData, name: ev.target.value })}
            ></BTInput>
          </BTForm.FormGroup>
          <TaxPeriodInput
            errorText={error.taxPeriod}
            options={[]}
            value={dataFlowData.taxPeriod}
            onChange={taxPeriod => setDataFlowData({ ...dataFlowData, taxPeriod })}
          />
        </BTForm>
        <p style={{ margin: '1rem 0' }}>
          To define cleaning and computation steps for your source data, start with an <b>input</b> block, drag
          additional tools onto the canvas, then end your Data Connect workflow with an <b>output</b> block.{' '}
          <b>
            <a href="https://workpapers.bloombergtax.com/help/index.html?article=importingData" target="blank">
              Learn more
            </a>
          </b>
        </p>
        <div style={{ textAlign: 'center' }}>
          <img
            alt="Data_Flow_Onboarding"
            src={Data_Flow_OnboardingGif}
            style={{ height: '230px', width: '100%' }}
          ></img>
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onCancelClick}>Cancel</BTButton>
        <BTButton btStyle="primary" onClick={() => onCreateClick(dataFlowData)}>
          Create
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}

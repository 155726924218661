import React, { useContext, useMemo, useState, useEffect } from 'react';
import { BTAlert, BTDropdownButton, BTSpinner } from '@btas/jasper';
import EditableText from './DataFlowEditorHeaderBar/EditableText';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import useSaveDataFlow, { ConnectedOutputsChangedCode } from './useSaveDataFlow';
import CloseDataFlow from './DataFlowEditorHeaderBar/CloseDataFlow';
import SaveAsModal from './DataFlowEditorHeaderBar/SaveAsModal';
import LastRunHeaderInfo from './DataFlowEditorHeaderBar/DownloadXLSX/LastRunHeaderInfo';
import ValidationErrorsAlert from './shared/ValidationErrorsAlert';
import { useRunAfterUpdate } from './shared/useRunAfterUpdate';
import { SAVE_STATE } from './../../data-flows/DataFlowEditorPage/useDataFlowStateReducer/saveStateReducer';
import './DataFlowEditorHeaderBar/styles.scss';
import data_flow_editor_icon from './DataFlowEditorHeaderBar/data_flow_editor_icon.svg';
import data_transformation_editor_icon_template from './DataFlowEditorHeaderBar/Data_transformation_editor_icon_template.svg';

import { getLatestValidDataFlowRun, updateDataFlowGlobalTemplate } from '../DataFlowsPage/apis';
import useDataFlowRunStatus from './DataFlowEditorHeaderBar/useDataFlowRunStatus';
import { Status } from '../shared/Status';
import { uploadOutputRecords } from './DataFlowEditorHeaderBar/apis';
import { useRef } from 'react';
import { isEqual } from 'lodash';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DF_GLOBAL_TEMPLATES } from '../../../constants/featureFlags';
import { getTemplateList } from '../DataFlowsPage/apis';
import { useCanCreateWorkflow, useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { format } from '@clientio/rappid';
import { generatePNGSnapshot } from './pngUtils';

export const DataFlowEditorHeaderBar = ({ trackInteractiveClick }) => {
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [userHeaderInfo, setUserHeaderInfo] = useState({});
  const [currentRun, setCurrentRun] = useState(null);
  const [isDataFlowGlobalTemplate, setIsDataFlowGlobalTemplate] = useState(false);

  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const {
    clearActiveElement,
    commitWorkingElement,
    resetSaveMenuDirty,
    setIsHeaderInfoShown,
    setDataFlowName,
    setSaveMenuSaveAndPublish,
    setSaveMenuSave,
    setLastPublishedDate,
    updateOutputOnExport,
  } = dataFlowActions;
  const { paper } = dataFlowState;
  const runAfterUpdate = useRunAfterUpdate(dataFlowState);
  const [{ isLoading, error, isSuccess, isSavePublish, saveResponse }, actions] = useSaveDataFlow();
  const initialData = { name: dataFlowState.name, taxPeriod: dataFlowState.taxPeriod };
  const showValidationErrors = useMemo(() => saveResponse && !saveResponse.isValid, [saveResponse]);
  const displayLastRunHeaderInfo = useMemo(
    () => dataFlowState.isHeaderInfoShown && dataFlowState.isValid,
    [dataFlowState]
  );
  const [isRunning, dataFlowRun, setDataFlowRun] = useDataFlowRunStatus(dataFlowState);
  const dataFlowHeaderRunId = useMemo(() => dataFlowRun?.id, [dataFlowRun]);
  const dataFlowStateRef = useRef(null);
  const newRunRef = useRef(null);

  const onSaveClicked = publish => {
    commitWorkingElement();
    runAfterUpdate(updatedState => {
      actions.saveDataFlow(publish, updatedState);
    });
  };

  const onModalClose = () => {
    actions.reset();
    setShowSaveAsModal(false);
  };

  useEffect(() => {
    const el = document.body.querySelector('button[aria-controls="save-df-dropdown_dropdown-menu"]');

    if (el) {
      el.setAttribute('id', 'save-df-dropdown');

      function onClick() {
        setTimeout(() => {
          const dd = document.body.querySelector('button[aria-controls="save-df-dropdown_dropdown-menu"]');
          const list = dd.nextSibling?.querySelectorAll('li');

          if (list) {
            list.forEach(item => {
              item.setAttribute('id', getIdForItem(item.innerText));
            });
          }
        });
      }

      el.addEventListener('click', onClick);

      return () => {
        el.removeEventListener('click', onClick);
      };
    }
  }, []);

  useEffect(() => {
    const showLastRunData = async () => {
      if (
        dataFlowState &&
        dataFlowState.lastModifiedDate &&
        //we are sure that getLatestValidDataFlowRun is not made when user is dragging elements
        !dataFlowState.isDraggingElementFromCanvas &&
        !dataFlowState.isDraggingElementFromStencil &&
        //checks that header info should be displayed and there is not current dataFlowRun
        //or the dataFlow is published
        displayHeaderInfo() &&
        (!dataFlowRun ||
          dataFlowState.saveMenu.state === SAVE_STATE.published ||
          dataFlowState.saveMenu.state === SAVE_STATE.save)
      ) {
        setUserHeaderInfo(dataFlowState);

        const newRun = await getLatestValidDataFlowRun(dataFlowState.id);
        if (newRunRef.current && isEqual(newRunRef.current, newRun)) {
          if (dataFlowStateRef.current && isEqual(dataFlowStateRef.current, dataFlowState)) {
            // The state hasn't changed, so no need to fetch data
            return;
          }
          dataFlowStateRef.current = dataFlowState;

          // The status of the newRun hasn't changed, so no need to set the dataflowRun State again
          return;
        }
        newRunRef.current = dataFlowState;
        setCurrentRun(newRun);
        if (newRun && newRun.status === Status.Finished && !newRun.isOutputUploaded) {
          const pngData = await generatePNGSnapshot(paper, format);

          //Trigger process to upload output records to s3 if data flow run succeeded
          const uploadResult = await uploadOutputRecords(newRun.id, pngData ? pngData : null);
          if (uploadResult?.dataFlowRun?.outputDataLocations) {
            newRun.outputDataLocations = uploadResult.dataFlowRun.outputDataLocations;
          }
        }

        //Display Last Run component if dataflow run is done and not issues for upload process.
        setDataFlowRun(newRun);
      }
    };

    showLastRunData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFlowState, isLoading, setDataFlowRun, dataFlowRun?.status]);

  useEffect(() => {
    const setSaveMenuState = () => {
      let { lastModifiedDate: lastSavedDate, lastPublishedDate, isDirty } = dataFlowState;

      if (!lastPublishedDate) {
        if (currentRun?.startTimestamp) {
          lastPublishedDate = currentRun?.startTimestamp;
        } else {
          lastPublishedDate = 'not-published-yet';
        }
        setLastPublishedDate(lastPublishedDate);
      }

      if (isDirty) {
        //resetSaveMenuDirty();
      } else if (!lastSavedDate) {
        resetSaveMenuDirty();
      } else if (lastPublishedDate === 'not-published-yet') {
        setSaveMenuSave();
      } else if (
        lastSavedDate > lastPublishedDate ||
        lastSavedDate > currentRun?.startTimestamp ||
        !currentRun ||
        (currentRun && !Object.keys(currentRun).length) > 0
      ) {
        setSaveMenuSave();
      } else {
        setSaveMenuSaveAndPublish();
      }
    };

    setSaveMenuState();
  }, [
    dataFlowState,
    currentRun,
    dataFlowRun?.status,
    resetSaveMenuDirty,
    setSaveMenuSaveAndPublish,
    setSaveMenuSave,
    setLastPublishedDate,
  ]);

  useEffect(() => {
    if (dataFlowState?.isValid && !dataFlowActions.isHeaderInfoShown) {
      setLastPublishedDate(dataFlowState.lastModifiedDate);
      setIsHeaderInfoShown(true);
    }

    async function dataFlowGlobalTemplates() {
      const defaultSort = { binding: 'name', direction: 'ASC' };
      const defaultFilters = {};
      const listOfDfGlobalTemplates = await getTemplateList({ defaultSort, defaultFilters });

      if (listOfDfGlobalTemplates.items.map(template => template.id).includes(dataFlowState.id)) {
        setIsDataFlowGlobalTemplate(true);
      }
    }
    if (isFeatureFlagEnabled(DF_GLOBAL_TEMPLATES)) {
      dataFlowGlobalTemplates();
    }
    // We only need to check for this once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateDfTemplateStatus = async publish => {
    const resp = await updateDataFlowGlobalTemplate(dataFlowState.id, !isDataFlowGlobalTemplate);
    if (resp.isGlobalTemplate !== undefined) {
      isDataFlowGlobalTemplate ? setIsDataFlowGlobalTemplate(false) : setIsDataFlowGlobalTemplate(true);
      onSaveClicked(publish);
    }
  };

  const displayHeaderInfo = () => {
    //check dataflow is valid and isHeaderInfoShown is set as true
    const isValidState = displayLastRunHeaderInfo && !dataFlowState.saveMenu.exportButton;

    //for when datafalow is published at least once
    //and only save and publish button is enabled
    //and user did modifications and then saved the dataflow
    const isSavedAfterPublishing =
      dataFlowState.lastModifiedDate === dataFlowState.lastPublishedDate &&
      dataFlowState.saveMenu.state === SAVE_STATE.save;

    return !isLoading && (isValidState || isSavedAfterPublishing);
  };
  const canEditWorkflow = useCanEditWorkflow();
  const canCreateWorkflow = useCanCreateWorkflow();
  const canEditAndCreateWorkflow = canCreateWorkflow && canEditWorkflow;
  return (
    <div className="wkp-data-flow-header">
      <img
        alt="Data flow editor icon"
        src={isDataFlowGlobalTemplate ? data_transformation_editor_icon_template : data_flow_editor_icon}
      />
      <EditableText
        id="df-name-input"
        name="Data Flow Name"
        text={initialData.name}
        onSubmit={newName => {
          setDataFlowName(newName);
          dataFlowActions.resetSaveMenuDirty();
        }}
      />
      <span className="wkp-data-flow-tax-period">
        <b>Tax period</b> {initialData.taxPeriod}
      </span>
      <div className="mx-3">
        {(canEditWorkflow || canCreateWorkflow) && (
          <BTDropdownButton btStyle="primary" id="save-df-dropdown" label="SAVE">
            {(canEditAndCreateWorkflow || canEditWorkflow) && (
              <BTDropdownButton.MenuItem
                disabled={dataFlowState?.saveMenu?.saveButton}
                id="save-df"
                onClick={() => {
                  onSaveClicked(false);
                }}
              >
                Save
              </BTDropdownButton.MenuItem>
            )}
            {(canEditAndCreateWorkflow || canEditWorkflow) && (
              <BTDropdownButton.MenuItem
                disabled={dataFlowState?.saveMenu?.saveAndPubButton}
                id="save-publish-df"
                onClick={() => {
                  onSaveClicked(true);
                }}
              >
                Save & publish
              </BTDropdownButton.MenuItem>
            )}
            {(canEditAndCreateWorkflow || canCreateWorkflow) && (
              <BTDropdownButton.MenuItem
                id="save-as-df"
                onClick={() => {
                  setShowSaveAsModal(true);
                }}
              >
                Save as
              </BTDropdownButton.MenuItem>
            )}
            {isFeatureFlagEnabled(DF_GLOBAL_TEMPLATES) && (
              <BTDropdownButton.MenuItem id="toggle-template-df" onClick={() => onUpdateDfTemplateStatus(false)}>
                {isDataFlowGlobalTemplate ? 'Unmark as global template' : 'Mark as global template'}
              </BTDropdownButton.MenuItem>
            )}
          </BTDropdownButton>
        )}
      </div>
      <CloseDataFlow />
      <span className="wkp-data-flow-header__running">
        {isRunning ? (
          <>
            <BTSpinner size="1x" /> Publishing
          </>
        ) : (
          <>
            <LastRunHeaderInfo
              clearActiveElement={clearActiveElement}
              currentRun={dataFlowRun}
              dataFlowName={userHeaderInfo.name}
              dataFlowRunId={dataFlowHeaderRunId}
              displayInfo={displayHeaderInfo()}
              firstName={currentRun?.userFirstName}
              id={userHeaderInfo.id}
              lastName={currentRun?.userLastName}
              lastPublishedDate={currentRun?.startTimestamp}
              showExportOutputButton={!dataFlowState.saveMenu.exportButton}
              taxPeriod={userHeaderInfo.taxPeriod}
              trackInteractiveClick={trackInteractiveClick}
              updateOutputOnExport={updateOutputOnExport}
              userHeaderInfo={userHeaderInfo}
            />
          </>
        )}
      </span>
      <BTAlert appear dismissible fixed btStyle="danger" visible={!!error} onDismiss={actions.reset}>
        {error ? error.message : ''}
      </BTAlert>
      {(showSaveAsModal || error?.code === ConnectedOutputsChangedCode) && (
        <SaveAsModal
          dataFlow={dataFlowState}
          initialData={initialData}
          showWarning={error?.code === ConnectedOutputsChangedCode}
          onClose={onModalClose}
        />
      )}
      <BTAlert
        appear
        dismissible
        fixed
        btStyle="success"
        visible={isSuccess && !isSavePublish && !showValidationErrors}
        onDismiss={actions.reset}
      >
        Data Connect workflow was successfully updated
      </BTAlert>
      <ValidationErrorsAlert
        show={showValidationErrors}
        validationErrors={saveResponse?.validationErrors}
        onDismiss={actions.reset}
      />
    </div>
  );
};

function getIdForItem(text) {
  switch (text) {
    case 'Save':
      return 'save-df';
    case 'Save & publish':
      return 'save-publish-df';
    case 'Save as':
      return 'save-as-df';
    case 'Mark as global template':
      return 'toggle-template-df';
    case 'Unmark as global template':
      return 'toggle-template-df';
    default:
      return 'save-df';
  }
}

import { eventNames } from './constants';
export const setPropValues = (event, eventName, tagProps) => {
  const targetElement = event.target;
  const { itemType, itemClicked, itemLocation } = getCommonPropValues(targetElement);

  switch (eventName) {
    case eventNames.interactiveClick:
      return { ...tagProps, itemType, itemClicked, itemLocation };
    case eventNames.navigation:
      return { ...tagProps, navType: itemType, navItem: itemClicked, navGroup: itemLocation };
    case eventNames.toolsContent:
      return { ...tagProps, toolName: getTextFromRect(targetElement) };
    default:
      return tagProps;
  }
};

export function getCommonPropValues(targetElement) {
  const itemClicked =
    targetElement.getAttribute('id') ||
    targetElement.getAttribute('data-test-id') ||
    targetElement.getAttribute('name') ||
    targetElement.innerText ||
    targetElement.getAttribute('aria-label');
  const itemType = targetElement.tagName;
  const itemLocation = GetScreenLocation(targetElement);

  return { itemType, itemClicked, itemLocation };
}

export function GetScreenLocation(targetElement) {
  const rect = targetElement.getBoundingClientRect();
  const x = rect.left + rect.width / 2;
  const y = rect.top + rect.height / 2;

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  let horizontalPosition = '';
  let verticalPosition = '';

  // Vertical Position
  if (y < screenHeight / 6) {
    verticalPosition = 'Top';
  } else if (y < screenHeight / 3) {
    verticalPosition = 'Top-Middle';
  } else if (y < (2 * screenHeight) / 3) {
    verticalPosition = 'Middle';
  } else if (y < (5 * screenHeight) / 6) {
    verticalPosition = 'Middle-Bottom';
  } else {
    verticalPosition = 'Bottom';
  }

  // Horizontal Position
  if (x < screenWidth / 6) {
    horizontalPosition = 'Left';
  } else if (x < screenWidth / 3) {
    horizontalPosition = 'Left-Center';
  } else if (x < (2 * screenWidth) / 3) {
    horizontalPosition = 'Center';
  } else if (x < (5 * screenWidth) / 6) {
    horizontalPosition = 'Right-Center';
  } else {
    horizontalPosition = 'Right';
  }

  const itemLocation = `${verticalPosition} ${horizontalPosition}`;
  return itemLocation;
}

export const itemTypes = {
  BUTTON: 'BUTTON',
  DIV: 'DIV',
  INPUT: 'INPUT',
  SPAN: 'SPAN',
  A: 'A', // Anchor tag
  P: 'P', // Paragraph tag
  IMG: 'IMG', // Image tag
  UL: 'UL', // Unordered list tag
  LI: 'LI', // List item tag
  HREF: 'HREF', // Link tag
  COMBO: 'COMBO', //Combo box tag.
};

export function getTextFromRect(rectElement) {
  // Find the parent <g> element that wraps both <rect> and <text>
  const parentG = rectElement.closest('g.rotatable');

  // Find the <text> element within the same parent <g>
  const textElement = parentG.querySelector('text.name');

  // Extract the text content from the <text> element
  const text = textElement ? textElement.textContent : '';

  return text.trim(); // Return the trimmed text
}

export const getFakeEvent = (itemType, tag) => {
  const fakeEvent = {
    target: {
      value: 'defaultValue',
      tagName: itemTypes[itemType] || 'DIV',
      getAttribute: () => tag,
      setAttribute: (attr, value) => {},
      getBoundingClientRect: () => ({
        top: window.lastMouseY || 0,
        left: window.lastMouseX || 0,
        right: (window.lastMouseX || 0) + 100,
        bottom: (window.lastMouseY || 0) + 100,
        width: 100,
        height: 100,
      }),
    },
    clientX: window.lastMouseX || 0,
    clientY: window.lastMouseY || 0,
  };

  return fakeEvent;
};

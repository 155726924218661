import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import CreateDataFlowDialog from './CreateDataFlowDialog.jsx';
import CreateDataFlowTemplateDialog from './CreateDataFlowTemplateDialog.jsx';
import * as apis from './apis';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags.js';
import { DF_GLOBAL_TEMPLATE_MODAL } from '../../../constants/featureFlags.js';
import CustomLogger from '../../_shared/Logger/CustomLogger.js';
export default function useCreateDataFlow(isCreateDialogVisible) {
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  const [error, setError] = useState({});

  const [showCreateDialog, setShowCreateDialog] = useState(isCreateDialogVisible ? isCreateDialogVisible : false);

  const onCreateNewClick = useCallback(async function () {
    setShowCreateDialog(true);
  }, []);

  const createDataFlow = useCallback(
    async dataFlowData => {
      setError({});
      const startTime = performance.now();
      const result = await apis.createDataFlow(dataFlowData);

      if (result?.error?.details) {
        const errorMap = result.error.details.reduce((acc, detail) => {
          acc[detail.target] = detail.message;

          return acc;
        }, {});

        setError(errorMap);
        CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
          workflow_id: `${result.id}`,
          error: result.error.detail,
          from_template: 'false',
          template_name: `blank`,
          message: `Something happen while creating a new blank workflow`,
        });

        return;
      }
      const endTime = performance.now();
      const duration = endTime - startTime;

      CustomLogger.pushLog(CustomLogger.operations.CREATE.WORKFLOW, {
        workflow_id: `${result.id}`,
        duration: `${duration} ms`,
        from_template: 'false',
        template_name: `blank`,
        message: `Data connect created from a blank template`,
      });

      history.push(`/data-flows/${result.id}/editor`);
    },
    [history]
  );

  if (showCreateDialog || !isFeatureFlagEnabled(DF_GLOBAL_TEMPLATE_MODAL)) {
    return [
      showDialog ? (
        <CreateDataFlowDialog
          error={error}
          onCancelClick={() => {
            setShowDialog(false);
            setShowCreateDialog(false);
          }}
          onCreateClick={dataFlowData => createDataFlow(dataFlowData)}
        />
      ) : undefined,
      () => {
        setError({});
        setShowDialog(true);
      },
    ];
  } else {
    return [
      showDialog ? (
        <CreateDataFlowTemplateDialog
          error={error}
          onCancelClick={() => {
            setShowDialog(false);
          }}
          onCreateClick={onCreateNewClick}
        />
      ) : undefined,
      () => {
        setError({});
        setShowDialog(true);
      },
    ];
  }
}

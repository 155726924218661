import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BTAlert, BTButton, BTForm, BTInput, BTModal, BTCheckbox, BTIcon } from '@btas/jasper';
import useCopyDataFlow from './SaveAsModal/useCopyDataFlow';
import TaxPeriodInput from '../../../_shared/TaxPeriodInput';
import './SaveAsModal/styles.scss';

export const getRenamedValue = name => {
  // Check if name has appended number
  const appendedPosition = name.lastIndexOf('(');

  if (appendedPosition === 0 || appendedPosition === -1) {
    return `${name} (1)`;
  }

  const appendedValue = name.substring(appendedPosition);

  const numberFromValue = appendedValue.match(/\d+/);

  if (!numberFromValue) {
    return `${name} (1)`;
  }

  const substringName = name.substring(0, appendedPosition);

  return `${substringName}(${parseInt(appendedValue.match(/\d+/)[0]) + 1})`;
};

const SaveAsModal = ({ initialData, dataFlow, showWarning, onClose }) => {
  const [{ isLoading, error }, { copyDataFlow, reset }] = useCopyDataFlow();
  const [name, setName] = useState(`${getRenamedValue(initialData.name)}`);
  const [taxPeriod, setTaxPeriod] = useState(initialData.taxPeriod);
  const [includeSourceFiles, setIncludeSourceFiles] = useState(false);
  const [taxPeriodChanged, setTaxPeriodChanged] = useState(false);
  const onChange = useCallback(e => setName(e.target.value), [setName]);

  const onSaveClicked = useCallback(
    () => copyDataFlow(dataFlow, name, taxPeriod, includeSourceFiles),
    [name, dataFlow, copyDataFlow, taxPeriod, includeSourceFiles]
  );

  const onTaxPeriodChanged = useCallback(tp => {
    if (tp !== taxPeriod) {
      setName(initialData.name);
      setTaxPeriodChanged(true);
    } else {
      setName(`${getRenamedValue(initialData.name)}`);
      setTaxPeriodChanged(false);
    }
    setTaxPeriod(tp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BTModal show={true} size="modal-lg" title="Save as" onCloseClick={onClose}>
      <BTModal.Body className="saveAs">
        {showWarning && (
          <React.Fragment>
            <div>
              The changes you’ve made would invalidate one or more source data connections in your workpapers. To keep
              your changes, you'll need to save the data flow with a new name.
            </div>
            <br />
          </React.Fragment>
        )}
        <BTForm>
          <TaxPeriodInput
            dataFlowId={dataFlow.id}
            errorText={error?.taxPeriod}
            value={taxPeriod}
            onChange={onTaxPeriodChanged}
          />
          <BTForm.FormGroup
            required
            aria-label="nameForm"
            errorText={error?.name}
            hasError={error?.name !== undefined}
            label="Name"
          >
            <BTInput maxLength="100" value={name} onChange={onChange} />
          </BTForm.FormGroup>
          <br />
          <BTCheckbox
            checked={includeSourceFiles}
            id="includeSourceFiles"
            label="Include source file(s)"
            onChange={ev => setIncludeSourceFiles(ev.target.checked)}
          />
        </BTForm>
        {taxPeriodChanged && (
          <React.Fragment>
            <br />
            <div class="notification">
              <p>
                <BTIcon icon="info-circle"></BTIcon>If you are performing a rollforward, it is recommended to keep the
                same name as the original Data Connect workflow. Keeping the same name will enable workpaper data
                connections to rollforward to the new period.
              </p>
            </div>
          </React.Fragment>
        )}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton btStyle="primary" disabled={isLoading} hasSpinner={isLoading} onClick={onSaveClicked}>
          Save
        </BTButton>
      </BTModal.Footer>
      <BTAlert appear dismissible fixed btStyle="danger" visible={!!error} onDismiss={reset}>
        {error || ''}
      </BTAlert>
    </BTModal>
  );
};
SaveAsModal.propTypes = {
  initialData: PropTypes.object,
  dataFlow: PropTypes.object,
  onClose: PropTypes.func,
  showWarning: PropTypes.bool,
};

SaveAsModal.defaultProps = {
  showWarning: false,
};

export default SaveAsModal;

import { SOURCE_DATA_CONNECTION } from '../../../../../_shared/DataReference/ReferenceType';
import { enqueueFormula, formatFormulaReturnValue } from '../formulas';

export const sourceDataFormulaName = 'SOURCE_DATA';

const name = sourceDataFormulaName;

const parameters = [
  {
    name: 'output',
    repeatable: false,
    optional: false,
  },
  {
    name: 'output_field',
    repeatable: false,
    optional: false,
  },
  {
    name: 'filter_field1, filter_value1',
    repeatable: true,
    optional: true,
  },
];

const description = 'Retrieves source data from a Data Connect workflow.';

const evaluationFunction = (args, data) => {
  const context = args[0];
  const { row, column, source } = context;
  const sheet = source.getSheet();
  const sheetName = sheet.name();
  const cellTag = data.getCellReferenceTag(sheet, row, column);
  const formulaType = SOURCE_DATA_CONNECTION;

  enqueueFormula(data, context, formulaType, args);

  return formatFormulaReturnValue(data.renderCustomFormulaValues(cellTag, row, column, formulaType, sheetName), data);
};

export const sourceDataFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 256,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });

import React, { useState, useEffect, useContext } from 'react';
import { BTAlert, BTComboBox, BTForm } from '@btas/jasper';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { saveDataFlow } from './apis';
import { getFormattedDate } from '../../_shared/dateFormatting';
import { transformToConfiguration } from './useSaveDataFlow/transformToConfiguration';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import { useUpdateWorkflowStatus } from '../../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowHooks';
import { LockedWorkflowState } from '../../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowState';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { getUser } from '../../_shared/auth';

export default function DataFlowWorkflowPanel({ onClose }) {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const [status, setStatus] = useState({
    label: 'Not started',
    value: 'Not started',
  });
  const [showAlert, setAlert] = useState({
    show: false,
    msg: 'Success',
    status: 'success',
  });

  const { elements, links, name, id, configuration } = dataFlowState;
  const { workflowConfiguration: workflowStatus } = configuration;

  const updateLockedWorkflowStatus = useUpdateWorkflowStatus();

  useEffect(() => {
    const dfStatus = workflowStatus ? workflowStatus.status : status;
    setStatus(dfStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, workflowStatus]);

  const updateWorkflowStatus = () => {
    setAlert({ show: true, msg: 'Success', status: 'success' });
  };

  const handleComboboxChange = async selected => {
    const { userId, firstName } = getUser();
    const currentTimestamp = Date.now();
    if (selected.value !== status.value) {
      setStatus(selected);
      const newConfig = {
        ...transformToConfiguration(elements, links),
        workflowConfiguration: {
          userId: userId,
          userName: firstName,
          date: getFormattedDate(currentTimestamp),
          status: selected,
        },
      };
      const workflowState = { id: id, name: name, configuration: newConfig, status: selected.value };

      try {
        await saveDataFlow(workflowState);
        updateWorkflowStatus();
        dataFlowActions.setWorkflowStatus({
          workflowConfiguration: {
            userId: userId,
            userName: firstName,
            date: getFormattedDate(Date.now()),
            status: selected,
          },
        });

        updateLockedWorkflowStatus(selected.value, id, currentTimestamp);
        dataFlowActions.setSaveStateDirty(false);
        if (
          selected.value === LockedWorkflowState.LOCKED_WORKFLOW_STATUS ||
          status.value === LockedWorkflowState.LOCKED_WORKFLOW_STATUS
        ) {
          dataFlowActions.setSaveStateDirty(false);
          window.location.reload();
        }
      } catch (e) {
        CustomLogger.pushLog(CustomLogger.operations.LOCK_WORKFLOW, {
          error: JSON.stringify(e),
          message: 'Error updating status',
        });
      }
    }
  };

  useEffect(() => {
    if (showAlert.show) {
      setTimeout(() => {
        setAlert({ show: false });
      }, 2000);
    }
  }, [showAlert.show]);

  const { userPermissions } = useContext(UserPermissionsContext);

  return (
    <>
      <div className="wkp-df-right-panel">
        <div className="wkp-df-inspector-title">
          <div className="wkp-df-panel-header">
            <span className="wkp-df-right-panel-name">Workflow</span>
            <button className="wkp-df-right-panel-close" onClick={onClose}>
              <i aria-hidden="true" className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div className="wkp-df-panel-content">
          <div>
            <BTForm.FormGroup htmlFor="ComboBox" label="Status">
              <BTComboBox
                disabled={!userPermissions.includes('edit_workflow_status')}
                id="StatusDropDown"
                isSearchable={false}
                options={[
                  {
                    label: 'Not started',
                    value: 'Not started',
                  },
                  {
                    label: 'In preparation',
                    value: 'In preparation',
                  },
                  {
                    label: 'Ready for review',
                    value: 'Ready for review',
                  },
                  {
                    label: 'In review',
                    value: 'In review',
                  },
                  {
                    label: 'Final',
                    value: 'Final',
                  },
                ]}
                value={status}
                onChange={handleComboboxChange}
              />
            </BTForm.FormGroup>
          </div>
          {showAlert.show && <BTAlert btStyle={showAlert.status}>Saved</BTAlert>}
        </div>
      </div>
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { BTIcon } from '@btas/jasper';
import { Link, useLocation } from 'react-router-dom';
import wkpLogo from './FakeBanner/wkp_logo.svg';
import feedFormbackIcon from './FakeBanner/feedback_form_icon.svg';
import './FakeBanner/styles.scss';
import { getUser, logout } from '../auth';
import Spinner from '../Spinner';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { FEEDBACK_FORM, RESEARCH_AI } from '../../../constants/featureFlags';
import { applications } from './FakeBanner/applications';
import TaxResearchAi from './TaxResearchAi';
import { useTrackInteractiveClick } from '../EventTrackingContext';
import { itemTypes } from '../EventTrackingContext/utils';

const FakeBanner = ({ onOpenFeedbackFormClick }) => {
  const userInfo = getUser();
  const [loading, setLoading] = useState(false);

  const trackEvent = useTrackInteractiveClick();

  const DATA_TRANSFORMATION_PATH = '/data-flows';

  const pathName = useLocation().pathname;

  return loading ? (
    <Spinner />
  ) : (
    <nav className="navbar bbna-navbar-hat-slim" id="bbnaTopNav">
      <div>
        <ul className="nav navbar-nav">
          <li className="bbna-tiles-container dropdown">
            <a
              className="dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              id="app-picker"
              title="Applications"
              type="button"
            >
              <BTIcon className="bbna-icon-apps" icon="apps" />
              <span className="visible-xs-inline">Applications</span>
            </a>
            <ul aria-labelledby="app-picker" className="dropdown-menu dropdown-menu-apps" role="menu">
              <li aria-level="2" className="dropdown-header" role="heading">
                APPLICATIONS
              </li>
              {applications.map((item, index) => (
                <li key={index} className="" role="presentation">
                  <a className="item-menu-apps" href={item.link} rel="noreferrer" role="menuitem" target="_blank">
                    <span className="icon-menu-apps">{item.icon}</span>
                    <span className="name-menu-apps">{item.name}</span>
                  </a>
                </li>
              ))}
            </ul>

            <ul aria-labelledby="app-picker" className="bbna-tiles dropdown-menu" role="menu">
              <li aria-level="2" className="dropdown-header" role="heading">
                Advantage
              </li>
              <li className="bbna-tile bbna-app-home" role="presentation">
                <a href="/ws#/dashboard" role="menuitem" tabIndex="-1" title="">
                  Home
                </a>
              </li>
              <li className="bbna-tile bbna-app-ws" role="presentation">
                <a href="/ws#/workspace" role="menuitem" tabIndex="-1" title="Manage projects and documents">
                  Workspace
                </a>
              </li>
              <li className="bbna-tile bbna-app-em" role="presentation">
                <a href="/em" role="menuitem" tabIndex="-1" title="">
                  Entity Manager
                </a>
              </li>
              <li className="bbna-tile bbna-app-admin" role="presentation">
                <a href="/users" role="menuitem" tabIndex="-1" title="">
                  Administration
                </a>
              </li>
              <li className="divider" role="separator"></li>
              <li aria-level="2" className="dropdown-header" role="heading">
                Applications
              </li>
              <li className="bbna-tile bbna-app-la" role="presentation">
                <a href="/la" role="menuitem" tabIndex="-1" title="">
                  Leased Assets
                </a>
              </li>
              <li className="bbna-tile" role="presentation">
                <a href="/sp" role="menuitem" tabIndex="-1" title="">
                  Spreadsheets
                </a>
              </li>
              <li className="bbna-tile bbna-app-sta" role="presentation">
                <a href="/sta" role="menuitem" tabIndex="-1" title="">
                  State Tax Analyzer
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="navbar-header">
          <button
            className="navbar-toggle collapsed"
            data-target="#main-navbar-collapse"
            data-toggle="collapse"
            type="button"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand bbna-navbar-brand-img" to="/">
            <img alt="Bloomberg Tax Workpapers" src={wkpLogo} />
          </Link>
          <span className="navbar-button-text">{userInfo ? `${userInfo.companyNameOverride ?? ''}` : ''}</span>
        </div>
        <div className="collapse navbar-collapse" id="main-navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            {pathName === DATA_TRANSFORMATION_PATH && (
              <li>
                <a id="onboarding-btn">
                  <BTIcon icon="compass" />
                  <span className="navbar-button-text">Onboarding</span>
                </a>
              </li>
            )}

            {isFeatureFlagEnabled(FEEDBACK_FORM) && (
              <li>
                <a id="feedback-btn" onClick={onOpenFeedbackFormClick}>
                  <img alt="Feedback Form Icon" className="navbar-button-icon" src={feedFormbackIcon} />
                  <span className="navbar-button-text">Feedback</span>
                </a>
              </li>
            )}
            {isFeatureFlagEnabled(RESEARCH_AI) && <TaxResearchAi />}

            <li className="dropdown user-profile">
              <a className="dropdown-toggle" data-toggle="dropdown" href="#" id="userMenu">
                <i className="fa fa-user fa-lg fa-fw"></i>
                <span className="visible-xs-inline">User Preferences</span>
              </a>
              <ul aria-labelledby="userMenu" className="dropdown-menu" role="menu">
                <li aria-level="2" className="dropdown-header" role="heading">
                  {userInfo ? `${userInfo.firstName} ${userInfo.lastName ? userInfo.lastName : ''}` : ''}
                </li>
                <li className="" role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => {
                      setLoading(true);
                      logout();
                    }}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </li>
            <li className="dropdown help">
              <a
                className="dropdown-toggle"
                data-testid="help-dropdown"
                data-toggle="dropdown"
                href="#"
                id="header-help-button"
                title="Help"
              >
                <i className="fa fa-question-circle fa-lg fa-fw"></i>
                <span className="visible-xs-inline">Help</span>
              </a>
              <ul aria-labelledby="header-help-button" className="dropdown-menu" role="menu">
                <li className="" role="presentation">
                  <a
                    data-testid="help-button"
                    href="/help/index.html"
                    id="help-center"
                    role="menuitem"
                    target="_blank"
                    title="Help"
                    onClick={() => {
                      trackEvent('help-documentation', itemTypes.BUTTON);
                    }}
                  >
                    Help center
                  </a>
                </li>
                <li className="" data-testid="release-notes-button" role="presentation">
                  <a id="help-release-notes" role="menuitem" onClick={() => {}}>
                    Release notes
                  </a>
                </li>
                <li className="" data-testid="give-feedback-button" role="presentation">
                  <a id="help-give-feedback" role="menuitem" onClick={() => {}}>
                    Give feedback
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default FakeBanner;

import CustomLogger from '../../_shared/Logger/CustomLogger';

const exportOptions = {
  padding: 20,
  useComputedStyles: false,
  backgroundColor: '#F5F5F5',
  size: '2x',
};

export async function generatePNGSnapshot(paper, format) {
  try {
    const pngDataUri = await new Promise(resolve => {
      format.toPNG(paper, resolve, exportOptions);
    });

    if (pngDataUri.indexOf(',') === -1 || !pngDataUri || pngDataUri.indexOf(';') === -1) {
      return null;
    }

    // Convert data URI to Blob without using fetch
    const byteString = atob(pngDataUri.split(',')[1]);
    const mimeString = pngDataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const pngBlob = new Blob([ab], { type: mimeString });

    const pngFile = new File([pngBlob], 'diagram.png', { type: 'image/png' });

    if (pngFile) {
      return pngFile;
    }

    return null;
  } catch (error) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: `Error processing PNG for snapshot for audit report during download output ${JSON.stringify(error)}`,
    });
    console.error('Error processing PNG:', error);
    return null;
  }
}

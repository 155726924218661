import React, { useEffect, useState } from 'react';
import { BTDropdownButton, BTAlert } from '@btas/jasper';
import {
  downloadXLSXFile,
  exportToTPClassic,
  convertOutputsFileToXlsx,
  generatePresignedUrls,
  existedNode,
  createNodeObject,
  updateNodeObject,
} from './apis';
import {
  SEND_TO_TAX_PROVISION,
  EXPORT_TO_FIX_ASSETS,
  WKP_TP_CLASSIC_S3_ORGANIZATION,
  WKP_WORKFLOW_SUPPORT_DOWNLOAD,
} from '../../../../../constants/featureFlags';
import { getFormattedDate } from '../../../../_shared/dateFormatting';
import { sendToFA } from './sendToFA';
import { getSendToTaxMessage, getDownloadOutputMessage } from '../../../../../constants/messages';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import {
  dataFlowServiceHostName,
  taxProvisionURL,
  taxProvisionClassicURL,
  EnviromentUrl,
} from '../../../../../configs/params';
import { FAExportModal } from '../DownloadXLSX/FAExportModal';
import { SendToFixedAssetsAlert } from '../DownloadXLSX/SendToFixedAssetsAlert';
import { getUser, getUserInfo } from '../../../../_shared/auth';
import { IntegrationType, ResourceType } from './constants';
import { useCanDownloadWorkflowOutputs, useCanEditWorkflow } from '../../../../_shared/UserPermissionsContext';
import { v4 as uuidv4 } from 'uuid';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';

import { eventTrackTagNames } from '../../../../_shared/EventTrackingContext/constants';
import { itemTypes } from '../../../../_shared/EventTrackingContext/utils';

function LastRunHeaderInfo({
  clearActiveElement,
  dataFlowName,
  dataFlowRunId,
  displayInfo,
  firstName,
  id,
  lastPublishedDate,
  lastName,
  taxPeriod,
  showExportOutputButton,
  currentRun,
  updateOutputOnExport,
  trackInteractiveClick,
}) {
  const formattedDate = getFormattedDate(lastPublishedDate);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSendingToTaxProvision, setIsSendingToTaxProvision] = useState(false);
  const [isSendingToFixedAssets, setIsSendingToFixedAssets] = useState(false);
  const [sendToTaxProvisionResult, setSendToTaxProvisionResult] = useState(null);
  const [sendToFixedAssetsResult, setSendToFixedAssetsResult] = useState(null);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [faNotificationOpened, setFaNotificationOpened] = useState(false);
  const [downloadNotificationOpened, setDownloadNotificationOpened] = useState(false);
  const [showFAModal, setShowFAModal] = useState(false);

  const sendToTaxProvisionFlag = isFeatureFlagEnabled(SEND_TO_TAX_PROVISION);
  const exportToFA = isFeatureFlagEnabled(EXPORT_TO_FIX_ASSETS);
  const displayLastRunInfo = lastPublishedDate !== undefined && displayInfo && dataFlowRunId !== undefined;

  useEffect(() => {
    const el = document.body.querySelector('button[aria-controls="export-output-df_dropdown-menu"]');

    if (el) {
      el.setAttribute('id', 'export-output-df');
    }
  }, []);

  const handleClose = () => {
    setShowFAModal(false);
  };

  const handleCancel = () => {
    setShowFAModal(false);
    setIsSendingToFixedAssets(false);
  };

  const downloadXLSXFileOutput = async () => {
    setIsDownloading(true);
    setDownloadNotificationOpened(false);
    try {
      const startTime = performance.now();
      await downloadXLSXFile(id, dataFlowRunId);
      const endTime = performance.now();
      const duration = startTime - endTime;
      CustomLogger.pushLog(CustomLogger.operations.DOWNLOAD_OUTPUT, {
        workflow_id: id,
        duration: `${duration} ms`,
        message: `Download XLSX file with dataflow :${id} and dataflowrun id :${dataFlowRunId}`,
      });
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.DOWNLOAD_OUTPUT, {
        workflow_id: id,
        error: JSON.stringify(error),
        message: `catch error when trying to download output xlsx file dataflow id :${id} and dataflowrun id :${dataFlowRunId}`,
      });
      setDownloadNotificationOpened(true);
    } finally {
      setIsDownloading(false);
    }
  };

  const sendToTaxProvision = async () => {
    trackInteractiveClick('Send to Tax Provision - Data Transformation', itemTypes.LI);
    setIsSendingToTaxProvision(true);
    setSendToTaxProvisionResult(null);
    setNotificationOpened(false);
    //Get User email from token
    const user = getUser();
    const userInfo = getUserInfo();
    const email = userInfo?.designatedUser?.email || userInfo?.sharedUser?.email;
    //Indicates If user can send to TP 2.0
    const hasProvSubscription = user?.subscriptions && user.subscriptions.includes('PROV');

    //Create textFieldsByOutput to indicate all the text fields for each output file to send to xlsx-to-csv
    const textFieldsByOutput = {};
    const configuration = currentRun.configuration;

    const resourceUrl = `${EnviromentUrl}/data-flows/${id}/editor`;

    Object.values(configuration.elements)
      .filter(el => el.type === 'output')
      .forEach(element => {
        textFieldsByOutput[element.name] = element.fields.filter(field => field.type === 'text');
      });

    const csvToXlsxPayload = {
      configuration: configuration,
      dataFlowId: id,
      dataFlowRunId,
      dataFlowName,
      taxPeriod,
      companyId: user.companyId,
      textFieldsByOutput,
      lastPublishedTime: lastPublishedDate,
      workflowSupportDownloadEnabled: isFeatureFlagEnabled(WKP_WORKFLOW_SUPPORT_DOWNLOAD),
    };

    if (isFeatureFlagEnabled(WKP_TP_CLASSIC_S3_ORGANIZATION)) {
      const currentDate = new Date();
      const currentDateUTC = currentDate.toISOString();
      const metadata = {
        processId: uuidv4(),
        componentType: ResourceType.Dataflow,
        resourceId: id,
        resourceUrl,
        resourceName: dataFlowName,
        resourceTaxPeriod: taxPeriod,
        sendTime: currentDateUTC,
      };

      csvToXlsxPayload.metadata = metadata;
    }

    try {
      //Invoke csv-to-xls Lambda
      let xlsxResult = await convertOutputsFileToXlsx(csvToXlsxPayload);
      if (xlsxResult?.body && typeof xlsxResult?.body === 'string') {
        xlsxResult = JSON.parse(xlsxResult.body);
      }
      const { path } = xlsxResult?.SourceFileInfo;
      const { path: pathTP2, name: fileNameTP2 } = xlsxResult?.SourceFileInfoTP2;

      const sendToTPClassicPayload = {
        email: email,
        filePath: path,
        resourceId: id,
        ...(isFeatureFlagEnabled(WKP_TP_CLASSIC_S3_ORGANIZATION) && {
          resourceName: dataFlowName,
          componentType: ResourceType.Dataflow,
          taxPeriod: taxPeriod,
        }),
      };

      //Invoke export to TP Classic Lambda
      await exportToTPClassic(sendToTPClassicPayload);

      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
        workflow_id: id,
        payload: JSON.stringify(sendToTPClassicPayload),
        message: `send to TP classic with dataflow id :${id}`,
      });

      const presignedPayload = {
        outputId: fileNameTP2,
        location: pathTP2,
        resourceType: ResourceType.Dataflow,
        integrationType: IntegrationType.TaxProvision,
      };
      const presignedUrlResult = await generatePresignedUrls([presignedPayload]);

      const presignedUrl = presignedUrlResult.presignedUrls[0]?.[fileNameTP2];
      const fileData = {
        name: fileNameTP2,
        location: pathTP2,
        presignedUrl,
        destination: ['tax-provision.bna.com'],
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      const nodeExisted = await existedNode(id);
      const objectPayload = {
        resourceId: id,
        name: dataFlowName,
        resourceUrl,
        resourceTaxPeriod: taxPeriod,
        files: [fileData],
        resourceType: ResourceType.Dataflow,
        canSendEvent: hasProvSubscription,
        integrationType: IntegrationType.TaxProvision,
      };
      if (nodeExisted?.existed) {
        CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
          workflow_id: id,
          payload: JSON.stringify(objectPayload),
          message: `update existing node to send to TP2.0 with dataflow id :${id}`,
        });
        await updateNodeObject(objectPayload);
      } else {
        CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
          workflow_id: id,
          payload: JSON.stringify(objectPayload),
          message: `{create new node to send to TP2.0 with dataflow id :${id}`,
        });
        await createNodeObject(objectPayload);
      }
      setSendToTaxProvisionResult(
        hasProvSubscription ? { url: taxProvisionURL, version: '2' } : { url: taxProvisionClassicURL, version: '1' }
      );
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_TP, {
        workflow_id: id,
        error: JSON.stringify(error),
        message: `catch error when send to tax provision with dataflow id :${id}`,
      });
      setSendToTaxProvisionResult(null);
    } finally {
      setIsSendingToTaxProvision(false);
      setNotificationOpened(true);
    }
  };

  const handleSendToFAWithoutModal = async () => {
    try {
      const createResult = await sendToFA(currentRun, null, id, dataFlowName, taxPeriod, dataFlowServiceHostName);
      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_FA, {
        currentRun: JSON.stringify(currentRun),
        dataFlowName: JSON.stringify(dataFlowName),
        taxPeriod: JSON.stringify(taxPeriod),
        message: `send to FA with select output(s) with dataflow id :${id} and dataflowrun id :${currentRun.id}`,
      });
      setSendToFixedAssetsResult(createResult);
      setFaNotificationOpened(true);
    } catch (err) {
      CustomLogger.pushLog(CustomLogger.operations.SEND_TO_FA, {
        error: JSON.stringify(err),
        message: `catch error when send to FA with dataflow id :${id} and dataflowrun id :${currentRun.id}`,
      });
      setSendToFixedAssetsResult({ ok: false });
      setFaNotificationOpened(true);
    }
  };

  const handleSendToFAWithModal = () => {
    setShowFAModal(true);
  };

  const sendToFixedAssets = async () => {
    trackInteractiveClick('Send to Fixed Assets Link Click - Export Dropdown', itemTypes.LI);
    clearActiveElement();
    setIsSendingToFixedAssets(true);
    setSendToFixedAssetsResult(null);
    setFaNotificationOpened(false);

    const checkFAFlag = !Object.values(currentRun.outputDataLocations).some(details => details.export);

    if (checkFAFlag) {
      handleSendToFAWithModal();
    } else {
      await handleSendToFAWithoutModal();
    }
  };

  const setby = () => {
    const fName = firstName ? firstName : '';
    const lName = lastName ? lastName : '';
    if (fName || lName) {
      return <>by {`${fName} ${lName}`}</>;
    }
  };

  const exportOutputTooltip = displayLastRunInfo
    ? 'Export is already in progress. You must wait for this export to finish before exporting again.'
    : 'You must save & publish the Data Connect workflow before you can export the outputs.';

  const canDownloadWorkflowOutputs = useCanDownloadWorkflowOutputs();
  const canEditWorkflow = useCanEditWorkflow();
  const canEditAndDownloadWorkflows = canDownloadWorkflowOutputs && canEditWorkflow;
  const lastRunHeader = (
    <>
      <div className="mx-3">
        {(canDownloadWorkflowOutputs || canEditWorkflow) && (
          <BTDropdownButton
            aria-label={exportOutputTooltip}
            btStyle="secondary"
            className="wkp-data-flow-export-output"
            disabled={!showExportOutputButton || isDownloading || isSendingToTaxProvision || isSendingToFixedAssets}
            hasSpinner={isDownloading || isSendingToTaxProvision || isSendingToFixedAssets}
            id="export-output-df"
            label="Export Output"
          >
            {(canEditAndDownloadWorkflows || canDownloadWorkflowOutputs) && (
              <BTDropdownButton.MenuItem
                disabled={isDownloading}
                hasSpinner={isDownloading}
                onClick={downloadXLSXFileOutput}
              >
                Download output
              </BTDropdownButton.MenuItem>
            )}
            {exportToFA && canEditAndDownloadWorkflows && (
              <BTDropdownButton.MenuItem
                disabled={isSendingToFixedAssets}
                hasSpinner={isSendingToFixedAssets}
                onClick={sendToFixedAssets}
              >
                Send to Fixed Assets
              </BTDropdownButton.MenuItem>
            )}

            {sendToTaxProvisionFlag && canEditAndDownloadWorkflows && (
              <BTDropdownButton.MenuItem
                disabled={isSendingToTaxProvision}
                hasSpinner={isSendingToTaxProvision}
                onClick={sendToTaxProvision}
              >
                Send to Tax Provision
              </BTDropdownButton.MenuItem>
            )}
          </BTDropdownButton>
        )}
      </div>

      {displayLastRunInfo && (
        <span aria-label="lastPublished">
          Last published {formattedDate} {setby()}{' '}
        </span>
      )}

      <BTAlert
        appear
        dismissible
        fixed
        btStyle={sendToTaxProvisionResult ? 'success' : 'error'}
        data-track-tag={eventTrackTagNames.interactive}
        visible={notificationOpened}
        onDismiss={() => setNotificationOpened(false)}
      >
        {getSendToTaxMessage(sendToTaxProvisionResult)}
      </BTAlert>

      <BTAlert
        appear
        dismissible
        fixed
        btStyle="danger"
        visible={downloadNotificationOpened}
        onDismiss={() => setDownloadNotificationOpened(false)}
      >
        {getDownloadOutputMessage()}
      </BTAlert>

      {showFAModal && (
        <FAExportModal
          currentRun={currentRun}
          dataFlowName={dataFlowName}
          handleCancel={handleCancel}
          handleClose={handleClose}
          id={id}
          isShow={showFAModal}
          setFaNotificationOpened={setFaNotificationOpened}
          setSendToFixedAssetsResult={setSendToFixedAssetsResult}
          taxPeriod={taxPeriod}
          updateOutputOnExport={updateOutputOnExport}
        />
      )}

      <SendToFixedAssetsAlert
        faNotificationOpened={faNotificationOpened}
        sendToFixedAssetsResult={sendToFixedAssetsResult}
        setFaNotificationOpened={setFaNotificationOpened}
        setIsSendingToFixedAssets={setIsSendingToFixedAssets}
      />
    </>
  );
  return lastRunHeader;
}

export default LastRunHeaderInfo;

import { cloneDeep, isEqual, mapValues } from 'lodash';
import { ElementsActions } from './elementsReducer';
import { LinksActions } from './linksReducer';
import { MULTIMERGE } from '../elementType/types/shared/typesConstants';

export const SyncElementStateActions = {
  syncElementState: 'sync-element-state',
};

export const syncElementStateActions = dispatch => ({
  syncElementState: () => dispatch({ type: SyncElementStateActions.syncElementState }),
});

function elementIsMultimerge(id, elements) {
  return elements[id] && elements[id]?.type === MULTIMERGE;
}

function syncElementState(state) {
  // Build up a map of connected elements by sourceId and another one by targetId
  const linkMap = state.links.reduce(
    (acc, link) => {
      const byTargetId = acc.byTargetId[link.targetId] || {};
      const bySourceId = acc.bySourceId[link.sourceId] || [];

      if (elementIsMultimerge(link.targetId, state.elements)) {
        if (byTargetId[link.targetPort] === undefined) {
          byTargetId[link.targetPort] = {};
        }
        byTargetId[link.targetPort][link.id] = link;
      } else {
        byTargetId[link.targetPort] = link;
      }

      bySourceId.push(link);

      acc.byTargetId[link.targetId] = byTargetId;
      acc.bySourceId[link.sourceId] = bySourceId;
      return acc;
    },
    { byTargetId: {}, bySourceId: {} }
  );

  const visited = new Set();
  const newElements = {};

  // Start with all elements that have no inputs
  const elementsToVisit = Object.values(state.elements).filter(e => !linkMap.byTargetId[e.id]);
  // Iterate through each element, computing it's fields and adding any elements
  // connected to that element's output ports (which were not already visited) to elementsToVisit.
  let element;
  let anyChanged = false;

  while ((element = elementsToVisit.shift())) {
    let valuesToMap = {};
    if (element.type === MULTIMERGE) {
      valuesToMap = linkMap.byTargetId[element.id] !== undefined ? linkMap.byTargetId[element.id]['in'] : {};
    } else {
      valuesToMap = linkMap.byTargetId[element.id] || {};
    }

    const sources = mapValues(valuesToMap, link => {
      if (!newElements[link.sourceId]) return undefined;
      let { cell, ...sourceElementWithoutCell } = newElements[link.sourceId];
      const sourceElementData = sourceElementWithoutCell.elementData;
      const sourceFields = sourceElementData.fieldsByPort
        ? sourceElementData.fieldsByPort[link.sourcePort]
        : sourceElementData.fields;
      return { ...sourceElementWithoutCell, elementData: { ...sourceElementData, fields: sourceFields } };
    });
    const elementSource = cloneDeep(sources);
    const targets = (linkMap.bySourceId[element.id] || []).map(link => state.elements[link.targetId]);

    visited.add(element.id);

    if (state.workingElement?.id === element.id) {
      element = state.workingElement;
    }

    const { elementData } = element;
    const newElementData = element.elementType.applySourceElements(elementData, elementSource);
    const changed = !isEqual(newElementData, elementData);

    newElements[element.id] = changed ? { ...element, elementData: newElementData } : element;
    anyChanged = anyChanged || changed;

    elementsToVisit.push(...targets.filter(t => !visited.has(t)));
  }

  if (anyChanged) {
    let { activeElement, workingElement, workingElementIsDirty } = state;

    if (activeElement && newElements[activeElement.id] !== state.activeElement) {
      activeElement = newElements[activeElement.id];
      workingElement = activeElement;
      workingElementIsDirty = false;
    }

    return { ...state, elements: newElements, activeElement, workingElement, workingElementIsDirty };
  } else {
    return state;
  }
}

export const syncElementStateReducer = (state, action) => {
  switch (action.type) {
    case ElementsActions.addElement:
    case ElementsActions.removeElement:
    case ElementsActions.removeElements:
    case ElementsActions.setActiveElement:
    case ElementsActions.clearActiveElement:
    case ElementsActions.setElementData:
    case ElementsActions.commitWorkingElement:
    case LinksActions.addLink:
    case LinksActions.removeLink:
    case SyncElementStateActions.syncElementState:
      return action.restore ? state : syncElementState(state);

    default:
      return state;
  }
};

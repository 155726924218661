import { applyTimestamp } from '../../../../../../sjs-cmd-process';
import { DATA_LINK } from '../../../../../_shared/DataReference/ReferenceType';
import { setValueOnServer } from '../../../useWorkpaper/processCommand';

export function createRefreshDataLinksCommand({ workpaperId, enqueueCommands, cellReviewFunctionManager }) {
  const { dataReferences } = cellReviewFunctionManager?.current;
  const refreshDataLinksCommand = {
    text: 'Refresh Links',
    commandName: 'wkp.refreshDataLinks',
    visibleContext: '!CellLocked || !IsProtected',
    async execute(context, _, __) {
      const spread = context.Spread;
      const dataLinkReferences = dataReferences?.current?.filter(
        r => r.type === DATA_LINK && r.workpaperId === workpaperId
      );

      let commands = [];
      let partialWorkbooks = {};

      spread.suspendPaint();
      spread.suspendCalcService();
      for (const r of dataLinkReferences) {
        const { taxPeriod, workpaperName, sheetName, row, column, a1Coordinate } =
          typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;
        const effectiveTaxPeriod = taxPeriod ? `${taxPeriod}/` : '';
        const formula = `='${effectiveTaxPeriod}[${workpaperName}]${sheetName}'!${a1Coordinate}`;
        const sheet = spread.getSheetFromName(r.sheetName);
        sheet.setFormula(r.row, r.column, formula);
        const previousValue = sheet.getValue(r.row, r.column);

        const partialWorkbook = {
          [sheetName]: {
            [row]: {
              [column]: r.value,
            },
          },
        };

        const key = `${effectiveTaxPeriod}${workpaperName}`;
        if (!partialWorkbooks[key]) partialWorkbooks[key] = {};
        if (!partialWorkbooks[key][sheetName]) partialWorkbooks[key][sheetName] = {};
        if (!partialWorkbooks[key][sheetName][row]) partialWorkbooks[key][sheetName][row] = {};
        if (!partialWorkbooks[key][sheetName][row][column]) partialWorkbooks[key][sheetName][row][column] = r.value;

        const command = applyTimestamp(setValueOnServer(sheet, r.row, r.column, previousValue, formula));
        command.partialWorkbook = partialWorkbook;
        command.workpaperName = workpaperName;
        command.taxPeriod = effectiveTaxPeriod;
        commands.push({ commandText: JSON.stringify(command) });
      }
      enqueueCommands(commands);

      // update values
      const references = spread.getExternalReferences(true);
      for (const { filePath: effectiveTaxPeriod, name } of references) {
        const key = `${effectiveTaxPeriod}${name}`;
        spread.updateExternalReference(name, partialWorkbooks[key], effectiveTaxPeriod, true);
      }
      spread.resumeCalcService();
      spread.resumePaint();

      return true;
    },
    iconClass: 'ribbon-button-editLinks',
    workArea: 'viewport',
    bigButton: '=ribbonHeight>toolbarHeight',
  };

  return {
    'wkp.refreshDataLinks': refreshDataLinksCommand,
  };
}

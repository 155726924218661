import { isEqual } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { transformToConfiguration } from '../useSaveDataFlow/transformToConfiguration';
import * as apis from './apis';
import { isValidationError } from './errorCodes';
import usePreviewStatus from './usePreviewStatus';

/**
 * Merge errors from preview to the data flow validation errrors.
 * @param {Array} validationErrors Element preview errors.
 * @param {Array} dataFlowValidationErrors Save and Preview validation errors.
 * @returns dataFlowValidationErrors with updated errors.
 */
function mergeErrors(validationErrors, dataFlowValidationErrors) {
  if (!dataFlowValidationErrors) {
    dataFlowValidationErrors = [];
  }

  validationErrors.forEach(error => {
    const existInDataflowErrors = dataFlowValidationErrors.some(
      dtError => dtError.elementId === error.elementId && dtError.message === error.message
    );
    if (!existInDataflowErrors) {
      dataFlowValidationErrors.push(error);
    }
  });
  return dataFlowValidationErrors;
}

/**
 * Removes errors from the data flow validation errors that are fixed on preview.
 * @param {string} elementId The updated element id
 * @param {Array} dataFlowValidationErrors Save and Preview validation errors.
 * @returns  dataFlowValidationErrors with updated errors.
 */
function removeError(elementId, dataFlowValidationErrors) {
  if (!dataFlowValidationErrors) return dataFlowValidationErrors;
  return dataFlowValidationErrors.filter(error => error.elementId !== elementId);
}

export default function usePreviewRun(dataFlowState, dataFlowActions) {
  const {
    elements,
    links,
    id: dataFlowId,
    validationErrors: dataFlowValidationErrors,
    previewOutputNode,
    workflowConfiguration,
  } = dataFlowState;
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState();
  const [error, setError] = useState();
  const lastRunConfiguration = useRef(null);
  const [previewStatusState, previewStatusActions] = usePreviewStatus(dataFlowState);
  const {
    previewRun,
    previewRunId,
    isRunning,
    isLoadingData,
    previewRecords,
    totalRecordsCount,
    valueErrorList,
    showToggleInput,
    elementConfiguration,
    ...status
  } = previewStatusState;
  const { setPreviewRun, setToggleInput } = previewStatusActions;
  const runPreview = useCallback(
    async elementId => {
      const MAX_RETRIES = 2;
      const configuration = transformToConfiguration(elements, links, workflowConfiguration);
      const runConfiguration = { elementId, configuration, previewOutputNode };

      let dataFlowRun;
      let attempts = 0;

      if (isEqual(runConfiguration, lastRunConfiguration.current)) {
        // the same configuration cached disable for input block
        if (elements[elementId]?.elementType?.type !== 'input') {
          return;
        }
      }

      lastRunConfiguration.current = runConfiguration;

      setError();
      setIsLoading(true);
      setValidationErrors(null);
      do {
        try {
          dataFlowRun = await apis.previewRun(dataFlowId, configuration, elementId);
          dataFlowRun.previewId = Date.now();

          // Remove fixed errors from notification if they are fixed on preview.
          dataFlowActions.setValidationErrors(removeError(elementId, dataFlowValidationErrors));
          setPreviewRun(dataFlowRun); // State to keep updating dataflow in GET Request
        } catch (e) {
          attempts++;

          if (isValidationError(e)) {
            const previewError = e.details.map(({ target, ...e }) => ({ ...e, elementId: target }));
            // Remove fixed errors
            const updatedDataflowValidationErrors = removeError(elementId, dataFlowValidationErrors);
            // Add errors for notification if they are not on the  error list yet.
            dataFlowActions.setValidationErrors(mergeErrors(previewError, updatedDataflowValidationErrors));
            setValidationErrors(previewError);
            attempts = MAX_RETRIES;
          } else if (attempts === MAX_RETRIES) {
            //Sets error for non validationErrors
            setError(e);
          }
        }
      } while (!dataFlowRun && attempts < MAX_RETRIES);

      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataFlowId, elements, links, setPreviewRun, previewOutputNode]
  );

  const previewState = {
    previewRun,
    isWaiting: isLoading || isRunning || isLoadingData,
    error: error || status.error,
    validationErrors,
    previewRecords,
    totalRecordsCount,
    valueErrorList,
    elementConfiguration,
    showToggleInput,
    previewRunId,
  };

  const previewActions = {
    runPreview,
    setToggleInput,
  };

  return [previewState, previewActions];
}

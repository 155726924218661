import { useState, useEffect, useCallback, useRef } from 'react';
import { useJobProcessor } from '../../DataFlowJobsContext';
import { JOB_PROCESSOR_STATUS_COMPLETED, JOB_PROCESSOR_STATUS_FAILED } from '../../DataFlowJobsContext';
import { DATAFLOW_FILE_IMPORT_FILE_JOB_TYPE, DATAFLOW_FILE_METADATA_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import { getSourceFileMetadata, ImportFile } from '../FileDialog/apis';
import { saveDataFlow } from '../apis';
import {
  FILE_COMPLETED_STATUS,
  FILE_FAILED_STATUS,
  FILE_IMPORT_CANCELLED_STATUS,
  FILE_IMPORT_RUNNING_STATUS,
} from '../FileSettings/FilePropertiesStatusTypes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { removeSourceFile } from './apis';
import { removeUplodFilePermanently, validateIsPermanentDelete } from './removeSourceFile';
import { transformToConfiguration } from '../useSaveDataFlow/transformToConfiguration';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { getDefaultHeaderRows, getDefaultHeaderStart } from '../../shared/utils';

export const STEP_IDLE = 'STEP_IDLE';
export const STEP_START = 'STEP_START';
export const STEP_IMPORT_FILE_COMPLETED = 'STEP_IMPORT_FILE_COMPLETED';
export const STEP_GENERATE_FILE_METADATA_COMPLETED = 'STEP_GENERATE_FILE_METADATA_COMPLETED';
export const STEP_COMPLETE = 'STEP_COMPLETE';
export const STEP_ERROR = 'STEP_ERROR';
export const STEP_CANCEL = 'STEP_CANCEL';

export default function useImportFile(dataFlowState, dataFlowActions) {
  const { setElementData, setWKPFileImportProperties, setFAInputProperties } = dataFlowActions;
  const { id: dataFlowId, workingElement, taxPeriod: dataFlowTaxPeriod, faInputProperties } = dataFlowState;
  const {
    addJob,
    bindOnStatusChanged,
    cancelPoolJob,
    setActiveBatchId,
    startPoolingJob,
    setPoolingRequestCleanupJobs,
    bindOnPoolingJobCompleted,
    bindOnPoolingJobFailed,
  } = useJobProcessor();
  const [importFileState, setImportFileState] = useState({});
  const isMounted = useRef(true);
  const history = useHistory();
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    // Set the flag to true when the component mounts
    isMounted.current = true;

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setActiveBatchId(dataFlowId);

    if (!workingElement?.elementData) return;

    const elementState = importFileState[workingElement?.id];
    if (!elementState) return;

    if (elementState.error) {
      return;
    }

    let endTime;

    switch (elementState.currentStep) {
      /* cleanup of state in the STEP_IDLE when we cancel*/
      case STEP_IDLE:
        break;
      case STEP_START:
        setStartTime(performance.now());
        createImportSourceFileJob();
        break;
      case STEP_IMPORT_FILE_COMPLETED:
        endTime = performance.now();
        const duration = endTime - startTime;
        CustomLogger.pushLog(CustomLogger.operations.INGEST_FILE.GENERAL, {
          duration: `${duration} ms`,
          message: `File imported successfully`,
        });
        if (!elementState.sourceFileInfo || !elementState.sheetData) break;
        createSourceFileMetadataJob();
        break;
      case STEP_GENERATE_FILE_METADATA_COMPLETED:
        updateElementData().then(() => {
          // TODO to be refactored
          // Check if the component is still mounted before calling setPoolingRequestCleanupJobs
          if (isMounted.current && elementState.currentStep === STEP_GENERATE_FILE_METADATA_COMPLETED) {
            setPoolingRequestCleanupJobs(true);
          }
        });
        break;
      case STEP_COMPLETE:
        history.replace(`/data-flows/${dataFlowId}/editor`);
        break;
      case STEP_CANCEL:
        cancelImportFile().then(() => {
          // Check if the component is still mounted before calling setPoolingRequestCleanupJobs
          // TODO to be refactored
          if (isMounted.current && elementState.currentStep === STEP_CANCEL) {
            setPoolingRequestCleanupJobs(true);
            // cleanupStates();
          }
          //When upload existing file is cancelled : redirects to initial upload page
          if (importFileState[workingElement?.id]?.uploadExistingFile) {
            history.replace(`/data-flows/${dataFlowId}/editor`);
          }
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileState[workingElement?.id]?.currentStep, importFileState[workingElement?.id]?.elementState]);

  const createImportSourceFileJob = useCallback(async () => {
    let sourceFileKey = null;
    if (workingElement?.elementData?.uploadedFile) {
      const { location } = workingElement?.elementData?.uploadedFile;
      sourceFileKey = location;

      setElementData(workingElement.id, {
        ...workingElement?.elementData,
        import: {
          status: FILE_IMPORT_RUNNING_STATUS,
        },
        fileMetadata: {
          sheetData: importFileState[workingElement.id].sheetData.sheetName,
          headerRows: getDefaultHeaderRows(importFileState[workingElement.id].sheetData.numHeaders),
          headersStartAt: getDefaultHeaderStart(importFileState[workingElement.id].sheetData.dataStartAt),
        },
      });
    } else {
      sourceFileKey = importFileState[workingElement.id]?.payload?.sourceData?.path;
      setElementData(workingElement.id, {
        ...workingElement?.elementData,
        import: {
          status: FILE_IMPORT_RUNNING_STATUS,
        },
        uploadedFile: {
          name: importFileState[workingElement.id]?.payload?.sourceData?.name,
          location: sourceFileKey,
        },
      });
    }

    const onPoolingJobCompleted = job => {
      // Avoid update the status if is cancelled
      if (
        workingElement?.elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS &&
        importFileState[workingElement.id]?.currentStep === STEP_CANCEL
      ) {
        setElementData(workingElement.id, {
          ...workingElement?.elementData,
          import: {
            status: undefined,
          },
        });
        return;
      }

      const sourceFileInfo = {
        name: sourceFileName,
        path: job?.payload?.outputKey,
      };

      setImportFileState(prevState => ({
        ...prevState,
        [workingElement.id]: {
          ...prevState[workingElement.id],
          sourceFileInfo: sourceFileInfo,
          currentStep: STEP_IMPORT_FILE_COMPLETED,
        },
      }));
    };

    const onPoolingJobFailed = job => {
      setImportFileState(prevState => ({
        ...prevState,
        [workingElement.id]: {
          ...prevState[workingElement.id],
          currentStep: STEP_ERROR,
          error: job?.jobOutput?.errorTitle,
        },
      }));

      setElementData(workingElement.id, {
        ...workingElement?.elementData,
        import: {
          status: FILE_FAILED_STATUS,
        },
      });
    };

    const onImportJobCreated = jobId => {
      bindOnPoolingJobCompleted(jobId, onPoolingJobCompleted);
      bindOnPoolingJobFailed(jobId, onPoolingJobFailed);
    };
    const sourceFileName = workingElement?.elementData?.uploadedFile
      ? workingElement.elementData.uploadedFile.name
      : importFileState[workingElement.id]?.payload?.sourceData?.name;

    startPoolingJob({
      entityId: workingElement.id,
      jobType: DATAFLOW_FILE_IMPORT_FILE_JOB_TYPE,
      batchId: dataFlowId,
      payload: {
        fileName: sourceFileName,
      },
      callback: importSourceFile,
      onJobCreatedCallback: onImportJobCreated,
      params: [
        dataFlowId,
        sourceFileKey,
        sourceFileName,
        importFileState[workingElement.id]?.sheetData,
        importFileState[workingElement.id]?.processingPath,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileState, setImportFileState, workingElement?.elementData]);

  const importSourceFile = useCallback(
    async (dataflowId, sourceFileKey, fileName, sheetData, processingPath, jobId) => {
      await ImportFile(
        dataflowId,
        sourceFileKey,
        fileName,
        sheetData.sheetName || '',
        sheetData.dataStartAt,
        sheetData.numHeaders,
        jobId,
        processingPath
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importFileState, workingElement?.elementData]
  );

  const createSourceFileMetadataJob = useCallback(async () => {
    // Avoid update the status if is cancelled
    if (workingElement?.elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS) {
      setElementData(workingElement.id, {
        ...workingElement?.elementData,
        import: {
          status: undefined,
        },
      });
      return;
    }

    bindOnStatusChanged(workingElement.id, (status, error) => {
      // Avoid update the status if is cancelled
      if (workingElement?.elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS) {
        setElementData(workingElement.id, {
          ...workingElement?.elementData,
          import: {
            status: undefined,
          },
        });
        return;
      }

      if (status === JOB_PROCESSOR_STATUS_COMPLETED) {
        setImportFileState(prevState => ({
          ...prevState,
          [workingElement.id]: {
            ...prevState[workingElement.id],
            currentStep: STEP_GENERATE_FILE_METADATA_COMPLETED,
          },
        }));
        return;
      }

      if (status === JOB_PROCESSOR_STATUS_FAILED) {
        setElementData(workingElement.id, {
          ...workingElement?.elementData,
          import: {
            status: FILE_FAILED_STATUS,
          },
        });

        setImportFileState(prevState => ({
          ...prevState,
          [workingElement.id]: {
            ...prevState[workingElement.id],
            error: error.message,
          },
        }));
      }
    });

    const sourceFileName = workingElement?.elementData?.uploadedFile
      ? workingElement?.elementData?.uploadedFile.name
      : importFileState[workingElement.id]?.payload?.sourceData?.name;

    await addJob({
      processId: workingElement.id,
      type: DATAFLOW_FILE_METADATA_JOB_TYPE,
      batchId: dataFlowId,
      fileName: sourceFileName,
      jobPayload: {
        fileName: sourceFileName,
      },
      callback: generateSourceFileMetadata,
      params: [
        importFileState[workingElement.id]?.sourceFileInfo,
        workingElement.id,
        dataFlowTaxPeriod,
        workingElement?.elementData,
        importFileState[workingElement.id]?.sheetData,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileState, workingElement?.elementData]);

  const generateSourceFileMetadata = useCallback(
    async (sourceFileInfo, inputId, taxPeriod, elementData, sheetData) => {
      // Avoid update the status if is cancelled
      if (workingElement?.elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS) {
        setElementData(workingElement?.id, {
          ...workingElement?.elementData,
          import: {
            status: undefined,
          },
        });
        return;
      }

      const sourceFileName = workingElement?.elementData?.uploadedFile
        ? workingElement?.elementData?.uploadedFile.name
        : importFileState[workingElement.id]?.payload?.sourceData?.name;
      const sourceFilePath = workingElement?.elementData?.uploadedFile
        ? workingElement?.elementData?.uploadedFile.location
        : importFileState[workingElement.id]?.payload?.sourceData?.path;
      const sourceFileBucket = workingElement?.elementData?.uploadedFile?.bucket;
      const payload = importFileState[workingElement.id]?.payload;

      const inputFilePayload = {
        dataFlowId,
        inputId,
        sourceData: {
          name: sourceFileName,
          path: sourceFilePath,
          bucket: sourceFileBucket,
        },
        sheetData: {
          ...sheetData,
          sheetName: sheetData?.sheetName || '',
        },
        systemCode: importFileState[workingElement.id]?.systemCode || payload?.systemCode,
        //for WKP import and SFTP use importFileState, for FA use payload
        taxPeriod: importFileState[workingElement.id]?.taxPeriod || payload?.taxPeriod || taxPeriod,
        fileLocationId: importFileState[workingElement.id].fileLocationId || payload?.fileLocationId,
        updateSourceFile: importFileState[workingElement.id]?.updateSourceFile || false,
        sourceFileId: importFileState[workingElement.id].sourceFileId,
        additionalFields: payload?.additionalFields,
      };

      const sourceToCsvResponse = await getSourceFileMetadata(sourceFileInfo, inputFilePayload);
      setImportFileState(prevState => ({
        ...prevState,
        [workingElement.id]: {
          ...prevState[workingElement.id],
          sourceToCsv: sourceToCsvResponse,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importFileState, workingElement?.id, workingElement?.elementData]
  );

  const updateElementData = useCallback(
    async () => {
      // Avoid update the status if is cancelled
      if (workingElement?.elementData?.import?.status === FILE_IMPORT_CANCELLED_STATUS) {
        if (importFileState[workingElement?.id].sourceToCsv) {
          const deleteResp = await validateIsPermanentDelete(dataFlowId, workingElement?.id);
          const sourceToCsv = importFileState[workingElement?.id].sourceToCsv;
          if (deleteResp.permanentDeleteInfo && deleteResp.permanentDeleteInfo.length > 0) {
            await removeSourceFile(sourceToCsv.sourceFileId, dataFlowId, workingElement?.id);
            await removeUplodFilePermanently(
              deleteResp.permanentDeleteInfo,
              workingElement?.elementData?.integrationType.toLowerCase()
            );
          } else {
            await removeSourceFile(sourceToCsv.sourceFileId, dataFlowId, workingElement?.id);
          }
        }

        setElementData(workingElement?.id, {
          ...workingElement?.elementData,
          import: {
            status: undefined,
          },
        });
        return;
      }
      try {
        const { sourceFileInfo, sourceToCsv } = importFileState[workingElement?.id] ?? {};
        const { metadata, sourceFileVersionId, sourceFileId } = sourceToCsv;
        const { elementData } = workingElement;
        const additionalFields = importFileState[workingElement?.id]?.payload?.additionalFields;

        if (elementData?.integrationType === 'FA') {
          setFAInputProperties({
            ...faInputProperties,
            processingFile: false,
            showDataType: true,
            workflow: additionalFields?.worselectedWFName,
            focusPeriod: additionalFields?.focusPeriod,
            uploadFileName: elementData?.uploadFile?.name,
            pendingSourceFileVersionId: sourceFileVersionId,
            sourceFileId,
            jobRunAt: additionalFields?.jobRunAt,
            isEdit: false,
          });
          if (faInputProperties?.isEdit) {
            const cpElements = { ...dataFlowState.elements };
            cpElements[workingElement.id].elementData = {
              ...elementData,
              fields: metadata?.fields,
              sourceFields: metadata?.fields,
              pendingSourceFileVersionId: sourceFileVersionId,
              configFields: { ...elementData.configFields, jobRunAt: additionalFields?.jobRunAt || '' },
              hasSourceFileUploaded: true,
            };

            await saveDataFlow({
              id: dataFlowState.id,
              name: dataFlowState.name,
              configuration: transformToConfiguration(cpElements, dataFlowState.links),
            });

            setElementData({
              ...elementData,
              hasSourceFileUploaded: true,
              fields: metadata.fields,
              configFields: { ...elementData?.configFields, jobRunAt: additionalFields?.jobRunAt || '' },
              pendingSourceFileVersionId: sourceFileVersionId,
              isEdit: false,
            });
          }
        } else {
          setWKPFileImportProperties({
            isSavingDataDialog: false,
          });
        }

        setElementData(workingElement?.id, {
          ...elementData,
          integrationType: elementData.integrationType === 'FA' ? 'FA' : 'WKP-IMPORT',
          sourceFields: metadata?.fields,
          hasSourceFileUploaded: true,
          fields: metadata?.fields,
          configFields: { ...elementData?.configFields, jobRunAt: additionalFields?.jobRunAt || '' },
          pendingSourceFileVersionId: sourceFileVersionId,
          import: {
            status: FILE_COMPLETED_STATUS,
          },
          deletePath: sourceFileInfo?.path,
        });

        setImportFileState(prevState => ({
          ...prevState,
          [workingElement?.id]: {
            ...prevState[workingElement.id],
            currentStep: STEP_COMPLETE,
          },
        }));
      } catch (error) {
        setImportFileState(prevState => ({
          ...prevState,
          [workingElement?.id]: {
            ...prevState[workingElement.id],
            error: error.message,
          },
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importFileState, workingElement?.id, workingElement?.elementData]
  );

  const cancelImportFile = useCallback(
    async () => {
      await cancelPoolJob(workingElement?.import?.jobId);
      setElementData(workingElement?.id, {
        ...workingElement?.elementData,
        import: {
          status: FILE_IMPORT_CANCELLED_STATUS,
        },
      });

      setImportFileState(prevState => ({
        ...prevState,
        [workingElement?.id]: {
          ...prevState[workingElement.id],
          currentStep: STEP_CANCEL,
        },
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingElement?.import, workingElement?.elementData, workingElement?.id]
  );

  // TODO to be refactored
  // const cleanupStates = useCallback(
  //   () => {
  //     const elementState = importFileState[workingElement?.id];

  //     if (elementState.currentStep === STEP_CANCEL) {
  //       setElementData(workingElement.id, {
  //         ...workingElement?.elementData,
  //         fields: undefined,
  //         pendingSourceFileVersionId: undefined,
  //         import: undefined,
  //         sourceFields: undefined,
  //       });
  //       setImportFileState(prevState => ({
  //         ...prevState,
  //         [workingElement?.id]: {
  //           ...prevState[workingElement.id],
  //           currentStep: STEP_IDLE,
  //         },
  //       }));
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [importFileState, workingElement?.id, workingElement?.elementData]
  // );

  return {
    importFileState,
    setImportFileState,
    updateImportFileState: (workingElementId, importData) => {
      setImportFileState(prevState => ({
        ...prevState,
        [workingElementId]: {
          ...prevState[workingElementId],
          ...importData,
        },
      }));
    },
  };
}

import { itemTypes } from '../components/_shared/EventTrackingContext/utils';
import { fixedAssetsLoginPageUrl } from '../configs/params';

export const GENERAL_TAX_ERROR =
  'The application has encountered a problem. If you continue to receive this error message, contact  Customer Support by phone (1-800-424-2938) or email appsupport@bloombergtax.com.';
const SENT_TO_TAX_SUCCESS =
  'The data was successfully transmitted. Open Bloomberg Tax Provision to review and accept the entries.';
const BOLD_SEND_TO_TAX_SUCCESS = 'Open Bloomberg Tax Provision';
const BOLD_GENERAL_TAX_ERROR = 'appsupport@bloombergtax.com';
const LINK_GENERAL_TAX_ERROR = 'appsupport@bloombergtax.com';
const SENT_TO_FIXED_ASSETS_ERROR =
  'No output blocks are configured to be included when sending to Fixed Assets. Select at least one output to be included, then publish and send again.';
const SENT_TO_FIXED_ASSETS_SUCCESS =
  'The output file(s) have been sent to the Fixed Assets Import workflow for processing. View in Workflow Automation';
const BOLD_SEND_TO_FIXED_ASSETS_SUCCESS = 'View in Workflow Automation';

export const getSendToTaxMessage = tpResponse => {
  const message = !tpResponse ? GENERAL_TAX_ERROR : SENT_TO_TAX_SUCCESS;
  const stringBold = !tpResponse ? BOLD_GENERAL_TAX_ERROR : BOLD_SEND_TO_TAX_SUCCESS;
  const messageSplitted = message.split(stringBold);
  const linkProps = {
    ...(!tpResponse ? { href: `mailto:${LINK_GENERAL_TAX_ERROR}` } : { href: tpResponse.url, target: '_blank' }),
  };
  return (
    <>
      {messageSplitted.map((item, index) => (
        <span key={index}>
          {item}
          {index !== messageSplitted.length - 1 && (
            <b>
              <a {...linkProps}>{stringBold}</a>
            </b>
          )}
        </span>
      ))}
    </>
  );
};

export const getDownloadOutputMessage = () => {
  const message = GENERAL_TAX_ERROR;
  const stringBold = BOLD_GENERAL_TAX_ERROR;
  const messageSplitted = message.split(stringBold);
  const linkProps = {
    ...{ href: `mailto:${LINK_GENERAL_TAX_ERROR}` },
  };
  return (
    <>
      {messageSplitted.map((item, index) => (
        <span key={index}>
          {item}
          {index !== messageSplitted.length - 1 && (
            <b>
              <a {...linkProps}>{stringBold}</a>
            </b>
          )}
        </span>
      ))}
    </>
  );
};

export const getGeneralTaxErrorMessage = () => {
  const message = GENERAL_TAX_ERROR;
  const stringBold = BOLD_GENERAL_TAX_ERROR;
  const messageSplitted = message.split(stringBold);
  const linkProps = {
    ...{ href: `mailto:${LINK_GENERAL_TAX_ERROR}` },
  };
  return (
    <>
      {messageSplitted.map((item, index) => (
        <span key={index}>
          {item}
          {index !== messageSplitted.length - 1 && (
            <b>
              <a {...linkProps}>{stringBold}</a>
            </b>
          )}
        </span>
      ))}
    </>
  );
};

//update
export const getSendToFixedAssetsMessage = (response, trackEvent) => {
  let message = '';
  let stringBold;
  switch (true) {
    case !response:
      message = GENERAL_TAX_ERROR;
      stringBold = BOLD_GENERAL_TAX_ERROR;
      break;
    case !response.ok:
      message = SENT_TO_FIXED_ASSETS_ERROR;
      break;
    case response.ok:
      message = SENT_TO_FIXED_ASSETS_SUCCESS;
      stringBold = BOLD_SEND_TO_FIXED_ASSETS_SUCCESS;
      break;
    default:
      message = GENERAL_TAX_ERROR;
      stringBold = BOLD_GENERAL_TAX_ERROR;
      break;
  }

  const messageSplitted = message.split(stringBold);
  const linkProps = response?.ok
    ? {
        href: `${fixedAssetsLoginPageUrl}/fa-wa/job/${response?.eventId}`,
        onClick: () => {
          trackEvent('view-in-workflow-automation-after-send-to-fa', itemTypes.HREF);
        },
        target: '_blank',
      }
    : {
        ...{ href: `mailto:${LINK_GENERAL_TAX_ERROR}` },
      };
  return (
    <>
      {messageSplitted.map((item, index) => (
        <span key={index}>
          {item}
          {index !== messageSplitted.length - 1 && (
            <b>
              <a {...linkProps}>{stringBold}</a>
            </b>
          )}
        </span>
      ))}
    </>
  );
};

import { BTButton, BTForm, BTInput, BTModal } from '@btas/jasper';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import TaxPeriodInput from '../../../_shared/TaxPeriodInput';
import { validateSourceFile } from './apis';
import { WKP_IMPORT_INTEGRATION_TYPE } from '../InputElementInspector/constants';

const UploadFileDialog = ({ dataFlowActions, dataFlowState, onSaveFile, updateIntegrationType }) => {
  const { setWKPFileImportProperties } = dataFlowActions;
  const { wkpFileImportProperties, taxPeriod: dataFlowTaxPeriod, id: dataFlowId } = dataFlowState;
  const [validationError, setValidationError] = useState({});
  const [fileName, setFileName] = useState(
    wkpFileImportProperties?.fileName?.substring(0, wkpFileImportProperties?.fileName.lastIndexOf('.') || '')
  );
  const [fileDialogTaxPeriod, setFileDialogTaxPeriod] = useState(
    wkpFileImportProperties?.fileDialogTaxPeriod ?? dataFlowTaxPeriod
  );
  const [fileExtension, setFileExtension] = useState('.'.concat(wkpFileImportProperties?.fileName?.split('.').pop()));

  useEffect(() => {
    async function createInitialValues() {
      if (!!wkpFileImportProperties?.fileName) {
        const name =
          wkpFileImportProperties?.fileName?.substring(0, wkpFileImportProperties?.fileName.lastIndexOf('.')) || '';
        setFileName(name);
        setFileExtension('.'.concat(wkpFileImportProperties?.fileName?.split('.').pop()));
      }
    }
    createInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkpFileImportProperties?.fileName, fileDialogTaxPeriod]);

  useEffect(() => {
    if (wkpFileImportProperties?.popup) {
      setValidationError({});
      validateFileNameExisted();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDialogTaxPeriod, fileName]);

  const onClose = () => {
    setValidationError({});
    setWKPFileImportProperties({
      popup: false,
      loading: false,
      uploading: false,
    });
  };

  const onTaxPeriodComboChange = value => {
    setFileDialogTaxPeriod(value);
  };

  const nameAndTaxPeriodError = (name, taxPeriod) => {
    return `There is already a file named ‘${name}’ for tax period ‘${taxPeriod}’. You can either rename this version, select a different tax period, or press cancel to browse to the existing version.`;
  };

  const validateFileNameExisted = async () => {
    const completeFileName = fileName?.concat(fileExtension);

    if (!fileName) {
      setValidationError({ name: 'File name is required.' });
    }
    const validationResult = await validateSourceFile(completeFileName, fileDialogTaxPeriod, dataFlowId);
    if (!validationResult.isValid) {
      const nameAndTaxPeriodErrorMsg = nameAndTaxPeriodError(completeFileName, fileDialogTaxPeriod);
      setValidationError({ nameAndTaxPeriod: nameAndTaxPeriodErrorMsg });
    }
  };
  const onFileUpload = async () => {
    setWKPFileImportProperties({ ...wkpFileImportProperties, uploading: true });
    await validateFileNameExisted();
    const isCsvFile = fileExtension === 'csv';

    setWKPFileImportProperties({
      popup: false,
      loading: false,
      uploading: false,
      fileName,
      isCsvFile,
      fileDialogTaxPeriod,
    });

    const file = wkpFileImportProperties.uploadFile;
    const name = fileName.concat(fileExtension);

    updateIntegrationType(WKP_IMPORT_INTEGRATION_TYPE);
    await onSaveFile(file, name, fileDialogTaxPeriod);
  };

  return (
    <BTModal show={wkpFileImportProperties?.popup} size="modal-lg" title="Upload File" onCloseClick={onClose}>
      <BTModal.Body>
        <BTForm>
          <BTForm.FormGroup
            required
            errorText={validationError.name ?? validationError.nameAndTaxPeriod}
            hasError={!!validationError.name || !!validationError.nameAndTaxPeriod}
            label="File name"
          >
            <div className="wkp-filename-input-group">
              <BTInput
                disabled={wkpFileImportProperties?.loading}
                hasError={!!validationError.name || !!validationError.nameAndTaxPeriod}
                name="fileName"
                value={fileName}
                onChange={e => {
                  setFileName(e.target.value);
                }}
              />
              <BTInput disabled={true} name="fileExtension" size="small" value={fileExtension} />
            </div>
          </BTForm.FormGroup>

          <div className="wkp-import-tax-period">
            <TaxPeriodInput
              canCreateNew={true}
              dataFlowId={dataFlowId}
              disabled={false}
              errorText={!!validationError.nameAndTaxPeriod ? '' : []}
              hasError={!!validationError.nameAndTaxPeriod}
              isClearable={false}
              showSmallText={false}
              value={fileDialogTaxPeriod}
              onChange={onTaxPeriodComboChange}
            />
          </div>
        </BTForm>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          disabled={wkpFileImportProperties?.uploading || (validationError && Object.keys(validationError).length > 0)}
          hasSpinner={wkpFileImportProperties?.uploading}
          id="df-save-file-settings"
          onClick={onFileUpload}
        >
          UPLOAD
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default UploadFileDialog;

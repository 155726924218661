import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BTModal, BTButton, BTGrid } from '@btas/jasper';
import { wijmoKey } from '../../../configs/params';
import { getDataFlowsLinkedWorkpapers } from './DeleteDataFlowsErrorModal/apis';

export default function DeleteDataFlowsErrorModal({ selectedDataFlows, show, onClose }) {
  const ids = useMemo(() => selectedDataFlows.map(sel => sel.id), [selectedDataFlows]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState([]);

  const nameCell = ({ rowData }) =>
    rowData && (
      <BTButton btStyle="link" onClick={() => handleNameClick(rowData.workpaperId)}>
        {rowData.workpaperName}
      </BTButton>
    );
  const handleNameClick = useCallback(workpaperId => history.push(`/editor/${workpaperId}`), [history]);
  const fetchDataFlowsLinkedWorkpapers = useCallback(async () => {
    if (!show) {
      return;
    }

    const dataFlowsLinkedWorkpapers = await getDataFlowsLinkedWorkpapers(ids);

    setGridData(dataFlowsLinkedWorkpapers);
    setIsLoading(false);
  }, [show, ids]);

  useEffect(() => {
    fetchDataFlowsLinkedWorkpapers();
  }, [fetchDataFlowsLinkedWorkpapers]);

  return (
    <BTModal
      btStyle="danger"
      className="wkp-linked-workpapers-modal"
      data-testid="modal-test-tag"
      show={show}
      title="Cannot Delete Data Connect Workflows"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <p>
          One or more selected data flows have linked workpapers. You must remove the corresponding source data
          connections before you can delete the data flows.
        </p>

        <BTGrid data={gridData} gridHeight={150} isLoading={isLoading} loadingType="spinner" wijmoKey={wijmoKey}>
          <BTGrid.Header key="dataFlowName" binding="dataFlowName" minWidth={140} width="*">
            Data flow
          </BTGrid.Header>
          <BTGrid.Header key="workpaperName" binding="workpaperName" cellTemplate={nameCell} minWidth={140} width="2*">
            Workpaper
          </BTGrid.Header>
          <BTGrid.Header key="sourceDataConnection" binding="sourceDataConnection" width="*">
            Connections
          </BTGrid.Header>
        </BTGrid>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle="primary" data-testid="close-modal-button" onClick={onClose}>
          Close
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}

import GC from './SpreadSheets';
import { prepareCommand } from './components/editor/EditorPage/useWorkpaper/processCommand';
import { SJS_API } from './constants/featureFlags';
import { saveCommands, saveBatchCommand } from './components/editor/EditorPage/EditorContext/useCommandsQueue/apis';
import { isFeatureFlagEnabled } from './utils/featureFlags';
import { v4 as uuid } from 'uuid';
import { applyTimestamp, preprocessCommand, toJSON } from './sjs-cmd-process';
import { getDataFromLocalStorage } from './components/_shared/storage';

const { Sheets: spreadNS } = GC.Spread;
const { extend } = spreadNS.GC$;
GC.Spread.Sheets.CalcEngine.CalcOperatorAdjustor.SaveFormulaChanges = true;
class CommandProcessor {
  static _sjsEnabled = undefined;

  static get isSjsApiEnabled() {
    if (this._sjsEnabled === undefined) {
      this._sjsEnabled = !!isFeatureFlagEnabled(SJS_API);
    }
    return this._sjsEnabled;
  }

  static getChangesKey(sheetName) {
    return 'changes' + sheetName;
  }

  static toJSON(GC, spreadSheet, command, calcService) {
    const instructionToIgnore = ['clipboardHtml', 'clipboardImage'];

    if (!this.isSjsApiEnabled) instructionToIgnore.push('clipboardText');

    if (!command) {
      return command;
    }
    if (typeof command === 'string') {
      return command;
    }
    if (command._inToJSON) {
      return null;
    }
    let sheetTab = Array.isArray(command) ? new Array(command.length) : {};
    command._inToJSON = true;
    for (let instruction in command) {
      if (command.hasOwnProperty(instruction)) {
        let commandValue = command[instruction];
        if (!commandValue) {
          sheetTab[instruction] = commandValue;
        } else {
          let valType = commandValue.constructor;
          if (valType === GC.Spread.Sheets.Worksheet) {
            sheetTab[instruction] = commandValue.name();
          } else if (commandValue instanceof GC.Spread.CalcEngine.Expression) {
            sheetTab[instruction] = calcService.unparse(spreadSheet, commandValue, 0, 0);
          } else if (instruction === 'slicerData') {
            sheetTab['tableName'] = commandValue.getTable().name();
          } else if (commandValue instanceof GC.Spread.Sheets.Filter.HideRowFilter) {
            sheetTab[instruction] = commandValue.toJSON({});
            sheetTab[instruction].extendedRange = commandValue.extendedRange;
          } else if (commandValue.toJSON) {
            let commandValueJson = null;
            if (commandValue instanceof GC.Spread.Sheets.Tables.Table) {
              commandValueJson = commandValue.toJSON();
            } else if (
              commandValue instanceof GC.Spread.Sheets.ConditionalFormatting.Condition ||
              commandValue instanceof GC.Spread.Sheets.ConditionalFormatting.ConditionRuleBase ||
              commandValue instanceof GC.Spread.Sheets.DataValidation.DefaultDataValidator
            ) {
              commandValueJson = commandValue.toJSON(commandValue.context());
            } else {
              try {
                commandValueJson = commandValue.toJSON({});
              } catch (ex) {
                console.log(ex);
              }
            }
            if (
              commandValueJson !== null &&
              commandValueJson !== undefined &&
              valType !== Number &&
              valType !== String &&
              valType !== Date &&
              valType !== Boolean
            ) {
              sheetTab[instruction] = this.toJSON(GC, spreadSheet, commandValueJson, calcService);
            } else {
              sheetTab[instruction] = commandValueJson;
            }
          } else if (valType !== Number && valType !== String && valType !== Date && valType !== Boolean) {
            sheetTab[instruction] = this.toJSON(GC, spreadSheet, commandValue, calcService);
          } else if (typeof val !== 'function' && !instructionToIgnore.includes(instruction)) {
            sheetTab[instruction] = commandValue;
          }
        }
      }
    }

    delete command._inToJSON;
    delete sheetTab._inToJSON;
    return sheetTab;
  }

  static getChangeData(row, column, value, oldValue) {
    return [['sheetModels', 3, 'dataTable', row, column, 'value'], oldValue, value];
  }

  static getTagChangeData(row, column, value, oldValue) {
    return [['sheetModels', 3, 'dataTable', row, column, 'tag'], oldValue, value];
  }

  static setValueOnServer(sheet, row, column, oldValue, value, dirtyCellsToUpdate) {
    let command = { cmd: 'editCell', sheetName: sheet.name(), row: row, col: column, newValue: value };
    if (!this.isSjsApiEnabled) {
      let data = [];
      if (dirtyCellsToUpdate && dirtyCellsToUpdate.length > 0) {
        dirtyCellsToUpdate.forEach(dirtyCell => {
          if (dirtyCell) {
            var dataValue = this.getChangeData(dirtyCell.row, dirtyCell.col, dirtyCell.newValue, dirtyCell.oldValue);
            data.push(dataValue);
            data.push(dirtyCell);
          }
        });
      } else {
        data = this.getChangeData(row, column, value, oldValue);
      }
      command['changes' + sheet.name()] = data;
      command[`${sheet.name()}formulas`] = {
        [row]: {
          [column]: { formula: sheet.getFormula(row, column) },
        },
      };
    }
    return this.prepareCommand(GC, sheet.parent, command, 0);
  }

  static setValueAndTagOnServer(sheet, row, column, tag) {
    if (!this.isSjsApiEnabled) {
      let command = { cmd: 'Designer.setTag', sheetName: sheet.name(), row: row, col: column, tag: tag };

      command['changes' + sheet.name()] = [this.getTagChangeData(row, column, tag, null)];

      return this.prepareCommand(GC, sheet.parent, command, 0);
    } else {
      return {
        cmd: 'Designer.setTag',
        sheetName: sheet.name(),
        row: row,
        col: column,
        tag: tag,
        activeRowIndex: sheet.getActiveRowIndex(),
        activeColIndex: sheet.getActiveColumnIndex(),
        actionType: 0,
        type: 'cell',
        selections: {
          row: row,
          col: column,
          rowCount: 1,
          colCount: 1,
          typeName: 'D',
        },
        sheetId: sheet?.parent?.getSheetIndex(sheet.name()),
      };
    }
  }

  static getSheetList(spreadSheet) {
    let sheetList = [];
    for (let i = 0; i < spreadSheet.sheets.length; i++) {
      sheetList.push(spreadSheet.sheets[i].name());
    }
    return sheetList;
  }

  static toOutlineJSON(itemsInfo) {
    return (
      itemsInfo &&
      itemsInfo.map(info => {
        if (info) {
          let items = info.items;
          let itemsData = [],
            i,
            k = -1;
          for (i = 0; i < items.length; i++) {
            let itemsDataK = itemsData[k],
              itemsI = items[i];
            if (itemsI) {
              if (
                k >= 0 &&
                i === itemsDataK.count + itemsDataK.index &&
                itemsI.level === itemsDataK.info.level &&
                itemsI.collapsed === itemsDataK.info.collapsed
              ) {
                itemsData[k].count++;
              } else {
                k++;
                itemsData[k] = {
                  index: i,
                  count: 1,
                  info: { level: itemsI.level, collapsed: itemsI.collapsed },
                };
              }
            }
          }

          return {
            items: items,
            itemsData: itemsData,
            direction: info.direction,
            head: info.head,
            tail: info.tail,
          };
        } else {
          return undefined;
        }
      })
    );
  }

  static nullOrUndefined(value) {
    return value === undefined || value === null;
  }

  static registerRowColCountCmd(commandManager) {
    commandManager.register('Designer.sheetSettings', {
      canUndo: false,
      execute: function (spread, cmdArgs, isUndo) {
        //Suspend layout
        var sheet = spread.getActiveSheet();
        if (!sheet) {
          return;
        }
        sheet.suspendPaint();
        sheet.suspendCalcService();
        var optionResult = cmdArgs.options;
        try {
          sheet.setColumnCount(optionResult.colCount);
          sheet.setRowCount(optionResult.rowCount);
          sheet.frozenRowCount(optionResult.frozenRowCount);
          sheet.frozenColumnCount(optionResult.frozenColumnCount);
          sheet.frozenTrailingRowCount(optionResult.frozenTrailingRowCount, optionResult.frozenTrailingRowStickToEdge);
          sheet.frozenTrailingColumnCount(
            optionResult.frozenTrailingColumnCount,
            optionResult.frozenTrailingColumnStickToEdge
          );
          sheet.selectionPolicy(optionResult.selectionPolicy);
          sheet.options.isProtected = optionResult.isProtected;
          if (!sheet.options.isProtected) {
            sheet.options.protectionOptions = {};
          }
          sheet.options.allowCellOverflow = optionResult.allowCellOverflow;
          if (!this.nullOrUndefined(optionResult.gridlineColor)) {
            sheet.options.gridline = {
              showHorizontalGridline: optionResult.showHorizontalGridline,
              showVerticalGridline: optionResult.showVerticalGridline,
              color: optionResult.gridlineColor,
            };
          }
          sheet.setRowCount(optionResult.colHeaderRowCount, 1);
          sheet.options.colHeaderAutoText = optionResult.colHeaderAutoText;
          sheet.options.colHeaderAutoTextIndex = optionResult.colHeaderAutoTextIndex;
          sheet.defaults.colHeaderRowHeight = Math.max(optionResult.colHeaderDefRowHeight || 0, 0 /* minHeight */);
          sheet.options.colHeaderVisible = optionResult.colHeaderVisible;
          sheet.setColumnCount(optionResult.rowHeaderColCount, 2 /* rowHeader */);
          sheet.options.rowHeaderAutoText = optionResult.rowHeaderAutoText;
          sheet.options.rowHeaderAutoTextIndex = optionResult.rowHeaderAutoTextIndex;
          sheet.defaults.rowHeaderColWidth = Math.max(optionResult.rowHeaderDefColWidth || 0, 0 /* minWidth */);
          sheet.options.rowHeaderVisible = optionResult.rowHeaderVisible;
          if (!this.nullOrUndefined(optionResult.sheetTabColor)) {
            sheet.options.sheetTabColor = optionResult.sheetTabColor;
          }
        } finally {
          sheet.resumeCalcService(false);
          sheet.resumePaint();
        }
      },
    });
  }

  static getSheetSetting(spread, sheetName) {
    var option = {};
    var sheet = spread.getSheetFromName(sheetName);
    if (!sheet) {
      return option;
    }
    option.colCount = sheet.getColumnCount();
    option.rowCount = sheet.getRowCount();
    option.frozenColumnCount = sheet.frozenColumnCount();
    option.frozenRowCount = sheet.frozenRowCount();
    option.frozenTrailingColumnCount = sheet.frozenTrailingColumnCount();
    option.frozenTrailingRowCount = sheet.frozenTrailingRowCount();
    option.frozenTrailingColumnStickToEdge = sheet.getFrozenTrailingState();
    option.frozenTrailingRowStickToEdge = sheet.getFrozenTrailingState(true /* isRow */);
    option.selectionPolicy = sheet.selectionPolicy();
    option.isProtected = sheet.options.isProtected;
    option.allowCellOverflow = sheet.options.allowCellOverflow;
    option.showHorizontalGridline = sheet.options.gridline.showHorizontalGridline || false;
    option.showVerticalGridline = sheet.options.gridline.showVerticalGridline || false;
    option.gridlineColor = sheet.options.gridline.color || '';
    option.colHeaderRowCount = sheet.getRowCount(GC.Spread.Sheets.SheetArea.colHeader);
    option.colHeaderAutoText = sheet.options.colHeaderAutoText;
    option.colHeaderAutoTextIndex = sheet.options.colHeaderAutoTextIndex;
    option.colHeaderDefRowHeight =
      sheet.defaults.colHeaderRowHeight !== undefined ? sheet.defaults.colHeaderRowHeight : 20;
    option.colHeaderVisible = sheet.options.colHeaderVisible;
    option.rowHeaderColCount = sheet.getColumnCount(GC.Spread.Sheets.SheetArea.rowHeader);
    option.rowHeaderAutoText = sheet.options.rowHeaderAutoText;
    option.rowHeaderAutoTextIndex = sheet.options.rowHeaderAutoTextIndex;
    option.rowHeaderDefColWidth =
      sheet.defaults.rowHeaderColWidth !== undefined ? sheet.defaults.rowHeaderColWidth : 40;
    option.rowHeaderVisible = sheet.options.rowHeaderVisible;
    option.sheetTabColor = sheet.options.sheetTabColor;
    return option;
  }

  static async setCountCommand(ss, rowCount, colCount, workbookId, callback, lastCommandAppliedTime) {
    const commandManager = ss.commandManager();
    this.registerRowColCountCmd(commandManager);
    const activeSheet = ss.getActiveSheet();
    const options = this.getSheetSetting(ss, activeSheet.name());
    const sheetName = activeSheet.name();

    const currentRowCount = activeSheet.getRowCount();
    const currentColCount = activeSheet.getColumnCount();

    options.rowCount = currentRowCount + rowCount;
    options.colCount = currentColCount + colCount;

    let command = {
      sheetName,
      cmd: 'Designer.sheetSettings',
      options: options,
    };
    commandManager.execute(command);
    command.actionType = 0;
    if (this.isSjsApiEnabled) {
      command = preprocessCommand(ss, command);
      command = applyTimestamp(command);
      command = toJSON(GC, ss, command);
      command.options = options;
      await saveCommands(
        workbookId,
        sheetName,
        [
          {
            commandId: uuid(),
            commandText: JSON.stringify(command),
            creationDate: new Date().toISOString(),
            sessionId: getDataFromLocalStorage(`sessionId-${workbookId}`),
          },
        ],
        lastCommandAppliedTime
      );
      await callback();
    } else {
      command = prepareCommand(GC, ss, command, 0);
      const res = await saveBatchCommand(workbookId, sheetName, [{ commandText: JSON.stringify(command) }]);
      if (res?.ok || res?.status === 504) {
        await callback();
      }
    }

    return { rowCount: options.rowCount, colCount: options.colCount };
  }

  static getSlicerCache(slicer) {
    let slicerJson = slicer.toJSON();
    let slicerCache = null;
    for (let p in slicer) {
      if (slicer[p].name === slicerJson.slicerCacheName) {
        slicerCache = slicer[p];
        break;
      }
    }
    return slicerCache;
  }

  static removeShapeChangesForSlicers(shapeChanges) {
    if (shapeChanges && shapeChanges.length) {
      let i = 0;
      while (i < shapeChanges.length) {
        let shapeChange = shapeChanges[i];
        let shape = shapeChange.shape || shapeChange.value.shape;
        if (shape && shape.drawingType === 'Slicer') {
          shapeChanges.splice(i, 1);
        } else {
          i++;
        }
      }
    }
  }

  static processSlicerChanges(sheetTab, sheetName) {
    sheetName = sheetTab.sheetName ? sheetTab.sheetName : sheetName;
    const changesKey = this.getChangesKey(sheetName);
    sheetTab[changesKey] = Object.assign({}, sheetTab[changesKey]);
    delete sheetTab[changesKey].slicerChanges;
    delete sheetTab[changesKey].shapeChanges;
  }

  static checkArrayInfo(spreadSheet, command) {
    const sheetNames = command.sheetNames;
    if (sheetNames && sheetNames.length > 0) {
      for (let i = 0; i < sheetNames.length; i++) {
        const sheetName = sheetNames[i];
        const changesKey = this.getChangesKey(sheetName);
        const changes = command[changesKey];
        const formulas = changes && changes.formulas;
        if (formulas) {
          const sourceModel = spreadSheet
            .getCalcService()
            .getAllSourceModels()
            .find(m => {
              return m.getSource().getName() === sheetName;
            });
          if (sourceModel) {
            for (let r in formulas) {
              for (let c in formulas[r]) {
                const cell = formulas[r][c];
                if (cell.formula && !cell.arrayInfo) {
                  const arrayInfo = sourceModel.getWorkingNodeInfo(parseInt(r), parseInt(c), 2);
                  if (arrayInfo) {
                    cell.arrayInfo = arrayInfo;
                  }
                }
              }
            }
          }
        }
      }
    }
    return command;
  }

  static prepareCommand(GC, spreadSheet, command, actionType) {
    let sheetName = command.sheetName;
    const calcService = spreadSheet.getCalcService();

    let sheetTab = this.prepareCommandImp(GC, spreadSheet, command, sheetName, actionType);
    if (sheetTab.cachedActions) {
      sheetTab.cachedActions = sheetTab.cachedActions.map(action => {
        if (action.toJSON) {
          var cmdData = action.toJSON();
          return {
            sheetName: cmdData.sheetName,
            command: this.checkArrayInfo(
              spreadSheet,
              this.prepareCommandImp(GC, spreadSheet, cmdData.command, sheetName, actionType)
            ),
          };
        }
        return {};
      });
    }
    const result = this.toJSON(GC, spreadSheet, sheetTab, calcService);
    return result;
  }

  static prepareCommandImp(GC, spreadSheet, command, sheetName, actionType) {
    let sheetTab = extend({}, command);
    let sheetNames = sheetTab.sheetNames || [command.sheetName ? command.sheetName : sheetName];
    if (command.cmd && (command.cmd.indexOf('deleteSheet') >= 0 || command.cmd.indexOf('renameSheet') >= 0)) {
      sheetNames = this.getSheetList(spreadSheet);
      sheetTab.sheetList = sheetNames;
    }
    if (sheetTab.cmd === 'Designer.insertSheet') {
      sheetTab.cmd = 'insertSheet';
      sheetTab.data = spreadSheet.getActiveSheet().toJSON();
      sheetTab.sheetIndex = spreadSheet.getActiveSheetIndex();
    }
    if (sheetNames && sheetNames.length > 0) {
      let changedSheets = {};

      for (let i = 0; i < sheetNames.length; i++) {
        sheetName = sheetNames[i];
        const sheet = spreadSheet.getSheetFromName(sheetName);
        const changesKey = this.getChangesKey(sheetName);
        const changes = sheetTab[changesKey] || sheetTab['_changes'];

        // properties attached to array object will lost.
        // we need to attach those properties to the top object.
        // when other clients get the message, need to put the
        // plugins changes back to sheet's changes.
        if (changes) {
          for (let change in changes) {
            if (typeof change === 'string' && isNaN(change) && change !== 'calc' && change !== 'events') {
              changedSheets[sheetName] = 1;
              if (Array.isArray(changes[change])) {
                if (change === 'chartChanges') {
                  if (changes[change] && changes[change].length > 0) {
                    if (actionType === 1) {
                      sheetTab[sheetName + change] = [changes[change][changes.length - 1]];
                    } else {
                      sheetTab[sheetName + change] = [changes[change][0]];
                    }
                  }
                } else {
                  sheetTab[sheetName + change] = extend([], changes[change]);
                }
              } else {
                sheetTab[sheetName + change] = extend({}, changes[change]);
              }
            }
          }

          if (changes.length > 0) {
            changedSheets[sheetName] = 1;
          }

          if (!changedSheets[sheetName]) {
            delete sheetTab[changesKey];
          }
        }

        let pivotTableChanges = sheetTab[sheetName + 'pivotTableChanges'];
        if (pivotTableChanges) {
          sheetTab[sheetName + 'pivotTableChanges'] = pivotTableChanges.map(tab => {
            delete tab.value.pivotTable;
            if (tab.pivotObj instanceof GC.Spread.Pivot.PivotFieldsModel) {
              let data = {};
              let pname = tab.pivotObj.getName();
              let pivotTable = sheet.pivotTables.get(pname);
              tab.pivotObj.toJson(pivotTable, data);
              data.name = pname;
              data.ref = GC.Spread.Sheets.CalcEngine.rangeToFormula(pivotTable.getRange().content, 0, 0, 15);
              return { pivotObj: data, value: tab.value };
            } else {
              let pivotTableData = null;
              if (tab.pivotObj) {
                pivotTableData = tab.pivotObj.toJson();
              }

              return { pivotObj: pivotTableData, value: tab.value };
            }
          });

          let caches = spreadSheet.getPivotCaches();
          if (caches) {
            sheetTab.pivotCaches = caches.pivotCaches;
          }
        }

        let outlineChanges = sheetTab[sheetName + 'originalOutlines'];
        if (outlineChanges) {
          sheetTab[sheetName + 'originalOutlines'] = this.toOutlineJSON(outlineChanges);
        }
      }

      switch (sheetTab.cmd) {
        case 'Designer.moveChart':
          let targetSheetName = sheetTab.targetSheetName;
          if (sheetTab.type === 'newSheet') {
            let sheet = spreadSheet.getSheetFromName(targetSheetName);
            sheetTab.data = sheet.toJSON();
            sheetTab.data.index = spreadSheet.getSheetIndex(targetSheetName);
            sheetTab.sheetList = this.getSheetList(spreadSheet);
          }
          if (targetSheetName && targetSheetName.length > 0) {
            changedSheets[targetSheetName] = 1;
          }
          break;
        case 'insertSheet':
        case 'deleteSheet':
        case 'renameSheet':
        case 'moveSheet':
          sheetTab.sheetList = this.getSheetList(spreadSheet);
          break;
        case 'Designer.createDefaultPivotTable' && sheetTab.place === 0:
          let sheet = spreadSheet.getActiveSheet();
          sheetTab.data = sheet.toJSON();
          sheetTab.data.index = spreadSheet.getSheetIndex(sheet.name());
          sheetTab.sheetList = this.getSheetList(spreadSheet);
          break;
        case 'gc.spread.contextMenu.unhideSheet':
          let names = [];
          let unhideSheetIndexes = sheetTab.unhideSheetIndexes;
          unhideSheetIndexes.forEach(n => {
            let hiddenSheet = spreadSheet.sheets[n].name();
            changedSheets[hiddenSheet] = 1;
            names.push(hiddenSheet);
          });
          sheetTab.names = names;
          break;
        case 'submitShapeText':
          sheetName = command.sheetName ? command.sheetName : sheetName;
          sheet = spreadSheet.getSheetFromName(sheetName);
          let shapeDatas = {};
          command.shapes.forEach(t => {
            let sheetShapes = sheet.shapes.all().find(s => s.name() === t);
            if (sheetShapes) {
              shapeDatas[t] = sheetShapes.toJSON().shapeData.sheetShapes;
            }
          });
          sheetTab.shapeDatas = shapeDatas;
          break;
        case 'moveShapes':
          // check if slicer
          if (sheetTab.changes && sheetTab.changes.length > 0) {
            sheetName = command.sheetName ? command.sheetName : sheetName;
            let slicerChanges = (sheetTab[sheetName + 'slicerChanges'] = []);

            for (let i = 0; i < sheetTab.changes.length; i++) {
              let spName = sheetTab.changes[i].name;
              let slicer = spreadSheet.getActiveSheet().slicers.get(spName);
              if (slicer) {
                let slicerJson = slicer.toJSON();
                let item = {
                  slicerItem: { name: spName },
                };
                if (actionType === 1) {
                  item.value = slicerJson;
                } else {
                  item.newValue = slicerJson;
                }
                slicerChanges.push(item);
              }
            }

            let shapeChanges = sheetTab[sheetName + 'shapeChanges'];
            this.removeShapeChangesForSlicers(shapeChanges);
          }
          break;
        case 'Designer.insertSlicer':
          {
            this.processSlicerChanges(sheetTab, sheetName);

            let slicerChanges = sheetTab[sheetName + 'slicerChanges'];
            let slicer = slicerChanges[0].slicerItem;
            if (slicer) {
              if (actionType !== 1) {
                let slicerCache = this.getSlicerCache(slicer);
                if (slicerCache) {
                  sheetTab['slicerCache'] = slicerCache;
                }
              } else {
                for (let i = 0; i < slicerChanges.length; i++) {
                  delete slicerChanges[i].slicerItem;
                  delete slicerChanges[i].newValue;
                }
              }

              let shapeChanges = sheetTab[sheetName + 'shapeChanges'];
              this.removeShapeChangesForSlicers(shapeChanges);
            }
          }

          break;
        case 'deleteSlicers':
        case 'deleteShapes':
          {
            let slicerChanges = sheetTab[sheetName + 'slicerChanges'];
            if (slicerChanges) {
              sheetTab.deleteSlicers = true;
              this.processSlicerChanges(sheetTab, sheetName);

              for (let i = 0; i < slicerChanges.length; i++) {
                slicerChanges[i] = Object.assign({}, slicerChanges[i]);
                delete slicerChanges[i].slicerItem;
              }

              let shapeChanges = sheetTab[sheetName + 'shapeChanges'];
              this.removeShapeChangesForSlicers(shapeChanges);
            }
          }
          break;
        default:
          break;
      }

      if (sheetTab.cmd === 'dragCopyShapes' && !sheetTab.copiedShapes) {
        sheetName = command.sheetName ? command.sheetName : sheetName;
        let sheet = spreadSheet.getSheetFromName(sheetName);
        let changes = sheetTab.changes;
        sheetTab.copiedShapes = [];
        for (let i = 0; i < changes.length; i++) {
          let change = changes[i];
          let sp = sheet.shapes.get(change.name);
          sheetTab.copiedShapes.push(sp);
        }
      }

      sheetTab.sheetNames = Object.keys(changedSheets);
    }

    return sheetTab;
  }

  static addCustomName(spreadsheet, name, value) {
    const activeSheet = spreadsheet.getActiveSheet();
    const sheetName = activeSheet.name();
    const cname = spreadsheet.getCustomName(name);
    const commandManager = spreadsheet.commandManager();
    let newValue = value;
    if (value === undefined) {
      newValue = '';
    }

    commandManager.execute({
      actionType: 0,
      cmd: 'Designer.addCustomName',
      name,
      sheetIndex: -1,
      referTo: `="${newValue}"`,
      comment: '',
      activeRowIndex: 0,
      activeColIndex: 0,
      selections: activeSheet.getSelections(),
      sheetId: activeSheet._id,
      sheetName,
      oldName: cname ? 'tax_period' : null,
    });
  }

  static getValueCustomName(GC, spreadsheet, name) {
    const activeSheet = spreadsheet.getActiveSheet();
    const cname = spreadsheet.getCustomName(name);
    if (cname) {
      const expression = cname.getExpression();
      const expStr = GC.Spread.Sheets.CalcEngine.expressionToFormula(activeSheet, expression, 0, 0);
      return GC.Spread.Sheets.CalcEngine.evaluateFormula(activeSheet, expStr, 0, 0);
    }
    return undefined;
  }
}

export default CommandProcessor;

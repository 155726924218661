import { SAVE_AS, RENAME } from '../../../../_shared/FileMenuModal/actions';

export function registerWorkpapersCustomFileMenu(GC, handleFileMenuAction) {
  fileMenuGrapeCityCustomization(GC);
  addWorkpaperActions(GC, handleFileMenuAction);
}

// add any item to this array for file actions
const workpaperFileMenuItems = [
  {
    text: 'Save as',
    value: 'SaveAs',
    iconClass: 'save-as',
    watermark: 'Save a copy',
    action: SAVE_AS,
  },
  {
    text: 'Rename',
    value: 'Rename',
    iconClass: 'rename',
    watermark: 'Rename this workpaper',
    action: RENAME,
  },
];

function getWorkpapersFileMenuItems(showMenuAction) {
  return workpaperFileMenuItems.map(i => ({
    ...i,
    execute: () => {
      showMenuAction(i.action);
    },
  }));
}

function addWorkpaperActions(GC, handleFileMenuAction) {
  var fileMenuTemplate = GC.Spread.Sheets.Designer.getTemplate(
    GC.Spread.Sheets.Designer.TemplateNames.FileMenuPanelTemplate
  );

  // removing default actions ('Import', 'Export' & 'Print')
  delete fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][5];
  delete fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][7];

  fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][1].type = 'WorkpaperFileMenu';
  fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][1].items =
    getWorkpapersFileMenuItems(handleFileMenuAction);
  fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][2] = undefined; // separate line
  fileMenuTemplate['content'][0]['children'][0]['children'][0]['children'][0]['children'][3] = undefined; // Print action
  fileMenuTemplate['content'][0]['children'][0]['children'][1] = [];
  GC.Spread.Sheets.Designer.registerTemplate(
    GC.Spread.Sheets.Designer.TemplateNames.FileMenuPanelTemplate,
    fileMenuTemplate
  );
}

function fileMenuGrapeCityCustomization(GC) {
  function WorkpaperFileMenu() {
    GC.Spread.Sheets.Designer.AtomicComponentBase.call(this, ...arguments);
  }
  WorkpaperFileMenu.prototype = new GC.Spread.Sheets.Designer.AtomicComponentBase();
  WorkpaperFileMenu.prototype.getTemplate = function (options) {
    return `<div class="gc-designer-list-control"></div>`;
  };
  WorkpaperFileMenu.prototype.onMounted = function (host, options) {
    this._items = options.items;
    this.container = host.children[0];
    if (this._items) {
      var listContainer = document.createElement('div');
      listContainer.classList.add('gc-list-control-container');
      listContainer.classList.add('gc-list-alignment-vertical');
      var hasIcon = this._items.some(function (r) {
        return r.iconClass;
      });
      for (var i = 0, len = this._items.length; i < len; i++) {
        this._renderItem(listContainer, this._items[i], hasIcon);
      }
    }
    this.container.appendChild(listContainer);
  };
  WorkpaperFileMenu.prototype.getItemElements = function () {
    return this.container.querySelectorAll('.gc-list-control-item');
  };
  WorkpaperFileMenu.prototype.attachEvent = function (itemHost) {
    var self = this;
    itemHost.addEventListener('click', function () {
      self.selectItem = this.getAttribute('data-value');
      self.refresh();
      self.raiseValueChanged();
      var cmd = self._items.find(t => {
        return t.value === self.selectItem;
      });
      if (cmd) {
        cmd.execute(self, self.selectItem);
      }
    });
  };
  WorkpaperFileMenu.prototype.refresh = function () {
    var listSelectedItemClassName = 'gc-list-control-selected-item';
    var itemElements = this.getItemElements();
    for (var i = 0; i < itemElements.length; i++) {
      itemElements[i].classList.remove(listSelectedItemClassName);
      var value = itemElements[i].getAttribute('data-value');
      var needSelect = false;
      if (value === this.selectItem) {
        needSelect = true;
      }
      if (needSelect) {
        itemElements[i].classList.add(listSelectedItemClassName);
        if (this._items && this._items[i]) {
          this._items[i].selected = true;
        }
      }
    }
  };
  WorkpaperFileMenu.prototype.onValueChanged = function (preValue, nextValue, host, options) {
    var self = this,
      nomatch = true;
    for (var i = 0; i < self._items.length; i++) {
      var item = self._items[i];
      if (item.value === nextValue) {
        self.selectItem = nextValue;
        item.selected = true;
        nomatch = false;
      } else {
        item.selected = false;
      }
    }
    if (nomatch) {
      self.selectItem = null;
    }
    self.refresh();
  };
  WorkpaperFileMenu.prototype.updateValue = function (host, options) {
    if (this.selectItem) {
      return this.selectItem;
    }
  };
  WorkpaperFileMenu.prototype._renderItem = function (host, item, hasIcon) {
    var itemContainer = document.createElement('div');
    var listItemClassName = 'gc-list-control-item';
    var ListNoItemClassName = 'gc-list-control-noitem';
    var ListSeparatorClassName = 'gc-list-control-listseparator';
    var ListItemBigIconClassName = 'gc-list-control-item-bigicon';
    var listEmptyIconClassName = 'gc-list-control-emptyicon';
    var listItemIconClassName = 'gc-list-control-item-icon';
    var listItemTextClassName = 'gc-list-control-item-text';
    var ListItemBigTextClassName = 'gc-list-control-item-bigtext';

    itemContainer.classList.add(listItemClassName);
    if (item.type === 'separator') {
      itemContainer.classList.add(ListNoItemClassName);
      var div = document.createElement('div');
      div.className = ListSeparatorClassName;
      itemContainer.appendChild(div);
    } else {
      var bigText = false;
      if (hasIcon) {
        var icon = document.createElement('div');
        if (item.iconClass) {
          icon.className = item.iconClass;
          if (item.bigIcon) {
            icon.classList.add(ListItemBigIconClassName);
            bigText = true;
          } else if (item.iconWidth) {
            icon.style.width = item.iconWidth + 'px';
            icon.style.height = item.iconHeight + 'px';
          }
        } else {
          icon.classList.add(listEmptyIconClassName);
        }
        icon.classList.add(listItemIconClassName);
        itemContainer.appendChild(icon);
      }
      var actualText = item.text;
      if (actualText) {
        var textSpan = document.createElement('div');
        if (item.textClass) {
          textSpan.className = item.textClass;
        }
        textSpan.classList.add(listItemTextClassName);
        if (bigText) {
          textSpan.classList.add(ListItemBigTextClassName);
        }
        textSpan.innerText = actualText;

        textSpan.title = actualText;

        itemContainer.appendChild(textSpan);

        if (item.watermark) {
          var watermark = document.createElement('div');
          watermark.innerText = item.watermark;
          watermark.className = `${item.iconClass} file-menu-watermark`;
          itemContainer.appendChild(watermark);
        }
      }
      itemContainer.setAttribute('data-value', item.value !== null && item.value !== void 0 ? String(item.value) : '');
      itemContainer.setAttribute('data-text', actualText !== null && actualText !== void 0 ? String(actualText) : '');
    }
    if (item.type !== 'separator') {
      this.attachEvent(itemContainer);
    }
    if (item.selected === true) {
      itemContainer.classList.add('gc-list-control-selected-item');
    }
    host.appendChild(itemContainer);
  };

  WorkpaperFileMenu.prototype.onDestroy = function (host) {
    this.getItemElements().forEach(function (itemEle) {
      itemEle.removeEventListener('click');
    });
  };

  GC.Spread.Sheets.Designer.Designer.RegisterComponent('WorkpaperFileMenu', WorkpaperFileMenu);
}

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SftpModal from '../../../_shared/SFTPModal/SftpModal.jsx';
import FileDialog from '../FileDialog/FileDialog';
import { BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { useSFTPSourceFile } from './useSFTPSourceFile';
import { getErrorMessage } from '../../shared/Status';
import { useInputSourceFiles } from './useInputSourceFiles';
import Spinner from '../../../_shared/Spinner';
import FileSettings from '../FileSettings/FileSettings';
import CustomLogger from '../../../_shared/Logger/CustomLogger.js';
import { ResourceType } from '../../../editor/EditorPage/Spreadsheet/WorkpaperSendToTPModal/constants.js';

const SFTPInputSubInspector = ({ elementData, elementId, updateData }) => {
  const { dataFlowState, dataFlowActions, sourceFileUpload } = useContext(DataFlowEditorContext);
  const { setFilePropertiesDialog, setSFTPInputProperties } = dataFlowActions;
  const { id: dataFlowId, workingElement, taxPeriod, filePropertiesDialog } = dataFlowState;

  const handleWorkpaperSendToSFTPModalOpen = () => setSFTPInputProperties({ popup: true });
  const handleWorkpaperSendToSFTPModalClose = () =>
    setSFTPInputProperties({
      popup: false,
      loading: false,
    });

  const [generalError, setGeneralError] = useState({
    message: undefined,
  });

  useEffect(() => {
    if (generalError?.message) {
      CustomLogger.pushLog(CustomLogger.operations.INGEST_FILE.FROM_SFTP, {
        workflow_id: dataFlowId,
        error: JSON.stringify(generalError),
        message: `catch error when trying to list/select a file/s from SFTP modal with dataflow id: ${dataFlowId}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalError]);

  const { sourceFiles, setSourceFiles } = useInputSourceFiles(
    dataFlowId,
    elementId,
    elementData?.pendingSourceFileVersionId,
    filePropertiesDialog?.reloadFiles
  );
  const fields = elementData.fields || [];

  const history = useHistory();
  const onErrorCallback = code => {
    setFilePropertiesDialog({
      popup: false,
      loading: false,
      saving: false,
      sheets: [],
      reloadFiles: false,
    });
    elementData.integrationType = null;
    setGeneralError({ message: getErrorMessage(code) });
  };

  const handleDismissAlert = () => {
    history.replace(`/data-flows/${dataFlowId}/editor`);
    setGeneralError(null);
  };

  const { uploadedSFTPFile, selectedSFTPFile, selectedSFTPFileSheetData, setOpenSourceFile, openSourceFile } =
    useSFTPSourceFile(elementId, onErrorCallback);

  useEffect(() => {
    if (openSourceFile) {
      const { type, name } = openSourceFile;
      const isCsvFile = type.toLowerCase() === 'csv';
      setFilePropertiesDialog({
        isCsvFile: isCsvFile,
        useSFTP: true,
        systemCode: 'sftp',
        fileName: name,
        uploadedSFTPFile,
        selectedSFTPFile,
        selectedSFTPFileSheetData,
        popup: true,
        loading: false,
        uploadExistingFile: false,
        fileDialogTaxPeriod: taxPeriod,
        sourceInfo: {
          dataFlowId,
          inputId: elementId,
          elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
        },
        reloadFiles: true,
      });

      CustomLogger.pushLog(CustomLogger.operations.INGEST_FILE.FROM_SFTP, {
        workflow_id: dataFlowId,
        message: `File ${name} selected successfully from SFTP modal with data flow id ${dataFlowId}`,
      });
    }
  }, [
    openSourceFile,
    uploadedSFTPFile,
    selectedSFTPFile,
    selectedSFTPFileSheetData,
    dataFlowId,
    elementId,
    setFilePropertiesDialog,
    workingElement.elementData,
    taxPeriod,
  ]);

  const onOpen = file => {
    setGeneralError({});
    handleWorkpaperSendToSFTPModalClose();
    setOpenSourceFile(file);
  };

  const onClose = () => {
    setGeneralError({});
    handleWorkpaperSendToSFTPModalClose();
    history.goBack();
  };

  const { isUploading } = sourceFileUpload;

  const importSFTPFileDialog = () => {
    return (
      <SftpModal
        generalError={generalError}
        setGeneralError={setGeneralError}
        show={handleWorkpaperSendToSFTPModalOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    );
  };

  return (
    <div className="sftp-input-element-inspector">
      {fields.length > 0 && sourceFiles.length > 0 && !sourceFiles[0].error ? (
        <FileSettings
          elementData={elementData}
          elementId={elementId}
          importFile={importSFTPFileDialog}
          setGeneralError={setGeneralError}
          setSourceFiles={setSourceFiles}
          sourceFiles={sourceFiles}
          updateData={updateData}
        />
      ) : isUploading ? (
        <Spinner />
      ) : (
        <SftpModal
          resourceId={dataFlowId}
          resourceType={ResourceType.Dataflow}
          setGeneralError={setGeneralError}
          show={handleWorkpaperSendToSFTPModalOpen}
          onClose={onClose}
          onOpen={onOpen}
        />
      )}

      <FileDialog
        dataFlowActions={dataFlowActions}
        dataFlowState={dataFlowState}
        setGeneralError={setGeneralError}
        setOpenSourceFile={setOpenSourceFile}
        setSourceFiles={setSourceFiles}
        sourceFiles={sourceFiles}
      />
      <BTAlert
        appear
        dismissible
        fixed
        btStyle="danger"
        visible={!!generalError?.message}
        onDismiss={handleDismissAlert}
      >
        {generalError ? generalError.message : ''}
      </BTAlert>
    </div>
  );
};

export default SFTPInputSubInspector;

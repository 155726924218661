import React, { useEffect } from 'react';
import CloseButton from '../shared/CloseButton';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import ConnectionForm from '../ConnectionPanel/ConnectionForm';
import Spinner from '../../../../../_shared/Spinner';
import { BTButton } from '@btas/jasper';
import { isEmpty } from 'lodash';
import './AddDataConnection/styles.scss';
import { eventTrackTagNames } from '../../../../../_shared/EventTrackingContext/constants';

export default function AddDataConnection({
  dataFlowGroups,
  filters,
  formData,
  saveButtonDisabled,
  readOnly,
  onSaveConnection,
  onClose,
  onFieldChange,
}) {
  useEffect(() => {
    const output =
      dataFlowGroups?.length === 1 && dataFlowGroups[0].dataFlows[0].outputs.length === 1
        ? dataFlowGroups[0].dataFlows[0].outputs[0].id
        : undefined;

    onFieldChange({
      ...formData,
      dataFlow: dataFlowGroups?.length === 1 ? dataFlowGroups[0].dataFlows[0] : formData.dataFlow,
      output,
    });
    // Removing lint checks for dependencies because we just need to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFlowGroups]);

  const handleSaveNewConnection = async () => {
    try {
      await onSaveConnection();
    } catch (error) {
      const fieldErrors = error
        .filter(event => event.target)
        .reduce((prev, curr) => ({ ...prev, [curr.target]: curr.message }), {});
      const formError = error.filter(event => !event.target);

      onFieldChange({ ...formData, fieldErrors, formError });
      return;
    }
  };

  const isLoading = isEmpty(formData.fieldErrors) && (!dataFlowGroups || (formData.output && !filters));

  return (
    <div className="wkp-right-pane wkp-add-connection-panel">
      <div className="wkp-right-pane-header">
        <h2>Link to Data Connect</h2>
        <CloseButton onClose={onClose} />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ConnectionForm
            dataFlowGroups={dataFlowGroups}
            filters={filters}
            formData={formData}
            isReadOnly={readOnly}
            updateParent={onFieldChange}
          />

          <Row className="wkp-add-footer-buttons">
            <Col xs={6}>
              <BTButton onClick={onClose}>Cancel</BTButton>
            </Col>
            <Col xs={6}>
              <BTButton
                btStyle="primary"
                data-track-tag={eventTrackTagNames.interactive}
                disabled={saveButtonDisabled}
                id="wkp-connection-save-btn"
                onClick={handleSaveNewConnection}
              >
                Save link
              </BTButton>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

import { useContext, useRef } from 'react';
import EditorContext from './EditorContext';

export function useEditorToggle() {
  const { spreadRef } = useContext(EditorContext);
  const isEnabledRef = useRef(true);
  const menuDataRef = useRef([]);
  const sheetProtectionRef = useRef({});

  function disableEditor() {
    const ss = spreadRef.current;

    menuDataRef.current = ss.contextMenu.menuData;

    ss.sheets.forEach(sheet => {
      sheetProtectionRef.current[sheet.name()] = sheet.options.isProtected;
      sheet.options.isProtected = true;
    });
    ss.options.tabEditable = false;
    ss.options.newTabVisible = false;
    ss.contextMenu.menuData = [];
    isEnabledRef.current = false;
  }

  function enableEditor() {
    const ss = spreadRef.current;
    ss.sheets.forEach(sheet => {
      sheet.options.isProtected = sheetProtectionRef[sheet.name()] ?? false;
    });
    ss.options.tabEditable = true;
    ss.options.newTabVisible = true;
    ss.contextMenu.menuData = menuDataRef.current;
    isEnabledRef.current = true;
  }

  return { disableEditor, enableEditor, isEnabled: isEnabledRef.current };
}

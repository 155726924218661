import React from 'react';
import ValidationErrors from './ValidationErrors';
import { BTAlert } from '@btas/jasper';

const ValidationErrorsAlert = ({ show, onDismiss, validationErrors }) => {
  return (
    <BTAlert appear dismissible fixed btStyle="warning" visible={show} onDismiss={onDismiss}>
      The Data Connect workflow has been saved, but is currently in an invalid state. The following errors must be
      resolved before you can import files using this Data Connect workflow:
      <ValidationErrors validationErrors={validationErrors} />
    </BTAlert>
  );
};

export default ValidationErrorsAlert;

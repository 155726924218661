import { MERGE_COLOR } from '../../shared/colors';
import { TEXT } from '../../shared/fieldTypes';
import { computeKeyFieldAutoMappings as computeAutoMappings } from './shared/utils';
import { MergeElementInspector, MergeInput } from '../../MergeElementInspector';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/merge_block_icon.svg';
import hintImage from '../icons/merge_hint_img.svg';
import { MERGE } from '../types/shared/typesConstants';

export class MergeElementType extends TransformationElementType {
  static TYPE = MERGE;

  static HELP_TEXT = `The merge block combines rows from two inputs. The column headings will be drawn from Input A.<img src=${hintImage} alt="Merge hint" />`;

  constructor() {
    super(MergeElementType.TYPE, 'Merge', MERGE_COLOR, iconImage, MergeElementType.HELP_TEXT, 'in');
  }

  get initialData() {
    return {
      ...super.initialData,
      mappings: [{ left: null, right: null }],
      previewUnmappedColumns: false,
      renamedFields: {},
    };
  }

  get maxCount() {
    return -1;
  }

  get inPorts() {
    return [MergeInput.A, MergeInput.B];
  }

  get inspectorComponent() {
    return MergeElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    const leftFields = sourceElements['left']?.elementData?.fields || null;
    const rightFields = sourceElements['right']?.elementData?.fields || null;
    let mappings = elementData.mappings;

    /**
     * If the mappings are empty
     * There are inputs connected to both sides
     * Some of those two inputs have just been connected
     * And preview unmapped columns in off
     *
     * Generate auto mapping
     */
    if (
      this.areMappingsEmpty(elementData.mappings) &&
      leftFields &&
      leftFields.length > 0 &&
      rightFields &&
      rightFields.length > 0 &&
      (elementData.leftFields === null || elementData.rightFields === null) &&
      !elementData.previewUnmappedColumns
    ) {
      mappings = computeAutoMappings(elementData.mappings, leftFields, rightFields);
    }

    const renamedFields = {};

    const fields = mappings
      ?.filter(m => m.left && m.right)
      ?.map(({ left, right }) => ({
        name: left?.original_name || left?.name,
        type: left?.type === right?.type ? left?.type : TEXT,
      }));

    if (elementData?.previewUnmappedColumns) {
      leftFields?.forEach(lf => {
        const fieldName = lf?.original_name || lf?.name;
        if (!fields?.find(fn => fn?.name === fieldName)) {
          fields.push({ name: fieldName, type: lf?.type });
        }
      });
      const fieldList = fields?.map(field => field.original_name || field.name);
      const rightFieldList = rightFields?.map(field => field.original_name || field.name);

      rightFields?.forEach(rf => {
        const fieldName = rf?.original_name || rf?.name;
        if (!mappings?.find(m => m.right?.name === fieldName)) {
          if (fieldList.includes(fieldName)) {
            let counter = 1;
            renamedFields[fieldName] = `${fieldName}_${counter}`;

            while (fieldList.includes(renamedFields[fieldName]) || rightFieldList.includes(renamedFields[fieldName])) {
              renamedFields[fieldName] = `${fieldName}_${counter++}`;
            }
            fields.push({ name: renamedFields[fieldName], type: rf?.type });
          } else {
            fields.push({ name: fieldName, type: rf?.type });
          }
        }
      });
    }

    return {
      ...elementData,
      fields,
      mappings: mappings.length > 0 ? mappings : this.initialData.mappings,
      previewUnmappedColumns: elementData?.previewUnmappedColumns,
      renamedFields: renamedFields,
      leftFields,
      rightFields,
    };
  }

  areMappingsEmpty(mappings) {
    const [mapping] = mappings;

    return (
      (mapping?.left === undefined || mapping?.left === null) &&
      (mapping?.right === undefined || mapping?.right === null)
    );
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      mappings: elementData.mappings,
      leftFields: elementData.leftFields,
      rightFields: elementData.rightFields,
      previewUnmappedColumns: elementData.previewUnmappedColumns,
      renamedFields: elementData.renamedFields,
    };
  }

  addPortsToInstance(instance) {
    super.addPortsToInstance(instance);

    if (this.inPorts) {
      this.inPorts.forEach(inPort => {
        instance.addInPort(inPort);
      });
    }

    if (this.outPorts) {
      this.outPorts.forEach(outPort => {
        instance.addOutPort(outPort);
      });
    }
    instance.portProp('left', 'attrs', { '.port-label': { text: 'A' } });
    instance.portProp('right', 'attrs', { '.port-label': { text: 'B' } });
  }
}

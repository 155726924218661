import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getErrorMessage, isLoading, Status } from '../../shared/Status';
import { getPreviewOutputRecords, getPreviewRun } from './apis';
import CustomLogger from '../../../_shared/Logger/CustomLogger';

const MAX_RETRIES = 2;

export default function usePreviewStatus(dataFlowState) {
  const [error, setError] = useState();
  const [isRunning, setIsRunning] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [previewRun, setPreviewRun] = useState();
  const [outputData, setOutputData] = useState([]);
  const [showToggleInput, setToggleInput] = useState(null);
  const [previewRunId, setPreviewRunId] = useState();
  const lastPreviewKey = useRef(undefined);
  const refreshTimeoutRef = useRef();
  const previewRunAttemptsRef = useRef(0);

  const fetchData = useCallback(
    async (run, status) => {
      const { id: dataFlowId, activeElement, previewOutputNode } = dataFlowState;

      if (run.previewId !== lastPreviewKey.current) {
        lastPreviewKey.current = run.previewId;

        setIsLoadingData(true);
        setOutputData([]);

        //TECH DEBT: For now only returns results from first output port (note: the 'out' condition is for output blocks)
        let port = activeElement?.elementType?.outPorts ? activeElement?.elementType?.outPorts[0] : 'out';
        if (previewOutputNode) {
          port = previewOutputNode;
        }

        try {
          //Retrieve output records for preview
          const resultOutputData = await getPreviewOutputRecords(run.id, activeElement.id, port, 100);

          CustomLogger.addDurationPayload(CustomLogger.operations.BLOCK_TO_PREVIEW, {
            data_flow_id: dataFlowState.id,
            block: dataFlowState.activeElement.type,
            columns: dataFlowState.activeElement.elementData.fields.length.toString(),
            rows: resultOutputData.totalRecordsCount.toString(),
          });

          if (run.previewId === lastPreviewKey.current) {
            // Update ids and port for filter and sorting
            setPreviewRunId(run.id);

            // Update preview run data state
            setPreviewRun(run);

            // Update output data state
            setOutputData(resultOutputData);
          }
        } catch (e) {
          if (!status.isCancelled) {
            CustomLogger.pushLog(CustomLogger.operations.LOAD_PREVIEW, {
              workflow_id: dataFlowId,
              error: JSON.stringify(e),
              message: 'Something happen while getting preview output records',
            });
            setError(e);
          }
        } finally {
          setIsLoadingData(false);
        }
      }
    },
    [dataFlowState, setPreviewRun, setIsLoadingData, setOutputData, setError]
  );

  const refresh = useCallback(
    async (run, status) => {
      let latest;

      do {
        if (refreshTimeoutRef.current) {
          clearTimeout(refreshTimeoutRef.current);
        }

        await new Promise(resolve => {
          refreshTimeoutRef.current = setTimeout(resolve, 1000);
        });

        try {
          setIsRunning(true);
          latest = await getPreviewRun(run.id, previewRunAttemptsRef.current);
          latest.previewId = run.previewId;

          if (!status.isCancelled) {
            if (latest.status === Status.Finished) {
              await fetchData(latest, status);
            } else if (latest.status === Status.Failed) {
              if (latest.errorCode === 'WKP2019') {
                CustomLogger.pushLog(CustomLogger.operations.JOIN_BLOCK, {
                  error: 'Join processing too many rows',
                  message: 'Join processing too many rows',
                  userId: latest.userId,
                  startTimeStamp: latest.startTimeStamp,
                  endTimeStamp: latest.endTimeStamp,
                  status: latest.status,
                  companyId: latest.companyId,
                  dataFlowId: latest.dataFlowId,
                  errorCode: latest.errorCode,
                });
              }

              setError({ message: getErrorMessage(latest.errorCode), statusCode: latest.errorCode });

              setIsRunning(false);
            }
          }
        } catch (e) {
          previewRunAttemptsRef.current++;

          if (previewRunAttemptsRef.current === MAX_RETRIES) {
            if (!status.isCancelled) {
              setError(e);
              setIsRunning(false);
            }
          }
        }
      } while (
        status.isCancelled === false &&
        (!latest || (latest.status !== Status.Finished && latest.status !== Status.Failed)) &&
        previewRunAttemptsRef.current < MAX_RETRIES
      );

      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    },
    [fetchData, setError, setIsRunning, previewRunAttemptsRef]
  );

  useEffect(() => {
    if (!previewRun) {
      return;
    }

    const isRunning = isLoading(previewRun.status);
    const status = { isCancelled: false };

    setIsRunning(isRunning);
    setError(null);

    if (dataFlowState?.activeElement?.id) {
      if (isRunning) {
        // Deliberately not waiting on promise
        // noinspection JSIgnoredPromiseFromCall
        refresh(previewRun, status);
      } else if (previewRun && previewRun?.status === Status.Finished) {
        //noinspection JSIgnoredPromiseFromCall
        fetchData(previewRun, status);
      } else if (previewRun?.status === Status.Failed && !status.isCancelled) {
        console.error('Verify preview run failed :', previewRun);
        setError({ message: getErrorMessage(previewRun.errorCode) });
      }
    }

    return () => {
      status.isCancelled = true;
    };
  }, [refresh, fetchData, previewRun, dataFlowState, setError, setIsRunning]);

  const previewRecords = useMemo(() => outputData.records, [outputData]);

  const totalRecordsCount = useMemo(() => outputData.totalRecordsCount, [outputData]);
  const valueErrorList = useMemo(() => outputData.valueErrorList, [outputData]);
  const elementConfiguration = useCallback(
    elementId => {
      return previewRun?.configuration?.elements[elementId] || {};
    },
    [previewRun]
  );

  const previewStatusState = {
    isRunning,
    isLoadingData,
    error,
    previewRun,
    previewRecords,
    totalRecordsCount,
    valueErrorList,
    showToggleInput,
    previewRunId,
    elementConfiguration,
  };

  const previewStatusActions = {
    setPreviewRun,
    setToggleInput,
  };

  return [previewStatusState, previewStatusActions];
}

import CustomLogger from '../../../_shared/Logger/CustomLogger';

import a_output_icon from './PreviewNav/A_Output.svg';
import b_output_icon from './PreviewNav/B_Output.svg';
import f_output_icon from './PreviewNav/F_Output.svg';
import j_output_icon from './PreviewNav/J_Output.svg';
import output_icon from './PreviewNav/Output.svg';
import t_output_icon from './PreviewNav/T_Output.svg';

const DEFAULT_OUTPUT_NODE = 'output';
const JOIN_DEFAULT_NODE = 'J';
const FILTER_DEFAULT_NODE = 'out';
const LOOKUP_DEFAULT_NODE = 'T';

const OUTPUT_TEXT_MAP = {
  a: 'Data connect preview A output',
  b: 'Data connect preview B output',
  out: 'Data connect preview T output',
  F: 'Data connect preview F output',
  J: 'Data connect preview J output',
  output: 'Data connect preview default output',
};

const ICON_MAP = {
  a: a_output_icon,
  b: b_output_icon,
  out: t_output_icon,
  F: f_output_icon,
  J: j_output_icon,
  T: t_output_icon,
  output: output_icon,
};

const FILTER_TOOLTIP_MAP = {
  out: 'Records matching filter criteria',
  F: 'Records not matching filter criteria',
};

const LOOKUP_TOOLTIP_MAP = {
  T: 'Successfully mapped records',
  F: 'Unmapped records',
};

const JOIN_TOOLTIP_MAP = {
  a: `Records only existing in input 'a'`,
  J: `Joined records`,
  b: `Records only existing in input 'b'`,
};

const TOOLTIP_DATA = {
  join: JOIN_TOOLTIP_MAP,
  lookup: LOOKUP_TOOLTIP_MAP,
  filter: FILTER_TOOLTIP_MAP,
};

const DEFAULT_TOOLTIP_TEXT = 'Preview output';

const JOIN_OUTPUTS = ['a', JOIN_DEFAULT_NODE, 'b'];
const FILTER_OUTPUTS = [FILTER_DEFAULT_NODE, 'F'];
const LOOKUP_OUTPUTS = [LOOKUP_DEFAULT_NODE, 'F'];

export class OutputToggleUtils {
  getIconPath(option) {
    return ICON_MAP[option];
  }

  getAlternativeOutputText(option) {
    return OUTPUT_TEXT_MAP[option];
  }

  getTooltipText(elementType, option) {
    if (elementType in TOOLTIP_DATA) {
      switch (elementType.toLowerCase()) {
        case 'join':
          return TOOLTIP_DATA[elementType][option] ?? JOIN_TOOLTIP_MAP[JOIN_DEFAULT_NODE];
        case 'filter':
          return TOOLTIP_DATA[elementType][option] ?? FILTER_TOOLTIP_MAP[FILTER_DEFAULT_NODE];
        case 'lookup':
          return TOOLTIP_DATA[elementType][option] ?? LOOKUP_DEFAULT_NODE[LOOKUP_DEFAULT_NODE];
        default:
          return DEFAULT_TOOLTIP_TEXT;
      }
    }
    return DEFAULT_TOOLTIP_TEXT;
  }

  hasMultipleOutputs(elementType) {
    return elementType in TOOLTIP_DATA;
  }

  getOutputToggleData(activeElement, previewOutputNode) {
    let outputData = {};

    const elementType = activeElement?.elementType?.type;

    if (!elementType) {
      return {};
    }
    const elementName = activeElement?.elementData?.name;

    switch (elementType.toLowerCase()) {
      case 'join':
        outputData = {
          activeOutput: this.getValidJoinOutput(previewOutputNode, elementName),
          outputs: JOIN_OUTPUTS,
          elementType: 'join',
        };

        break;
      case 'filter':
        outputData = {
          activeOutput: this.getValidFilterOutput(previewOutputNode, elementName),
          outputs: FILTER_OUTPUTS,
          elementType: 'filter',
        };
        break;
      case 'lookup':
        outputData = {
          activeOutput: this.getValidLookupOutput(previewOutputNode, elementName),
          outputs: LOOKUP_OUTPUTS,
          elementType: 'lookup',
        };
        break;
      default:
        outputData = {
          activeOutput: DEFAULT_OUTPUT_NODE,
          outputs: [DEFAULT_OUTPUT_NODE],
          elementType: elementType ?? 'unknown',
        };
        break;
    }

    return outputData;
  }

  getValidFilterOutput(previewOutputNode, elementName) {
    return this._getValidOutput(previewOutputNode, elementName, FILTER_OUTPUTS, FILTER_DEFAULT_NODE, 'filter');
  }

  getValidLookupOutput(previewOutputNode, elementName) {
    return this._getValidOutput(previewOutputNode, elementName, LOOKUP_OUTPUTS, LOOKUP_DEFAULT_NODE, 'lookup');
  }

  getValidJoinOutput(previewOutputNode, elementName) {
    return this._getValidOutput(previewOutputNode, elementName, JOIN_OUTPUTS, JOIN_DEFAULT_NODE, 'join');
  }

  _getValidOutput(previewOutputNode, elementName, outputs, defaultNode, elementType) {
    const validPreviewOutput = outputs.includes(previewOutputNode);
    if (!validPreviewOutput) {
      if (previewOutputNode !== null) {
        this._logInvalidOutputMessage(previewOutputNode, elementName, elementType);
      }
      return defaultNode;
    }
    return previewOutputNode;
  }

  _logInvalidOutputMessage(previewOutputNode, elementName, elementType) {
    CustomLogger.pushLog(CustomLogger.operations.DATA_CONNECT_PREVIEW, {
      message: `Invalid output node ${previewOutputNode} for  ${elementName} block. A default value will be used`,
      block: elementType,
    });
  }
}

/* eslint-disable eqeqeq */
/**
 * This file overrides the paint method, keeping the older paint methods hierarchy untouched.
 *
 * The changes here are only related to Cell Review.
 */

import GC from '../../../../../../SpreadSheets';
import { isCellOnScreenValidator } from './cellReview/utils';
import { registerTooltip, unregisterTooltip } from '../tooltipHelper';
import { exportIcons } from './cellReview/exportIcons';
import { CELL_REVIEW } from '../../../../../_shared/DataReference/ReferenceType';
import { cleanDirtyCellValue } from '../../../DataReference/dataReferenceHelper';

const { checkIcon, checkExclamationIcon } = exportIcons();
const moment = require('moment');
function getCellReviewsInTargetCell(row, column, sheetName, dataReferences) {
  return dataReferences?.current?.filter(
    x => x.row === row && x.column === column && x.sheetName === sheetName && x.type === CELL_REVIEW
  );
}

export function cellReviewDefinition(tooltipManager, dataReferences, isRecalcPostitions) {
  // Applying these to avoid overriding spreadJS default and other custom functionality
  const prevPaint = GC.Spread.Sheets.CellTypes.Base.prototype.paint;
  const prevProcessMouseEnter = GC.Spread.Sheets.CellTypes.Base.prototype.processMouseEnter;
  const prevProcessMouseLeave = GC.Spread.Sheets.CellTypes.Base.prototype.processMouseLeave;
  GC.Spread.Sheets.CellTypes.Base.prototype.paint = function (ctx, _, x, y, w, h, ____, context) {
    prevPaint.apply(this, arguments);
    if (isRecalcPostitions.current) {
      return;
    }

    const { row, col, sheet } = context;

    const cellReviews = getCellReviewsInTargetCell(row, col, sheet.name(), dataReferences);

    if (cellReviews?.length) {
      const cell = sheet.getCell(row, col);
      const cellValue = cell.text() || '';

      const reviewParameters = cellReviews.map(review => JSON.parse(review.parameters));
      const isReviewValid = reviewParameters.every(({ ApprovedValue }) => {
        return validateCellReview(cellValue, ApprovedValue);
      });

      // reviewed icon is drawn (depending on whether the original value was modified or not, different icons are rendered)
      try {
        ctx.save();
        ctx.rect(x, y, w, h);
        const { image } = isReviewValid ? checkIcon : checkExclamationIcon;
        ctx.drawImage(image, x + checkIcon.marginLeft, y + checkIcon.marginTop);
        ctx.restore();
        // add indentation to avoid icon overlap
        const currentIndent = cell.textIndent();
        if (currentIndent < 2 && isNaN(cellValue)) {
          cell.textIndent(2);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseEnter = function (hitInfo) {
    prevProcessMouseEnter.apply(this, arguments);
    const { row, col, cellRect } = hitInfo;
    const { x, y } = cellRect;
    const sheet = hitInfo.sheet;

    const cellReviews = getCellReviewsInTargetCell(row, col, sheet.name(), dataReferences);
    // clean last drawing and tooltip in case the cell has been un-reviewed
    const tooltipId = `wkp_cell_review_row_${row}_col_${col}`;
    const unregister = () => unregisterTooltip(tooltipManager, tooltipId);
    unregister();

    if (cellReviews?.length) {
      const reviewParameters = cellReviews.map(review => JSON.parse(review.parameters));
      const cell = sheet.getCell(row, col);
      const cellValue = cell.text() || '';

      // the tooltip is registered
      let tooltipContent = '';

      reviewParameters.forEach(parameters => {
        tooltipContent += getTooltipHTML(parameters, cellValue);
      });

      // add margin styles
      tooltipContent = `<div style="margin-top: 10px; margin-right: 5px; margin-bottom: 10px; white-space: normal; text-align: left">${tooltipContent}</div>`;

      const { height, width } = checkIcon.image;
      registerTooltip(
        tooltipManager,
        x,
        y,
        { height, width },
        { x: width / 2 + checkIcon.marginLeft + 1, y: 5 },
        tooltipId,
        tooltipContent,
        true,
        isCellOnScreenValidator({ GC, sheet, row, col, onNotActive: unregister })
      );
    }
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.processMouseLeave = function (hitInfo) {
    prevProcessMouseLeave.apply(this, arguments);
    const { row, col } = hitInfo;
    const tooltipId = `wkp_cell_review_row_${row}_col_${col}`;
    unregisterTooltip(tooltipManager, tooltipId);
    return true;
  };

  function getTooltipHTML(params, cellValue) {
    let { ApprovedValue, ReviewDateTime, ReviewerUserFirstName } = params;
    const sanitizedReviewerUserFirstName = sanitizeHTML(ReviewerUserFirstName);

    // Only add timezone if needed
    ReviewDateTime = ReviewDateTime.endsWith('Z') ? ReviewDateTime : ReviewDateTime + 'Z';

    const isValid = validateCellReview(cellValue, ApprovedValue);
    const formattedDate = moment(ReviewDateTime).format('MM/DD/YYYY, h:mm A');
    const iconSrc = isValid ? checkIcon.image.currentSrc : checkExclamationIcon.image.currentSrc;
    const iconStyle = 'margin-right: 4px;';

    let reviewEntry = `<div style="display: flex; white-space: normal;"><img data-testid="${
      isValid ? 'valid' : 'invalid'
    }-${sanitizedReviewerUserFirstName}" src="${iconSrc}" style="${iconStyle}"><span style="margin-top: 1px;">${sanitizedReviewerUserFirstName} | ${formattedDate}</span></div>`;

    if (!isValid) {
      reviewEntry += `<div style="margin-left: 20px;">Reviewed previous value of '${ApprovedValue}'</div>`;
    }

    return reviewEntry;
  }

  function validateCellReview(cellValue, approvedValue) {
    // Normalize values
    cellValue = formatReviewCellValue(cellValue);
    approvedValue = formatReviewCellValue(approvedValue);

    // Number cases
    const numericApprovedValue = parseFloat(approvedValue);

    if ((isNum(numericApprovedValue) && numericApprovedValue % 1 !== 0) || numericApprovedValue == 1) {
      if (isNum(cellValue)) {
        return parseFloat(cellValue) === numericApprovedValue;
      }
    }

    // String cases
    return cellValue == approvedValue;
  }

  function formatReviewCellValue(value) {
    if (typeof value == 'string' && value?.endsWith('%')) {
      return parseFloat(value) / 100;
    }

    return cleanDirtyCellValue(value);
  }

  const isNum = value => !isNaN(value);

  function sanitizeHTML(str) {
    return str.replace(/[&<>"']/g, function (match) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
      }[match];
    });
  }
}

import GC from '../../../../../SpreadSheets';
export function CopyExecute(callback) {
  let oldCopyExeFn = GC.Spread.Sheets.Commands.copy.execute;
  GC.Spread.Sheets.Commands.copy.execute = function (workbook, options, isUndo) {
    let sheet = workbook.getSheetFromName(options.sheetName);
    let sels = sheet.getSelections();
    let totalCells = 0;
    sels.forEach(sel => (totalCells = totalCells + sel.rowCount * sel.colCount));
    //use 50k of cells like excel online
    if (totalCells > 50000) {
      callback();
      return false;
    } else return oldCopyExeFn.apply(this, arguments);
  };
}

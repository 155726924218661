import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BTGrid, BTModal, BTButton } from '@btas/jasper';
import { wijmoKey } from '../../../../configs/params';
import useGrid from './WorkpapersConnectionsListModal/useGrid';

export default function WorkpapersConnectionsListModal({ show, dataFlowName, linkedOutputIds, onClose }) {
  const history = useHistory();
  const [{ isLoading, gridData }] = useGrid({ linkedOutputIds });

  const nameCell = ({ rowData }) =>
    rowData && (
      <BTButton btStyle="link" onClick={() => handleNameClick(rowData.workpaperId)}>
        {rowData.workpaperName}
      </BTButton>
    );
  const handleNameClick = useCallback(workpaperId => history.push(`/editor/${workpaperId}`), [history]);

  return (
    <BTModal
      className="wkp-linked-workpapers-modal"
      show={show}
      size="modal-lg"
      title={`${dataFlowName} - Linked workpapers`}
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <BTGrid data={gridData} gridHeight={350} isLoading={isLoading} loadingType="spinner" wijmoKey={wijmoKey}>
          <BTGrid.Header key="workpaperName" binding="workpaperName" cellTemplate={nameCell} minWidth={140} width="2*">
            Workpaper
          </BTGrid.Header>
          <BTGrid.Header key="sourceDataConnection" binding="sourceDataConnection" width="*">
            Data Connect links
          </BTGrid.Header>
          <BTGrid.EmptyState message="There are no linked workpapers to this data flow." />
        </BTGrid>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle="primary" onClick={onClose}>
          Close
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}

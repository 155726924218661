import { BTComboBox, BTForm, BTInput } from '@btas/jasper';

export default function SaveAs({ name, taxPeriod, taxPeriodComboData, errorMessage, handleName, handleTaxPeriod }) {
  return (
    <BTForm>
      <BTForm.FormGroup errorText={errorMessage} hasError={!!errorMessage} htmlFor="name" label="Name">
        <BTInput id="name" value={name} onChange={({ target: { value } }) => handleName(value)} />
      </BTForm.FormGroup>
      {
        <BTForm.FormGroup htmlFor="taxPeriod" label="Tax Period">
          <BTComboBox
            canCreateNew
            isClearable
            popOutMenu
            createText="Add tax period"
            id="taxPeriod"
            isSearchable={true}
            maxMenuHeight={150}
            noResultsMessage="No tax periods created. Start typing to add a tax period."
            options={taxPeriodComboData}
            placeholder="Select a tax period, or start typing to add new"
            value={taxPeriod && { label: taxPeriod, value: taxPeriod }}
            onChange={option => {
              const selectedValue = option && option.value;
              handleTaxPeriod(selectedValue);
            }}
          />
        </BTForm.FormGroup>
      }
    </BTForm>
  );
}

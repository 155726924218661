import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BTButton, BTModal, BTGrid } from '@btas/jasper';
import './Summary/styles.scss';
import { wijmoKey } from '../../../../configs/params';
import { getAssociatedDataTransformations } from '../../../editor/EditorPage/DataReference/apis';
import Spinner from '../../../_shared/Spinner';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import { eventTrackTagNames } from '../../../_shared/EventTrackingContext/constants';

const Grid = ({ currentTaxPeriod, data, newTaxPeriod }) =>
  data.length ? (
    <>
      <p>
        For each '{currentTaxPeriod !== 'unassigned' ? currentTaxPeriod : '[ Unassigned ]'}' Data Connect workflow
        listed below, the Data Connect links will be updated to the respective '{newTaxPeriod}' workflow. Workflows that
        do not already exist in the new period will be created for you.
      </p>
      <p>Data Connect links that do not match period '{currentTaxPeriod}' will not be rolled forward.</p>
      <BTGrid allowResizing={1} data={data} wijmoKey={wijmoKey}>
        <BTGrid.Header key="dataTransformationWorkflow" align="left" binding="dataTransformationWorkflow" width="2*">
          Data Connect workflow
        </BTGrid.Header>
        <BTGrid.Header key="connections" align="left" binding="connections" width="*">
          Data Connect links
        </BTGrid.Header>
        <BTGrid.Header
          key="requiresWorkflowRollforward"
          align="left"
          binding="requiresWorkflowRollforward"
          minWidth={200}
          width="3*"
        >
          Will workflow be rolled forward?
        </BTGrid.Header>
      </BTGrid>
    </>
  ) : (
    <p>
      There are no Data Connect links to any Tax Period '
      {currentTaxPeriod !== 'unassigned' ? currentTaxPeriod : '[ Unassigned ]'}' Data Connect workflows.{' '}
      <Link
        rel="noopener noreferrer"
        target="_blank"
        title="Learn more about data connections"
        to="/help/index.html?article=connectingData"
      >
        <strong>Learn more about data connections</strong>
      </Link>
    </p>
  );

const Summary = ({ currentTaxPeriod, newTaxPeriod, workpaperId, onClose, onFinishClick, onPreviousClick }) => {
  const [workflows, setWorkflows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSummary, setErrorSummary] = useState(false);

  const mapAssociatedDataTransformations = useCallback(
    ({ name, amountOfConnections, existsOnTaxPeriod }) => ({
      dataTransformationWorkflow: name,
      connections: amountOfConnections,
      requiresWorkflowRollforward: existsOnTaxPeriod ? 'No. Workflow already exists in new tax period.' : 'Yes',
    }),
    []
  );

  const sortByNameDesc = useCallback(({ name: nameA }, { name: nameB }) => (nameA < nameB ? -1 : 1), []);

  useEffect(
    () => {
      async function fetchData() {
        setIsLoading(true);
        const data = await getAssociatedDataTransformations(workpaperId, newTaxPeriod, currentTaxPeriod);
        setIsLoading(false);
        if (data === null) {
          setErrorSummary(true);
        } else {
          setWorkflows(data.sort(sortByNameDesc).map(mapAssociatedDataTransformations));
        }
      }
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <BTModal
      className="rollforward-modal"
      show={true}
      size="modal-lg"
      title="Rollforward Workpaper"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div className="body-modal-height">
          {workflows ? (
            <Grid currentTaxPeriod={currentTaxPeriod} data={workflows} newTaxPeriod={newTaxPeriod} />
          ) : isLoading ? (
            <>
              <Spinner description="Scanning workpaper for Data Connect links..." />
            </>
          ) : errorSummary ? (
            <GeneralErrorMessage className="rollforward-summary-alert" dismissible={false} />
          ) : (
            <></>
          )}
        </div>
      </BTModal.Body>

      <BTModal.Footer>
        <BTButton id="wkp-rollforward-cancel-btn" onClick={onClose}>
          CANCEL
        </BTButton>

        {!errorSummary && (
          <>
            <BTButton
              data-track-tag={eventTrackTagNames.interactive}
              hasSpinner={false}
              id="wkp-rollforward-previous-btn"
              onClick={onPreviousClick}
            >
              PREVIOUS
            </BTButton>

            <BTButton
              btStyle="primary"
              data-track-tag={eventTrackTagNames.interactive}
              hasSpinner={false}
              id="wkp-rollforward-finish-btn"
              onClick={onFinishClick}
            >
              FINISH
            </BTButton>
          </>
        )}
      </BTModal.Footer>
    </BTModal>
  );
};

export default Summary;
